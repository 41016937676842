<template>
  <div id="agency-view">
    <AgencySidebar :isSidebarActive="isOpenedSidebar" :appActiveUser="activeUserInfo" @closeAppointmentsSidebar="toggleSidebar" />

    <vx-card>
      <div v-if="isAgency" class="flex">
        <vs-button class="mr-3" color="primary" type="filled" icon-size="24px" icon="add" @click.stop="activePrompt = true">
          {{ $t('inputs.inviteCompany') }}
        </vs-button>

        <vs-button v-if="activeUserInfo.superadmin" color="primary" type="filled" icon-size="24px" icon="add" @click.stop="toggleSidebar(true)">
          {{ $t('inputs.createAccount') }}
        </vs-button>
      </div>

      <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="allowedCompanies">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center"></div>
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{ allowedCompanies.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : allowedCompanies.length }} of
                {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 5">
                <span>5</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="name">
            {{ $t('vue.name') }}
          </vs-th>
          <vs-th sort-key="cooperation">
            {{ $t('vue.cooperation') }}
          </vs-th>
          <vs-th sort-key="phone">
            {{ $t('vue.phone') }}
          </vs-th>
          <vs-th sort-key="url">
            {{ $t('vue.url') }}
          </vs-th>
          <vs-th sort-key="city">
            {{ $t('vue.address') }}
          </vs-th>
          <vs-th>{{ $t('vue.action') }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>

              <vs-td :data="data[indextr].cooperation">
                <span
                  :style="{
                    color: setCooperationColorByType(data[indextr].cooperation)
                  }"
                >
                  {{ $t(`vue.${data[indextr].cooperation}`) }}
                </span>
              </vs-td>

              <vs-td :data="data[indextr].phone">
                {{ data[indextr].phone }}
              </vs-td>

              <vs-td :data="data[indextr].url">
                {{ data[indextr].url }}
              </vs-td>

              <vs-td>
                <p v-if="data[indextr].street">
                  <strong>{{ $t('vue.street') }} : </strong>{{ data[indextr].street }}
                </p>
                <p v-if="data[indextr].city">
                  <strong>{{ $t('vue.city') }} : </strong>{{ data[indextr].city }}
                </p>
                <p v-if="data[indextr].zipcode">
                  <strong>{{ $t('vue.zipcode') }} : </strong>{{ data[indextr].zipcode }}
                </p>
                <p v-if="data[indextr].country">
                  <strong v-if="data[indextr].country.countryName">{{ $t('vue.country') }} : </strong>{{ data[indextr].country.countryName }}
                </p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <div class="flex">
                  <vx-tooltip v-if="isAgency" position="top" :text="$t('vue.openSettingsForTheCompany')">
                    <vs-button
                      class="mr-2"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      :color="data[indextr].approved === 'confirmed' ? 'primary' : 'dark'"
                      icon="icon-info"
                      :disabled="data[indextr].approved !== 'confirmed'"
                      @click="toggleSidebar(true, data[indextr])"
                    />
                  </vx-tooltip>

                  <vx-tooltip v-if="isAgency" position="top" :text="$t('vue.logInToCustomerAccount')">
                    <vs-button
                      class="mr-2"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-user"
                      :color="data[indextr].approved === 'confirmed' ? 'primary' : 'dark'"
                      :disabled="data[indextr].approved !== 'confirmed'"
                      @click.stop="impersonate(data[indextr].createdBy)"
                    />
                  </vx-tooltip>

                  <vx-tooltip v-if="isAgency && activeUserInfo.superadmin" position="top" :text="$t('vue.sendSignInLinkToTheCustomersEmailAddress')">
                    <vs-button
                      class="mr-2"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-send"
                      :color="data[indextr].approved === 'confirmed' ? 'primary' : 'dark'"
                      :disabled="data[indextr].approved !== 'confirmed'"
                      @click="sendSignInLink(data[indextr].customer.email)"
                    />
                  </vx-tooltip>

                  <vx-tooltip v-if="activeUserInfo.superadmin" position="top" :text="$t('vue.cancelTheCooperation')">
                    <vs-button
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-trash"
                      color="danger"
                      @click.stop="openDeletingPrompt(data[indextr].uidAgencySettings)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vx-card>

    <vs-prompt
      v-if="activePrompt"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.confirm')"
      :title="$t('inputs.inviteCompany')"
      :active.sync="activePrompt"
      @accept="inviteCompany"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t('inputs.enterCompanyId') }}</span>

        <vs-input
          class="mt-3 w-full"
          :placeholder="$t('inputs.companyId')"
          :name="$t('inputs.companyId')"
          v-model="inviteData.companyId"
          v-validate="'required'"
          data-vv-validate-on="blur"
        />
      </div>
    </vs-prompt>

    <vs-prompt
      v-if="activeDeletingPrompt"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :title="$t('inputs.deleteCompany')"
      :active.sync="activeDeletingPrompt"
      @accept="deleteCompany"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t('info.terminateTheCooperationWithTheCompany') }}</span>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

import { mapGetters } from 'vuex'

import AgencySidebar from './AgencySidebar'

export default {
  components: {
    AgencySidebar
  },

  props: {
    isAgency: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    allowedCompanies: [],
    isMounted: false,
    itemsPerPage: 5,
    selected: [],
    isOpenedSidebar: false,
    activePrompt: false,
    activeDeletingPrompt: false,
    inviteData: {
      companyId: ''
    },
    temrminateCooperationId: '',
    uidAgencySettingsId: null
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      agencySettings: 'agency_settings'
    }),

    currentPage() {
      if (this.isMounted && this.$refs.table) {
        return this.$refs.table.currentx
      }

      return 1
    },

    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.allowedCompanies.length
    }
  },

  mounted() {
    this.isMounted = true

    this.setAgencySettings(this.agencySettings)
  },

  watch: {
    async agencySettings(newValue) {
      this.setAgencySettings(newValue)

      if (this.isOpenedSidebar && this.uidAgencySettingsId && !this.isAgency) {
        this.isOpenedSidebar = false
        const sidebarData = newValue.find((x) => x.uidAgencySettings === this.uidAgencySettingsId)
        if (sidebarData) {
          setTimeout(() => {
            this.toggleSidebar(true, sidebarData)
          }, 100)
        }
      }
    }
  },

  methods: {
    async setAgencySettings(newValue) {
      await this.$vs.loading()

      this.allowedCompanies = []

      const keyForAgencySettings = this.isAgency ? 'customerId' : 'agencyId'

      await Promise.all(
        newValue.map(async (data) => {
          const companyRef = await this.$db.collection('company').doc(data[keyForAgencySettings]).get()

          if (companyRef && companyRef.data()) {
            const { name, phone, url, street, city, zipcode, country, createdBy } = companyRef.data()
            const modifiedData = {
              ...data,
              name,
              phone,
              url,
              street,
              city,
              zipcode,
              country,
              createdBy,
              cooperation: data.approved
            }

            if (this.isAgency) {
              const userRef = await this.$db.collection('users').doc(createdBy).get()
              const userData = userRef.data()

              modifiedData.customer = {
                firstname: userData.firstname,
                lastname: userData.lastname,
                email: userData.email
              }
            }

            this.allowedCompanies.push(modifiedData)
          }
        })
      )

      await this.$vs.loading.close()
    },

    openDeletingPrompt(docId) {
      this.activeDeletingPrompt = true
      this.temrminateCooperationId = docId
    },

    async inviteCompany(id) {
      if (!id) {
        this.activePrompt = true
      }

      if (!this.inviteData.companyId && !id) {
        return
      }

      await this.$vs.loading()

      const invitedCompanyRef = await this.$db
        .collection('company')
        .doc(this.inviteData.companyId || id)
        .get()

      if (!invitedCompanyRef.exists) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.companyNotFound'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return await this.$vs.loading.close()
      }

      const agencySettingsRef = await this.$db
        .collection('agency-settings')
        .where('agencyId', '==', this.activeUserInfo.company)
        .where('customerId', '==', this.inviteData.companyId)
        .get()

      if (!agencySettingsRef.empty && !id) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.companyIsAlreadyInvited'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        let data = {
          approved: 'waiting'
        }

        if (!id) {
          data = {
            ...data,
            agencyId: this.activeUserInfo.company,
            customerId: this.inviteData.companyId,
            created: new Date(),
            createdBy: this.activeUserInfo.uid
          }

          await this.$db.collection('agency-settings').add(data)
        } else {
          data = {
            ...data,
            modified: new Date(),
            modifiedBy: this.activeUserInfo.uid
          }

          const neededAgencySettings = this.agencySettings.find((settings) => settings.customerId === id)

          if (!neededAgencySettings) {
            this.$vs.notify({
              title: this.$i18n.t('vue.error'),
              text: this.$i18n.t('info.somethingWentWrong'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })

            await this.$vs.loading.close()

            return
          }

          await this.$db.collection('agency-settings').doc(neededAgencySettings.uidAgencySettings).set(data, { merge: true })
        }

        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: !id ? this.$i18n.t('info.theInvitationHasBeenSent') : this.$i18n.t('info.theInvitationHasBeenResent'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      }

      this.inviteData.companyId = ''
      this.activePrompt = false

      await this.$vs.loading.close()
    },

    async impersonate(uid) {
      this.$vs.loading({ type: 'point' })

      try {
        const getUserCustomToken = this.$functions.httpsCallable('getUserCustomToken')
        const name = this.activeUserInfo.firstname ? `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}` : this.activeUserInfo.displayName
        const result = await getUserCustomToken({ uid, impersonate: true, loggedInUser: { uid: this.activeUserInfo.uid, name } })
        if (result.data && result.data.status === true) {
          const token = result.data.token
          await firebase.auth().signInWithCustomToken(token)
          location.href = '/'
        } else {
          const message = this.$i18n.t(`error.${result.data.messageCode}`)
          this.$vs.notify({
            time: 4000,
            title: this.$i18n.t('vue.error'),
            text: message,
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle'
          })
          this.$vs.loading.close()
        }
      } catch (error) {
        const message = error.message
        this.$vs.notify({
          time: 4000,
          title: this.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
        this.$vs.loading.close()
      }
    },

    async sendSignInLink(email) {
      await this.$vs.loading()

      const payload = {
        email,
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close
      }

      this.$store.dispatch('auth/sendSignInLink', payload)
    },

    async deleteCompany() {
      this.activeDeletingPrompt = true

      if (!this.temrminateCooperationId) {
        return
      }

      await this.$vs.loading()

      await this.$db.collection('agency-settings').doc(this.temrminateCooperationId).delete()

      this.$vs.notify({
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('info.theCooperationHasBeenTerminated'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })

      this.activeDeletingPrompt = false

      await this.$vs.loading.close()
    },

    toggleSidebar(isOpened = false, data) {
      this.$serverBus.$emit('company-settings-data', isOpened ? data : null)

      this.isOpenedSidebar = isOpened
      this.uidAgencySettingsId = isOpened && data.uidAgencySettings ? data.uidAgencySettings : null

      if (!isOpened) {
        this.selected = []
      }
    },

    setCooperationColorByType(value) {
      switch (value) {
        case 'confirmed':
          return 'rgb(40, 199, 111)'
        case 'waiting':
          return 'rgb(245, 220, 0)'
        case 'canceled':
          return 'rgb(234, 84, 85)'
      }
    },

    chipColor(value) {
      if (value) {
        return 'success'
      }

      return 'danger'
    }
  }
}
</script>

<style lang="scss">
#agency-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
          box-shadow: none !important;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
