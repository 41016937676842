var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "add-new-data-sidebar items-no-padding",
      attrs: {
        spacer: "",
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [
            _vm._v(
              _vm._s(
                _vm.id
                  ? _vm.$t("info.editBookableAppointment").toUpperCase()
                  : _vm.$t("info.addNewBookableAppointment").toUpperCase()
              )
            ),
          ]),
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "row" } },
            [
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "primary",
                  size: "large",
                  type: "flat",
                  icon: "save",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.saveAppointment.apply(null, arguments)
                  },
                },
              }),
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "dark",
                  size: "large",
                  type: "flat",
                  icon: "close",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.onClose.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c(
        _vm.scrollbarTag,
        {
          key: _vm.$vs.rtl,
          tag: "component",
          staticClass: "scroll-area--data-list-add-new",
          attrs: { settings: _vm.settings },
        },
        [
          _c(
            "vx-card",
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full mb-2",
                attrs: {
                  name: "name",
                  "label-placeholder": _vm.$t("inputs.nameOfAppointment"),
                  "data-vv-as": _vm.$t("inputs.nameOfAppointment"),
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("name"))),
              ]),
              _c("vs-input", {
                staticClass: "w-full mt-6 mb-2",
                attrs: {
                  name: "company-display-name",
                  "label-placeholder": _vm.$t("inputs.setDisplayCompanyName"),
                  "data-vv-as": _vm.$t("inputs.setDisplayCompanyName"),
                },
                model: {
                  value: _vm.companyDisplayName,
                  callback: function ($$v) {
                    _vm.companyDisplayName = $$v
                  },
                  expression: "companyDisplayName",
                },
              }),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|numeric",
                    expression: "'required|numeric'",
                  },
                ],
                staticClass: "w-full mt-6 mb-2",
                attrs: {
                  name: "leadTime",
                  type: "number",
                  "label-placeholder": _vm.$t(
                    "info.leadTimeForTheNextBookableAppointmentInMinutes"
                  ),
                  "data-vv-as": _vm.$t(
                    "info.leadTimeForTheNextBookableAppointmentInMinutes"
                  ),
                },
                model: {
                  value: _vm.leadTime,
                  callback: function ($$v) {
                    _vm.leadTime = $$v
                  },
                  expression: "leadTime",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("leadTime"))),
              ]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|numeric",
                    expression: "'required|numeric'",
                  },
                ],
                staticClass: "w-full mt-6 mb-2",
                attrs: {
                  name: "duration",
                  type: "number",
                  "label-placeholder": _vm.$t(
                    "info.appointmentDurationInMinutes"
                  ),
                  "data-vv-as": _vm.$t("info.appointmentDurationInMinutes"),
                },
                model: {
                  value: _vm.duration,
                  callback: function ($$v) {
                    _vm.duration = $$v
                  },
                  expression: "duration",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("duration"))),
              ]),
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("info.setPeriodForUsersToBookOnYourCalendar")
                      )
                    ),
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      autoscroll: "",
                      name: "bookingPeriodInWeeks",
                      label: "name",
                      options: _vm.bookingPeriodInWeeksOptions,
                      "data-vv-as": _vm.$t("info.bookingPeriodInWeeks"),
                      clearable: true,
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      reduce: function (op) {
                        return op.id
                      },
                      "validate-on": "blur",
                    },
                    model: {
                      value: _vm.bookingPeriodInWeeks,
                      callback: function ($$v) {
                        _vm.bookingPeriodInWeeks = $$v
                      },
                      expression: "bookingPeriodInWeeks",
                    },
                  }),
                  _c("div", [
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("bookingPeriodInWeeks"))),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "mt-4 flex items-center" }, [
                _c("div", { staticClass: "mr-2" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.isPrivacyInformationEnabled =
                            !_vm.isPrivacyInformationEnabled
                        },
                      },
                    },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.isPrivacyInformationEnabled,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.enablePrivacyInformation"))),
                ]),
              ]),
              _vm.isPrivacyInformationEnabled
                ? _c(
                    "div",
                    { staticClass: "mt-4 mr-2 w-full privacy-quill" },
                    [
                      _c("quill-editor", {
                        ref: "quillEditorA",
                        attrs: { options: _vm.editorOption },
                        model: {
                          value: _vm.privacyInformationHtml,
                          callback: function ($$v) {
                            _vm.privacyInformationHtml = $$v
                          },
                          expression: "privacyInformationHtml",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-5 assignment-actions" },
                [
                  _c(
                    "vs-radio",
                    {
                      attrs: { "vs-name": "assignType", "vs-value": "agent" },
                      on: {
                        change: function ($event) {
                          _vm.assignedTo = ""
                        },
                      },
                      model: {
                        value: _vm.assignType,
                        callback: function ($$v) {
                          _vm.assignType = $$v
                        },
                        expression: "assignType",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.assignToAgent")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm.userGroups.length
                    ? _c(
                        "vs-radio",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            "vs-name": "assignType",
                            "vs-value": "group",
                          },
                          on: {
                            change: function ($event) {
                              _vm.assignedTo = ""
                            },
                          },
                          model: {
                            value: _vm.assignType,
                            callback: function ($$v) {
                              _vm.assignType = $$v
                            },
                            expression: "assignType",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("vue.assignToGroup")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.assignType === "agent"
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          autoscroll: "",
                          name: "assignedTo",
                          label: "name",
                          options: _vm.currentCompanyUsers,
                          "data-vv-as": _vm.$t("vue.agent"),
                          clearable: true,
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          reduce: function (op) {
                            return op.id
                          },
                          placeholder: _vm.$t("vue.chooseAgent"),
                          "validate-on": "blur",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "select-item" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(option.name) +
                                          "\n\n              "
                                      ),
                                      option.isSynced
                                        ? _c("CalendarIcon", {
                                            attrs: {
                                              color: "#575757",
                                              width: 13,
                                              height: 13,
                                            },
                                          })
                                        : _c("CalendarCrossedIcon", {
                                            attrs: {
                                              color: "#F05541",
                                              width: 13,
                                              height: 13,
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4162790196
                        ),
                        model: {
                          value: _vm.assignedTo,
                          callback: function ($$v) {
                            _vm.assignedTo = $$v
                          },
                          expression: "assignedTo",
                        },
                      })
                    : _vm._e(),
                  _vm.assignType === "group"
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          autoscroll: "",
                          name: "assignedTo",
                          label: "name",
                          "data-vv-as": _vm.$t("vue.group"),
                          clearable: false,
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          options: _vm.userGroups,
                          reduce: function (op) {
                            return op.id
                          },
                          placeholder: _vm.$t("vue.chooseGroup"),
                          "validate-on": "blur",
                        },
                        model: {
                          value: _vm.assignedTo,
                          callback: function ($$v) {
                            _vm.assignedTo = $$v
                          },
                          expression: "assignedTo",
                        },
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("assignedTo"))),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "mt-5" }, [
                _vm._v("Appointemnt picture"),
              ]),
              _c(
                "div",
                { staticClass: "mt-2 add-new-data-sidebar__upload-picture" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "add-new-data-sidebar__upload-picture__avatar-wrapper",
                    },
                    [
                      _c(
                        "croppa",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required_if:assignedTo,group",
                              expression: "'required_if:assignedTo,group'",
                            },
                          ],
                          ref: "myPhoto",
                          staticClass: "upload-img shadow-md",
                          staticStyle: { left: "20px", top: "5px" },
                          attrs: {
                            name: "appointmentPicture",
                            "canvas-color": "default",
                            width: 70,
                            height: 70,
                            "placeholder-font-size": 14,
                            placeholder: _vm.$t("vue.clickHere"),
                            "show-remove-button": false,
                            "image-border-radius": 80,
                            "prevent-white-space": "",
                            "initial-size": "contain",
                            disable: false,
                            accept: ".jpeg,.jpg,.gif,.png",
                            "file-size-limit": 2048000,
                          },
                          on: {
                            "file-type-mismatch": _vm.onImageRemoved,
                            "file-size-exceed": _vm.onFileSizeExceed,
                            "new-image-drawn": _vm.onNewImageDrawn,
                            "image-remove": _vm.onImageRemoved,
                            "file-choose": _vm.onFileChoose,
                          },
                          model: {
                            value: _vm.originalPhotoURL,
                            callback: function ($$v) {
                              _vm.originalPhotoURL = $$v
                            },
                            expression: "originalPhotoURL",
                          },
                        },
                        [
                          _c("Avatar", {
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              left: "0",
                            },
                            attrs: {
                              size: "70px",
                              fontSize: "28px",
                              photoURL: _vm.appointmentPhotoURL,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "add-new-data-sidebar__upload-picture__buttons-wrapper",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "upload-photo mr-4 sm:mb-0 mb-2",
                          on: {
                            click: function ($event) {
                              return _vm.selectImage()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.uploadPhotoButtonText))]
                      ),
                      _vm.hasPhoto
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "upload-photo mr-4 mb-2",
                              attrs: { type: "border", color: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.removePhoto()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("vue.deletePhoto")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", [
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("appointmentPicture"))),
                ]),
              ]),
              _vm.assignedTo
                ? _c("div", { staticClass: "mt-5" }, [
                    _vm.assignType === "agent"
                      ? _c("div", [
                          _vm._v(_vm._s(_vm.$t("inputs.agentAvailability"))),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(_vm.$t("inputs.groupAvailability"))),
                        ]),
                    _c(
                      "div",
                      { staticClass: "users-list" },
                      _vm._l(_vm.filteredListOfSelectedUsers, function (user) {
                        return _c(
                          "div",
                          {
                            key: user.id,
                            ref: user.id,
                            refInFor: true,
                            staticClass: "users-list__item",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "users-list__item__main-info" },
                              [
                                _c("Avatar", {
                                  staticClass: "users-list__item__avatar",
                                  attrs: {
                                    size: "45px",
                                    photoURL: user.image,
                                    name: user.name,
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "users-list__item__name" },
                                  [_vm._v(_vm._s(user.name))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "users-list__item__calendar-info",
                                  },
                                  [
                                    user.isSynced
                                      ? [
                                          _c("CalendarIcon", {
                                            attrs: {
                                              color: "#575757",
                                              width: 13,
                                              height: 13,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "users-list__item__calendar-info__label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "calendar.syncedCalendar"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _c("CalendarCrossedIcon", {
                                            attrs: {
                                              color: "#F05541",
                                              width: 13,
                                              height: 13,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "users-list__item__calendar-info__label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "calendar.calendarNotSynced"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "users-list__item__button-toggle",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleClassByRef(
                                          user.id,
                                          "users-list__item_expanded"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ChevronDownIcon" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.getFilteredAvailableTimeSlots(user).length
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "users-list__item__slots-list",
                                  },
                                  _vm._l(
                                    _vm.getFilteredAvailableTimeSlots(user),
                                    function (slot, d) {
                                      return _c(
                                        "div",
                                        {
                                          key: d,
                                          staticClass:
                                            "users-list__item__slots-list__slot",
                                        },
                                        [
                                          _c("vs-icon", {
                                            staticClass:
                                              "users-list__item__slots-list__slot__icon",
                                            attrs: {
                                              size: "17px",
                                              icon: "check",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mb-2 users-list__item__slots-list__slot__day",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.modifyDayNameToLong(
                                                        slot.day
                                                      )
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            slot.times,
                                            function (time, t) {
                                              return _c(
                                                "div",
                                                { key: t, staticClass: "mt-1" },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .format12HrTime(
                                                            time.start
                                                          )
                                                          .toLowerCase()
                                                      ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm
                                                            .format12HrTime(
                                                              time.end
                                                            )
                                                            .toLowerCase()
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "div",
                                  { staticClass: "users-list__item__no-slots" },
                                  [
                                    _c("vs-icon", {
                                      staticClass:
                                        "users-list__item__no-slots__icon",
                                      attrs: {
                                        "icon-pack": "material-icons",
                                        icon: "error",
                                        size: "22px",
                                        color: "#F05541",
                                      },
                                    }),
                                    _vm._v(
                                      "\n\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "info.agentHasNotYetAnyAvailabilities"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "advanced-settings" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("info.advancedSettings")) +
                    "\n\n        "
                ),
                _c(
                  "button",
                  {
                    staticClass: "advanced-settings__button-toggle",
                    on: {
                      click: function ($event) {
                        _vm.isOpenedAdvancedSettings =
                          !_vm.isOpenedAdvancedSettings
                      },
                    },
                  },
                  [
                    !_vm.isOpenedAdvancedSettings
                      ? _c("feather-icon", {
                          attrs: { icon: "ChevronDownIcon" },
                        })
                      : _c("feather-icon", {
                          attrs: { icon: "ChevronUpIcon" },
                        }),
                  ],
                  1
                ),
              ]),
              _vm.isOpenedAdvancedSettings
                ? [
                    _c(
                      "div",
                      { staticClass: "advanced-settings__info" },
                      [
                        _c("vs-icon", {
                          staticClass: "advanced-settings__info__icon",
                          attrs: {
                            "icon-pack": "material-icons",
                            icon: "error",
                            size: "22px",
                            color: "#F05541",
                          },
                        }),
                        _c("span", { staticClass: "attention-label" }, [
                          _vm._v(_vm._s(_vm.$t("vue.attention"))),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "info.advancedSettingsInfoOfAssignmentRules"
                              )
                            ) +
                            "\n\n          "
                        ),
                        _c("ul", { staticClass: "options-list" }, [
                          _c("li", { staticClass: "options-list__item" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "info.advancedSettingsDescritpionOfAssignmentRules"
                                  )
                                ) +
                                "\n              "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "options-list__item__link",
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("setActiveTabIndex", 2)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("vue.assignmentRules").toLowerCase()
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex items-center mt-4" },
                      [
                        _c("vs-switch", {
                          key: "areAssignmentRules",
                          staticClass: "mr-3",
                          attrs: { color: "#275D73" },
                          model: {
                            value: _vm.areAssignmentRules,
                            callback: function ($$v) {
                              _vm.areAssignmentRules = $$v
                            },
                            expression: "areAssignmentRules",
                          },
                        }),
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("vue.useTheAssignmentRules"))),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }