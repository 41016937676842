<template>
  <div>
    <vx-card class="mb-4" :title="$t('legal.headerText')" title-color="primary" subtitle-color="danger">
      <p class="mb-3">{{ $t('legal.descriptionText') }}</p>
    </vx-card>
    <vx-card>
      <div class="pathadvice-legal">
        <div class="pathadvice-legal-items">
          <div class="pathadvice-legal-items-header">{{ $t('legal.licenseAgreementHeader') }}</div>
          <div class="pathadvice-legal-items-item">
            <div
              class="pathadvice-legal-items-item-col"
              @click="
                openExternalFile(
                  'https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100D_Lizenzvertrag_Datenverarbeitung_LetsConnect_DE_20221018.pdf'
                )
              "
            >
              <div class="w-full">PATHADVICE_Connect_Lizenzvertrag_inkl_Datenverarbeitungsvereinbarung_DE</div>
              <div class="download">
                <download-icon color="#575757" :width="18" :height="18" />
              </div>
            </div>

            <div
              class="pathadvice-legal-items-item-col"
              @click="
                openExternalFile(
                  'https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100E_LicenseAgreement_DataProcessing_Agreement_LetsConnect_EN_20221018.pdf'
                )
              "
            >
              <div class="w-full">PATHADVICE_Connect_LicenceAgreement_incl_DataProcessingAgreement_EN</div>
              <div class="download">
                <download-icon color="#575757" :width="18" :height="18" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
export default {
  components: {
    DownloadIcon
  },
  data() {
    return {
      affiliate: []
    }
  },
  computed: {
    ...mapGetters({
      company: 'company'
    })
  },
  async mounted() {
    if (this.company && this.company.affiliatePartner) {
      const companyaffiliate = this.$functions.httpsCallable('getAffiliateInfo')
      const affiliateData = await companyaffiliate({ affiliateId: this.company.affiliatePartner })

      if (affiliateData) {
        this.affiliate = { company: affiliateData.data.company, level: affiliateData.data.level }
      }
    }
  },
  methods: {
    openExternalFile(path) {
      const today = new Date()
      const iToday = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`)
      window.open(`${path}?v=${iToday.getTime()}`, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.pathadvice-legal {
  .download {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-header {
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: 38px;
    text-transform: uppercase;
    color: #12598d;
    margin-bottom: 15px;
    @media only screen and (max-width: 416px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 417px) and (max-width: 675px) {
      font-size: 25px;
    }
    @media only screen and (min-width: 676px) {
      font-size: 30px;
    }
  }
  &-summary {
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #12598d;
    margin-bottom: 15px;
    @media only screen and (max-width: 416px) {
      font-size: 9px;
    }
    @media only screen and (min-width: 417px) and (max-width: 675px) {
      font-size: 13px;
    }
    @media only screen and (min-width: 676px) {
      font-size: 16px;
    }
  }

  &-items {
    width: 100%;
    &-header {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 20px;
      color: #000000;
    }
    &-item {
      margin-bottom: 10px;
      display: flex;
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
      @media only screen and (min-width: 601px) {
        flex-direction: row;
      }
      justify-content: space-between;

      &-col {
        display: flex;
        cursor: pointer;
        justify-content: flex-start;
        padding: 0 24px;
        align-items: center;
        background: #e6ecf1;
        border-radius: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        min-height: 43.4px;
        color: #000000;

        @media only screen and (max-width: 600px) {
          width: 98%;
          margin-bottom: 10px;
        }
        @media only screen and (min-width: 601px) {
          width: 49%;
        }
      }
    }
  }
}
</style>
