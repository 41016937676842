var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.75",
          y: "0.75",
          width: "23.5",
          height: "23.5",
          rx: "5.25",
          "stroke-width": "1.5",
          stroke: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.2927 8.29741C15.5744 8.01569 15.9565 7.85742 16.3549 7.85742C16.7533 7.85742 17.1354 8.01569 17.4172 8.29741C17.6989 8.57913 17.8571 8.96122 17.8571 9.35963C17.8571 9.75804 17.6989 10.1401 17.4172 10.4219L10.6897 17.1493L7.85714 17.8574L8.56529 15.0248L15.2927 8.29741Z",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          stroke: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }