<template>
  <div id="appointments-view" class="assignment-view-container">
    <AppointmentsSidebar :isSidebarActive="isOpenedSidebar" :id="id" @closeAppointmentsSidebar="toggleRulesDataSidebar" @setActiveTabIndex="setActiveTabIndex" />

    <div :class="!HAS_ACCESS ? 'blur-background' : ''">
      <vx-card title-color="primary" subtitle-color="danger" :title="$t('routes.appointmentSettings')" class="mb-4">
        <p class="mb-1">{{ $t('info.createSettingsForTheAbilityToBookAMeeting') }}</p>
        <p class="mb-3">
          <router-link to="/web-connect">{{ $t('info.goToDialogOverview') }}</router-link>
        </p>

        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button color="primary" type="filled" icon-size="24px" icon="add" @click="addNewAppointment">
              {{ $t('info.addSchedule') }}
            </vs-button>
          </vs-row>
        </div>
      </vx-card>

      <vx-card v-if="appointments && appointments.length > 0">
        <vs-table ref="table" pagination :max-items="itemsPerPage" :data="appointments" search :noDataText="$t('views.home.noDataAvailable')">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-end">
            <vs-dropdown vs-trigger-click class="cursor-pointer items-per-page-handle mr-4 mb-4">
              <div class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium" style="border-radius: 5px">
                <span class="mr-2">
                  {{ firstRecordNumber }} - {{ lastRecordNumber }} {{ $t('vue.of') }}
                  {{ queriedItems }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 3">
                  <span>3</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <template slot="thead">
            <vs-th sort-key="name" style="width: 40%">{{ $t('info.appointment') }}</vs-th>
            <vs-th sort-key="duration" style="width: 40%">{{ $t('info.duration') }}</vs-th>
            <vs-th style="width: 300px">{{ $t('vue.action') }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="tr.id" v-for="tr in data">
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.name }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.duration }}
                  </p>
                </vs-td>
                <vs-td>
                  <div>
                    <vs-prompt :title="$t('modals.deleteAppointment')" @cancel="onCloseModal" @accept="deleteAppointment" @close="onCloseModal" color="danger" :cancel-text="$t('vue.cancel')" :accept-text="$t('vue.delete')" :active.sync="isOpenedRemovingDialog">
                      <div class="con-exemple-prompt" v-if="selectedAppointment">
                        <p>
                          <strong>{{ selectedAppointment.name }}</strong>
                        </p>
                        {{ $t('modals.deleteAppointmentQuestion') }}
                      </div>
                    </vs-prompt>

                    <div class="flex" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
                      <vx-tooltip :text="$t('vue.edit')" position="top" class="ml-3">
                        <vs-button color="primary" type="border" size="medium" icon-pack="feather" icon="icon-edit" @click="() => edit(tr.id)"></vs-button>
                      </vx-tooltip>
                      <vx-tooltip :text="$t('vue.copy')" position="top" class="ml-3">
                        <vs-button color="#3EA54E" type="border" size="medium" icon-pack="feather" icon="icon-copy" v-clipboard:copy="getMeetingLink(tr.id)" v-clipboard:success="onCopy" v-clipboard:error="onError"></vs-button>
                      </vx-tooltip>
                      <vx-tooltip :text="$t('vue.delete')" position="top" class="ml-3">
                        <vs-button color="danger" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click="() => openRemovingDialog(tr)"></vs-button>
                      </vx-tooltip>
                    </div>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <scheduling-message v-if="!HAS_ACCESS"></scheduling-message>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)
import { mapGetters } from 'vuex'

import AppointmentsSidebar from './AppointmentsSidebar.vue'
import SchedulingMessage from '@/components/upgrade-messages/SchedulingMessage.vue'

export default {
  components: {
    AppointmentsSidebar,
    SchedulingMessage
  },

  data: () => ({
    itemsPerPage: 5,
    isOpenedRemovingDialog: false,
    id: null,
    dialogId: null,
    selectedAppointment: null,

    isOpenedSidebar: false,
    appointments: [],
    unsubscribeAppointments: null
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      user_groups: 'user_groups',
      company: 'company'
    }),
    firstRecordNumber() {
      let currentPage = 1
      if (this.$refs.table && this.$refs.table.currentx) {
        currentPage = this.$refs.table.currentx
      }
      if (this.queriedItems === 0) {
        return 0
      }

      const x = (currentPage - 1) * this.itemsPerPage
      return x + 1
    },
    lastRecordNumber() {
      const recordNumber = this.firstRecordNumber + this.itemsPerPage - 1
      return recordNumber < this.queriedItems ? recordNumber : this.queriedItems
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.appointments.length
    },
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('scheduling') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    }
  },

  async created() {
    await this.subscribeAppointments()
  },

  mounted() {
    this.$serverBus.$on('toggle-sidebar', (value) => {
      this.isOpenedSidebar = value
    })
  },

  beforeDestroy() {
    if (this.unsubscribeAppointments) {
      this.unsubscribeAppointments()
    }
  },

  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    getCorrectLocationUrl(url) {
      return url && url.includes('capacitor') ? this.$config.hostUrl : location.origin
    },
    getMeetingLink(id) {
      return `${this.getCorrectLocationUrl(location.origin)}/book-meeting/${this.company.id}/?cid=${id}`
    },

    onCloseModal() {
      this.isOpenedRemovingDialog = false
      this.selectedAppointment = null
      this.id = null
    },

    onCopy() {
      this.$vs.notify({
        title: this.translate('vue.success'),
        text: this.translate('vue.linkCopiedSuccessfully'),
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },

    onError() {
      this.$vs.notify({
        title: this.translate('vue.error'),
        text: this.translate('vue.errorCopyingText'),
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    },

    async openRemovingDialog(tr) {
      this.id = tr.id
      this.selectedAppointment = tr
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        await this.$vs.loading()

        await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').doc(this.id)

        const dialogsRef = await this.$db.collection('dialogs').where('company', '==', this.activeUserInfo.company).get()

        dialogsRef.docs.forEach((dialogRef) => {
          const dialogData = dialogRef.data()

          if (dialogData.contactForm && dialogData.contactForm.selectedAppointment === this.id) {
            this.dialogId = dialogRef.id
          }
        })

        this.isOpenedRemovingDialog = true

        await this.$vs.loading.close()
      }
    },

    edit(id) {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        this.id = null

        setTimeout(() => {
          this.id = id
          this.isOpenedSidebar = true
        }, 100)
      }
    },

    async deleteAppointment() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        setTimeout(async () => {
          this.isOpenedRemovingDialog = false

          await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').doc(this.id).delete()

          if (this.dialogId) {
            await this.$db
              .collection('dialogs')
              .doc(this.dialogId)
              .set(
                {
                  contactForm: {
                    selectedAppointment: '',
                    isBookingEnabled: false
                  }
                },
                { merge: true }
              )
          }

          this.id = null

          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.success'),
            text: this.$i18n.t('vue.changesSavedSuccessfully'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }, 500)
      }
    },
    async setActiveTabIndex(value) {
      await this.toggleRulesDataSidebar()

      this.$emit('setActiveTabIndex', value)
    },

    toggleRulesDataSidebar(val = false) {
      this.isOpenedSidebar = val
    },

    addNewAppointment() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        this.id = null
        this.isOpenedSidebar = true
      }
    },

    async subscribeAppointments() {
      const query = await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments')

      this.unsubscribeAppointments = query.onSnapshot((ref) => {
        this.appointments = []

        ref.docs.forEach((doc) => {
          const { name, duration, assignType, assignedTo } = doc.data()

          const appointment = {
            name,
            duration,
            assignType,
            assignedTo,
            id: doc.id
          }

          this.appointments.push(appointment)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.blur-background {
  filter: blur(2px);
}
.assignment-view-container {
  min-height: 300px;
}
</style>
