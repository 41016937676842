<template>
  <transition name="fade-bottom-2x">
    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
      <div class="agreement-text" id="divagreementtext" v-on:scroll="handleScroll">
        <div class="pt-2 pb-2 pr-2 pl-2" align="right">
          <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-x" @click="closeAgreement()"></vs-button>
        </div>
        <div class="agreement-header">
          <div class="agreement-header-left">
            <div class="vx-logo cursor-pointer flex items-center router-link-active">
              <img src="/img/logo.2f12cf77.svg" class="mr-4 fill-current text-primary" />
              <span class="text-primary">PATHADVICE</span>
            </div>
          </div>
          <div class="agreement-header-right" align="right">
            AFFILIATE PARTNER <br />
            AGREEMENT<br />
            Version 1.0
          </div>
        </div>
        <br />
        <br />
        <br />
        <strong> AFFILIATE PARTNER AGREEMENT </strong>
        <br />
        <br />
        This Affiliate Partner Agreement (“Agreement”) is by and between PATHADVICE and the entity or individual identified as Affiliate Partner. This Agreement
        governs the parties’ relationship under which Affiliate Partner will assist and cooperate with PATHADVICE in identifying and marketing certain
        PATHADVICE services (collectively, the “PATHADVICE Service”) and under which Affiliate Partner may receive compensation in connection therewith, all as
        further detailed herein. If you are entering into this Agreement on behalf of an entity, you represent and warrant that you have the authority to bind
        such entity to all of the terms and conditions of this Agreement.
        <br />
        <br />
        <strong> 1. Affiliate Appointment </strong>
        <br />
        PATHADVICE hereby appoints you as Affiliate Partner for the PATHADVICE Service under the terms and conditions of this Agreement and Affiliate Partner
        accepts this appointment. This Agreement (and the appointment) is non-exclusive in all respects.
        <br />
        <br />
        <strong> 2. Affiliate Fees </strong>
        <br />
        <br />
        As payment for the Services in connection with the PATHADVICE product “CONNECT" delivered, PA shall pay the Affiliate Partner for the duration of this
        Agreement as follows:
        <br />
        <br />
        (1) Eight percent (8%) of the License Fees paid to PATHADVICE.
        <br />
        (2) Ten percent (10%) of the License Fees paid to PATHADVICE in case the Affiliate Partner provides more than 2 clients (applicable for all clients one
        month after reaching more than 2 clients)
        <br />
        <br />
        No Affiliate Fees will be due on any other items, initiation or setup fees, professional services or training, overages, renewals, extensions or any
        other fees of any kind payable to PATHADVICE. PATHADVICE may issue, from time to time, written terms and conditions regarding its Affiliate Partner
        program such as: (a) the qualifications for Affiliate Partners, (b) the applicable territory covered by this Agreement, (c) the resources and support
        that PATHADVICE will make available to Affiliate Partners, (d) the means by which the parties will cooperate in marketing the PATHADVICE Service and/or
        (e) other relevant terms of the parties’ cooperation. PATHADVICE may modify the applicable Program Terms on 30 days written notice, provided that the
        effect of any modification will be prospective only and will not affect any pending referrals or accrued obligations. All resources and support provided
        to Affiliate Partner under this Agreement are for use solely during the Term and for the purposes of this Agreement.
        <br />
        <br />
        <strong> 3. PATHADVICE Account </strong>
        <br />
        PATHADVICE may provide Affiliate Partner with a PATHADVICE account through which Affiliate Partner can access and use the PATHADVICE Service solely to
        demonstrate the features and functionality of the PATHADVICE Service to prospective customers (i.e., not for Affiliate Partner’s own commercial
        purposes). In the event that Affiliate Partner`s use of the PATHADVICE Service exceeds reasonable limits determined by PATHADVICE or other restrictions
        imposed on Affiliate Partner`s use of the PATHADVICE Service hereunder, PATHADVICE may terminate Affiliate Partner`s access to the PATHADVICE Service
        immediately and without notice.
        <br />
        <br />
        <br />
        <br />
        <strong> 4. Marketing Materials </strong>
        <br />
        PATHADVICE may, from time to time, provide Affiliate Partner with advertising, publicity or marketing materials (collectively “Marketing Materials”) for
        use in connection with this Agreement. Use of any Marketing Materials is subject to such terms and conditions as PATHADVICE may specify in writing.
        Except for the limited license provided herein, PATHADVICE reserves all rights to the Marketing Materials, including, without limitation, all rights
        under copyright and trademark law.
        <br />
        <br />
        <strong> 5. Trademark License </strong>
        <br />
        Each party hereto hereby grants to the other a non-exclusive, nontransferable (except in connection with the assignment of this Agreement as expressly
        permitted herein), royalty-free license to use its relevant trademarks and service marks (“Marks”) solely to perform the marketing and promotional tasks
        authorized in this Agreement. Use of each party’s Marks shall conform to any trademark usage guidelines and requirements provided in writing by the
        owner of such Mark. All use of a Mark and any resulting goodwill shall accrue solely to the benefit of the owner of such Mark. No rights or permissions
        regarding Marks are granted other than the license expressly granted herein.
        <br />
        <br />
        <strong> 6. Restrictions </strong>
        <br />
        Affiliate Partner (a) is not authorized to make offers, accept orders, negotiate or make agreements in PATHADVICE’s name, to transact any business in
        the name of PATHADVICE, or to assume or create any obligation or responsibility binding upon PATHADVICE, and without limiting the foregoing, Affiliate
        Partner is not authorized to make any representations, warranties, covenants or promises of any kind to any party with respect to PATHADVICE or the
        PATHADVICE Service; (b) will not use or exploit the PATHADVICE Service except as may be expressly permitted in this Agreement and (c) will not use the
        Confidential Information of PATHADVICE or access the PATHADVICE Service to design or create competing products or services or any derivative of the
        PATHADVICE Service.
        <br />
        <br />
        <strong> 7. Confidentiality </strong>
        <br />
        Either party may disclose or make available to the other party certain non-public information, including the terms of this Agreement hereunder
        (“Confidential Information”). Each party agrees that: (a) it will use Confidential Information of the other party solely for the purpose(s) of this
        Agreement; and (b) it will take all reasonable precautions to ensure that it does not disclose Confidential Information belonging to the other party to
        any third party (other than its employees and/or agents on a need-to-know basis who are bound by obligations of nondisclosure and limited use at least
        as stringent as those contained herein) without first obtaining such other party’s written consent. Upon request, a party will return all copies of the
        other party’s Confidential Information. Each party will be responsible for any breach of this section by its employees and/or agents.
        <br />
        <br />
        <strong> 8. Term and Termination </strong>
        <br />
        <span class="font-9">
          This agreement shall start on the Effective Date, and last until terminated by one of the parties.
          <br />
          <br />
          <strong> Termination for breach by Affiliate Partner. </strong>
          <br />
          PA may terminate this Agreement upon thirty (30) days’ written notice to Affiliate Partner in the event of a breach of a material provision of this
          Agreement, provided that Affiliate Partner fails to cure such breach within an adequate time. The Parties agree that a breach has occurred if (i)
          Affiliate Partner commits a material breach to this agreement; or (ii) Affiliate Partner falsifies any information provided to PA, a Customer or a
          Customer prospect; or (iii) Affiliate Partner fails to reach its targets pursuant to Section 1.4. PA may terminate this Agreement immediately, when
          the Affiliate Partner or its staff manipulates or attempts to manipulate the cooperation for personal gain at the expense of customers, shareholders,
          or the Partner or its Affiliates. In that case PA retains the right to terminate commission , compensation or other payments and Affiliate Partner
          will forfeit and be ineligible to receive any award under the cooperation.
          <br />
          <strong> Termination for breach by PA. </strong>
          <br />
          Affiliate Partner may terminate this Agreement upon thirty (30) days’ written notice to PA in the event of a breach of a material provision of this
          Agreement, provided that PA fails to cure such breach within a reasonable notice period. The Parties agree that a breach has occurred if (i) PA fails
          to renumerate Affiliate Partner pursuant to Section 2; or (ii) PA falsifies any information provided to Affiliate Partner.
          <br />
          <strong> Other. </strong>
          <br />
          Each party shall have the right to terminate this Agreement for any or no reason, upon 90 days’ written notice to the other party. In such event,
          Affiliate Partner shall be further entitled to render a final invoice to PA about 30% of the last annual revenues whereas the Parties agree mutually,
          that with the payment of the final invoice all obligations of PA arising out of this Agreement are settled.
        </span>
        <br />
        <br />
        <strong> 9. Disclaimer </strong>
        <br />
        EXCEPT AS EXPRESSLY STATED HEREIN, NEITHER PARTY MAKES ANY REPRESENTATIONS OR WARRANTIES TO THE OTHER, EITHER EXPRESS OR IMPLIED, AND EACH PARTY
        SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT AND/OR FITNESS FOR A PARTICULAR PURPOSE.
        <br />
        <br />
        <strong> 10. Limitation of Liability </strong>
        <br />
        Except with regard to a breach of Section 6: (a) in no event will either party be liable to the other for any incidental, consequential, or other
        indirect loss or damage, including lost profits and (b) each party’s entire liability for any and all claims, losses, damages, or expenses from any
        cause whatsoever shall be limited to direct, proven damages in an amount not to exceed thousand dollars ($1,000.00) in the aggregate for all such
        claims.
        <br />
        <br />
        <strong> 11. General </strong>
        <br />
        (a) Independent Contractors: PATHADVICE and Affiliate Partner are independent contractors under this<br />
        Agreement and nothing herein shall be construed to create a partnership, joint venture or agency relationship; (b) Assignment: Neither party may assign
        performance of this Agreement or any of its rights or delegate any of its duties under this Agreement without the prior written consent of the other,
        except that each party may assign this Agreement without the other party’s prior written consent in the case of a merger, acquisition or other change of
        control, and in such event this Agreement shall be binding upon and inure to the benefit of the parties hereto and their respective successors and
        assigns; (c) Governing Law: This Agreement shall be governed by the laws of Liechtenstein for PARTNERS domiciled in EC and UK law for PARTNERS domiciled
        outside EC; (d) Forum Selection: Any and all disputes arising out of or related to this Agreement or performance hereof, shall be brought exclusively in
        the state courts of Liechtenstein and the parties hereby waive any objection thereto; (e) Notices: All notices under this Agreement must be in writing
        and delivered either by hand, e-mail, certified mail (return receipt requested, postage pre-paid) or nationally recognized overnight delivery service
        (all delivery charges prepaid) and addressed, if to Affiliate Partner, to the contact provided by the Affiliate Partner and, if to PATHADVICE, to
        PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria; (f) Effect of Waivers: The waiver by either party of a breach or a default of any
        provision shall not be construed as a waiver of any succeeding breach of the same or any other provision; (g) Survival: All terms of this Agreement
        which by their nature extend beyond the termination of this Agreement, remain in effect until fulfilled and apply to respective successors and assigns;
        (h) Counterparts; Delivery; Acceptance: This Agreement may be executed in counterparts, all of which are considered one and the same agreement, and
        becomes effective when one or more counterparts have been signed by each of the parties and delivered to the other party. Delivery by facsimile or
        e-mail and online acceptance are all as effective as physical delivery of an originally executed copy hereof; (i) Integration; Amendment: This Agreement
        constitutes the entire understanding of the parties hereto with respect to the matters contemplated hereby, supersedes all previous agreements between
        the parties concerning the subject matter hereof and cannot be amended except by a writing signed by authorized PARTNERs of both parties; (j) No
        Reliance: No party hereto has relied on any statement, representation or promise of any party or PARTNER thereof except as expressly set forth in this
        Agreement; (k) Severability: If any term, provision, covenant or condition of this Agreement is held invalid or unenforceable for any reason, the
        remainder of the provisions will continue in full force and effect as if this Agreement had been executed with the invalid portion eliminated. The
        parties further agree to substitute for the invalid provision a valid provision that most closely approximates the intent and economic effect of the
        invalid provision.
      </div>

      <div class="agreement-confirmation">
        <vs-checkbox v-model="agree">YES, I am accepting this Affiliate Partner Agreement.</vs-checkbox>
      </div>
      <div class="container" align="right">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
      <vs-button @click="becomePartner" :disabled="!agree" class="mr-3 mb-2 flat">Become Partner </vs-button>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      agree: false,
      partnerAgreement: false,
      endOfDiv: false,
      affiliateId: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  created() {
    this.partnerAgreement = true
  },
  mounted() {
    if (document.getElementById('divagreementtext')) {
      document.getElementById('divagreementtext').addEventListener('scroll', this.handleScroll)
    }
  },
  beforeDestroy() {
    if (document.getElementById('divagreementtext')) {
      document.getElementById('divagreementtext').removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0
      const sh = document.getElementById('divagreementtext').scrollHeight
      const st = document.getElementById('divagreementtext').scrollTop
      const oh = document.getElementById('divagreementtext').offsetHeight

      if (sh - st - oh + 1 === 1) {
        this.endOfDiv = true
      }
    },
    async becomePartner() {
      const vm = this
      this.$vs.loading()
      const affiliate = {
        company: vm.activeUserInfo.company,
        createdByUser: vm.activeUserInfo.uid,
        createdDate: new Date(),
        level: 8
      }
      const affiliateRef = await this.$db.collection('affiliates').add(affiliate)
      const companyData = await this.$db.collection('company').doc(this.activeUserInfo.company).get()
      if (companyData.data() && !companyData.data().affiliate) {
        const company = this.$functions.httpsCallable('getCompanyDetailByCompanyName')
        const companydetail = await company()
        if (companydetail) {
          this.affiliateId = companydetail.data[0].affiliateCompanyId
        }
      }
      const companyRef = await this.$db.collection('company').doc(this.activeUserInfo.company)
      const companyInfo = {
        affiliatePartner: affiliateRef.id,
        affiliatePartnerCreated: new Date()
      }
      if (this.affiliateId) {
        companyInfo.affiliateCompany = this.affiliateId
      }
      await companyRef.set(companyInfo, { merge: true })
      const userRef = await this.$db.collection('users').doc(this.activeUserInfo.uid)
      const userInfo = {
        isPartner: true
      }
      await userRef.set(userInfo, { merge: true })
      this.$router.push({ name: 'partner-program' })
      this.$vs.loading.close()
    },
    closeAgreement() {
      this.partnerAgreement = false
      this.$serverBus.$emit('close-partnerAgreement')
    }
  }
}
</script>
<style>
.agreement-text {
  overflow: auto;
  height: 500px;
  background-color: #fff;
  padding: 30px 80px 30px 80px;
}
.agreement-confirmation {
  padding: 20px;
}
.agreement-header {
  display: inline-block;
  width: 100%;
}
.agreement-header-left {
  display: inline-block;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  width: 50%;
}
.agreement-header-left img {
  width: 100px;
  height: 100px;
}
.agreement-header-right {
  text-align: right;
  display: inline-block;
  font-size: 25px;
  padding-right: 10px;
  width: 50%;
}
.font-9 {
  font-size: 11px;
  font-family: 'Calibri';
}
.container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: right;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 0%;
  height: 1vh;
}

.chevron {
  position: absolute;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-animation: move-chevron 3s ease-out infinite;
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  -webkit-animation: move-chevron 3s ease-out 1s infinite;
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  -webkit-animation: move-chevron 3s ease-out 2s infinite;
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #2c3e50;
}

.chevron:before {
  left: 0;
  -webkit-transform: skewY(30deg);
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  -webkit-transform: skewY(-30deg);
  transform: skewY(-30deg);
}

@-webkit-keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    -webkit-transform: translateY(2.28rem);
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    -webkit-transform: translateY(3.12rem);
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(4.8rem) scale(0.5);
    transform: translateY(4.8rem) scale(0.5);
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    -webkit-transform: translateY(2.28rem);
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    -webkit-transform: translateY(3.12rem);
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(4.8rem) scale(0.5);
    transform: translateY(4.8rem) scale(0.5);
  }
}
</style>
