var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "assignment-view-container",
      attrs: { id: "appointments-view" },
    },
    [
      _c("AppointmentsSidebar", {
        attrs: { isSidebarActive: _vm.isOpenedSidebar, id: _vm.id },
        on: {
          closeAppointmentsSidebar: _vm.toggleRulesDataSidebar,
          setActiveTabIndex: _vm.setActiveTabIndex,
        },
      }),
      _c(
        "div",
        { class: !_vm.HAS_ACCESS ? "blur-background" : "" },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                "title-color": "primary",
                "subtitle-color": "danger",
                title: _vm.$t("routes.appointmentSettings"),
              },
            },
            [
              _c("p", { staticClass: "mb-1" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("info.createSettingsForTheAbilityToBookAMeeting")
                  )
                ),
              ]),
              _c(
                "p",
                { staticClass: "mb-3" },
                [
                  _c("router-link", { attrs: { to: "/web-connect" } }, [
                    _vm._v(_vm._s(_vm.$t("info.goToDialogOverview"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "vs-row",
                    { attrs: { "vs-justify": "flex-end" } },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "primary",
                            type: "filled",
                            "icon-size": "24px",
                            icon: "add",
                          },
                          on: { click: _vm.addNewAppointment },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("info.addSchedule")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.appointments && _vm.appointments.length > 0
            ? _c(
                "vx-card",
                [
                  _c(
                    "vs-table",
                    {
                      ref: "table",
                      attrs: {
                        pagination: "",
                        "max-items": _vm.itemsPerPage,
                        data: _vm.appointments,
                        search: "",
                        noDataText: _vm.$t("views.home.noDataAvailable"),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var data = ref.data
                              return [
                                _c(
                                  "tbody",
                                  _vm._l(data, function (tr) {
                                    return _c(
                                      "vs-tr",
                                      { key: tr.id, attrs: { data: tr } },
                                      [
                                        _c("vs-td", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "product-name font-medium truncate",
                                            },
                                            [_vm._v(_vm._s(tr.name))]
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "product-name font-medium truncate",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(tr.duration) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "vs-prompt",
                                                {
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "modals.deleteAppointment"
                                                    ),
                                                    color: "danger",
                                                    "cancel-text":
                                                      _vm.$t("vue.cancel"),
                                                    "accept-text":
                                                      _vm.$t("vue.delete"),
                                                    active:
                                                      _vm.isOpenedRemovingDialog,
                                                  },
                                                  on: {
                                                    cancel: _vm.onCloseModal,
                                                    accept:
                                                      _vm.deleteAppointment,
                                                    close: _vm.onCloseModal,
                                                    "update:active": function (
                                                      $event
                                                    ) {
                                                      _vm.isOpenedRemovingDialog =
                                                        $event
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.selectedAppointment
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "con-exemple-prompt",
                                                        },
                                                        [
                                                          _c("p", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedAppointment
                                                                    .name
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "modals.deleteAppointmentQuestion"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex",
                                                  style: {
                                                    direction: _vm.$vs.rtl
                                                      ? "rtl"
                                                      : "ltr",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        text: _vm.$t(
                                                          "vue.edit"
                                                        ),
                                                        position: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        attrs: {
                                                          color: "primary",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-edit",
                                                        },
                                                        on: {
                                                          click: function () {
                                                            return _vm.edit(
                                                              tr.id
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        text: _vm.$t(
                                                          "vue.copy"
                                                        ),
                                                        position: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        directives: [
                                                          {
                                                            name: "clipboard",
                                                            rawName:
                                                              "v-clipboard:copy",
                                                            value:
                                                              _vm.getMeetingLink(
                                                                tr.id
                                                              ),
                                                            expression:
                                                              "getMeetingLink(tr.id)",
                                                            arg: "copy",
                                                          },
                                                          {
                                                            name: "clipboard",
                                                            rawName:
                                                              "v-clipboard:success",
                                                            value: _vm.onCopy,
                                                            expression:
                                                              "onCopy",
                                                            arg: "success",
                                                          },
                                                          {
                                                            name: "clipboard",
                                                            rawName:
                                                              "v-clipboard:error",
                                                            value: _vm.onError,
                                                            expression:
                                                              "onError",
                                                            arg: "error",
                                                          },
                                                        ],
                                                        attrs: {
                                                          color: "#3EA54E",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-copy",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        text: _vm.$t(
                                                          "vue.delete"
                                                        ),
                                                        position: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        attrs: {
                                                          color: "danger",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-trash",
                                                        },
                                                        on: {
                                                          click: function () {
                                                            return _vm.openRemovingDialog(
                                                              tr
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2731625372
                      ),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap-reverse items-center flex-grow justify-end",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              staticClass:
                                "cursor-pointer items-per-page-handle mr-4 mb-4",
                              attrs: { "vs-trigger-click": "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                                  staticStyle: { "border-radius": "5px" },
                                },
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.firstRecordNumber) +
                                        " - " +
                                        _vm._s(_vm.lastRecordNumber) +
                                        " " +
                                        _vm._s(_vm.$t("vue.of")) +
                                        "\n                " +
                                        _vm._s(_vm.queriedItems) +
                                        "\n              "
                                    ),
                                  ]),
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronDownIcon",
                                      svgClasses: "h-4 w-4",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "vs-dropdown-menu",
                                [
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.itemsPerPage = 3
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("3")])]
                                  ),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.itemsPerPage = 4
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("4")])]
                                  ),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.itemsPerPage = 10
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("10")])]
                                  ),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.itemsPerPage = 15
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("15")])]
                                  ),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.itemsPerPage = 20
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("20")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c(
                            "vs-th",
                            {
                              staticStyle: { width: "40%" },
                              attrs: { "sort-key": "name" },
                            },
                            [_vm._v(_vm._s(_vm.$t("info.appointment")))]
                          ),
                          _c(
                            "vs-th",
                            {
                              staticStyle: { width: "40%" },
                              attrs: { "sort-key": "duration" },
                            },
                            [_vm._v(_vm._s(_vm.$t("info.duration")))]
                          ),
                          _c("vs-th", { staticStyle: { width: "300px" } }, [
                            _vm._v(_vm._s(_vm.$t("vue.action"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.HAS_ACCESS ? _c("scheduling-message") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }