var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: {
            title: _vm.$t("legal.headerText"),
            "title-color": "primary",
            "subtitle-color": "danger",
          },
        },
        [
          _c("p", { staticClass: "mb-3" }, [
            _vm._v(_vm._s(_vm.$t("legal.descriptionText"))),
          ]),
        ]
      ),
      _c("vx-card", [
        _c("div", { staticClass: "pathadvice-legal" }, [
          _c("div", { staticClass: "pathadvice-legal-items" }, [
            _c("div", { staticClass: "pathadvice-legal-items-header" }, [
              _vm._v(_vm._s(_vm.$t("legal.licenseAgreementHeader"))),
            ]),
            _c("div", { staticClass: "pathadvice-legal-items-item" }, [
              _c(
                "div",
                {
                  staticClass: "pathadvice-legal-items-item-col",
                  on: {
                    click: function ($event) {
                      return _vm.openExternalFile(
                        "https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100D_Lizenzvertrag_Datenverarbeitung_LetsConnect_DE_20221018.pdf"
                      )
                    },
                  },
                },
                [
                  _c("div", { staticClass: "w-full" }, [
                    _vm._v(
                      "PATHADVICE_Connect_Lizenzvertrag_inkl_Datenverarbeitungsvereinbarung_DE"
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "download" },
                    [
                      _c("download-icon", {
                        attrs: { color: "#575757", width: 18, height: 18 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pathadvice-legal-items-item-col",
                  on: {
                    click: function ($event) {
                      return _vm.openExternalFile(
                        "https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100E_LicenseAgreement_DataProcessing_Agreement_LetsConnect_EN_20221018.pdf"
                      )
                    },
                  },
                },
                [
                  _c("div", { staticClass: "w-full" }, [
                    _vm._v(
                      "PATHADVICE_Connect_LicenceAgreement_incl_DataProcessingAgreement_EN"
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "download" },
                    [
                      _c("download-icon", {
                        attrs: { color: "#575757", width: 18, height: 18 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }