<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="assignment-rule-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <vs-popup class="main-popup" v-if="ruleCondition" :title="$t('vue.ruleCondition')" :active.sync="popupCondition">
      <rule-condition
        :condition="ruleCondition"
        :rule-condition-id="ruleConditionId"
        :operation-type="operationType"
        :condition-type="conditionType"
        @save-condition="pushMainCondition"
        @save-inner-condition="pushInnerCondition"
        @update-condition="updateCondition"
      ></rule-condition>
    </vs-popup>
    <scroll-view class="scroll-area">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ id ? $t('vue.editAssignmentRule').toUpperCase() : $t('vue.addANewAssignmentRule').toUpperCase() }}</h4>
        <div style="display: flex; flex-direction: row">
          <vs-button
            style="padding: 1rem !important"
            color="primary"
            :disabled="!validateForm"
            @click.stop="saveAssignmentRule()"
            size="large"
            radius
            type="flat"
          >
            <vs-icon icon-pack="material-icons" icon="save" size="35px" color="primary"></vs-icon>
          </vs-button>
          <vs-button style="padding: 1rem" color="dark" @click.stop="isSidebarActiveLocal = false" size="large" radius type="flat">
            <vs-icon icon-pack="material-icons" icon="close" size="35px" color="#b2b2b2"></vs-icon>
          </vs-button>
        </div>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <vx-card>
        <div class="header-text p-2">{{ $t('vue.nameOfRule') }}</div>
        <vs-input
          :data-vv-as="$t('vue.nameOfRule')"
          name="rule_name"
          class="w-full mb-2"
          :label-placeholder="$t('vue.rulePlaceholder')"
          v-model="ruleName"
          v-validate="'required'"
        />
        <span class="text-danger text-sm">{{ errors.first('rule_name') }}</span>

        <div class="pa-rules">
          <scroll-view class="scroll-area">
            <div class="pa-rule-conditons">
              <strong> {{ $t('vue.ruleConditions') }} </strong>
              <span class="rule-if-then-string rule-if"> {{ $t('vue.if').toUpperCase() }} </span>
              <div class="rule-div">
                <div class="rule-filters">
                  <div class="title">{{ $t('vue.filter') }}</div>
                  <div class="row" style="display: flex; flex-direction: row; justify-items: flex-end; align-items: center">
                    <div class="mr-2">{{ $t('vue.if') }}</div>
                    <div>
                      <v-select
                        :options="conditionOptions"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="condition"
                        :reduce="(op) => op.value"
                        label="label"
                      >
                      </v-select>
                    </div>
                    <div class="ml-2">{{ condition === 'any' ? $t('vue.ofBelowConditionsApplies') : $t('vue.ofBelowConditionsApply') }}</div>
                  </div>

                  <div class="rule-segments">
                    <scroll-view class="scroll-area">
                      <div class="rule-condition" v-for="(rc, index) in rule_conditions" :key="index">
                        <div class="rule">
                          <vs-row>
                            <vs-col vs-type="flex" vs-w="12">
                              <div class="w-full" style="display: flex; flex-direction: column; justify-items: flex-start; align-items: normal; flex-shrink: 0">
                                <div
                                  class="mb-2"
                                  style="
                                    background-color: #cdcdcd;
                                    border-radius: 5px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-items: flex-end;
                                    align-items: center;
                                  "
                                  v-for="(r, i) in rc.rules"
                                  :key="i"
                                >
                                  <vs-col vs-type="flex" vs-w="9">
                                    <rule-display :rule="r" />
                                  </vs-col>
                                  <vs-col vs-type="flex" vs-w="3">
                                    <vs-button radius size="large" color="primary" type="flat" icon="edit" @click="editCondition(rc, r)"></vs-button>
                                    <vs-button radius size="large" color="danger" type="flat" icon="delete" @click="deleteCondition(rc, i, index)"></vs-button>
                                    <vs-button
                                      radius
                                      :disabled="!rc.rules || i !== rc.rules.length - 1"
                                      size="large"
                                      color="primary"
                                      type="flat"
                                      icon="add"
                                      @click="addInnerCondition(rc)"
                                    ></vs-button>
                                    <div v-if="i > 0" :class="!rc.inner_condition ? 'inner-rule-or' : 'inner-rule-and'" class="rule-match">
                                      {{ getInnerConditionType(rc.inner_condition) }}
                                    </div>
                                  </vs-col>
                                </div>

                                <div
                                  v-if="rc.rules && rc.rules.length > 1"
                                  class="condition-switch"
                                  style="display: flex; flex-direction: row; align-items: center; justify-content: center"
                                >
                                  <div class="mr-2">
                                    <label>{{ $t('vue.or') }}</label>
                                  </div>
                                  <div>
                                    <vs-switch color="#275D73" v-model="rc.inner_condition" />
                                  </div>
                                  <div class="ml-2">
                                    <label>{{ $t('vue.and') }}</label>
                                  </div>
                                </div>
                              </div>
                            </vs-col>
                          </vs-row>
                        </div>
                        <div v-if="index < rule_conditions.length - 1" class="condition-rule-div"></div>
                        <div :class="condition === 'any' ? 'rule-or' : 'rule-and'" v-if="index < rule_conditions.length - 1" class="rule-match">
                          {{ getConditionType(condition) }}
                        </div>
                        <div v-if="index < rule_conditions.length - 1" class="condition-rule-div"></div>
                      </div>
                    </scroll-view>
                  </div>

                  <div class="mt-5">
                    <vs-button color="primary" type="border" icon="add" @click="addMainCondition"></vs-button>
                    <span v-if="rule_conditions.length === 0 && dirty" class="text-danger text-sm ml-1">
                      {{ $t('vue.atleastOneConditionRequired') }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="assignment-actions">
                <vs-radio v-model="assign_type" vs-name="assign_type" vs-value="agent">{{ $t('vue.assignToAgent') }}</vs-radio>
                <vs-radio v-if="user_groups.length > 0" v-model="assign_type" class="ml-2" vs-name="assign_type" vs-value="group">
                  {{ $t('vue.assignToGroup') }}
                </vs-radio>
                <vs-radio v-model="assign_type" vs-name="assign_type" class="ml-2" vs-value="block">{{ $t('vue.block') }}</vs-radio>

                <v-select
                  v-if="assign_type === 'agent'"
                  :options="users"
                  name="assigned_to"
                  :data-vv-as="$t('vue.agent')"
                  v-validate="'required'"
                  validate-on="blur"
                  :clearable="true"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="assigned_to"
                  :reduce="(op) => op.id"
                  :placeholder="$t('vue.chooseAgent')"
                  label="name"
                  autoscroll
                >
                </v-select>

                <v-select
                  v-if="assign_type === 'group'"
                  :options="user_groups"
                  name="assigned_to"
                  :data-vv-as="$t('vue.group')"
                  v-validate="'required'"
                  validate-on="blur"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="assigned_to"
                  :reduce="(op) => op.id"
                  label="name"
                  :placeholder="$t('vue.chooseGroup')"
                  autoscroll
                >
                </v-select>
                <span v-if="errors && errors.items && errors.items.filter((x) => x.field === 'assigned_to').length > 0" class="text-danger text-sm">
                  {{ errors.items.filter((x) => x.field === 'assigned_to')[0].msg }}
                </span>
              </div>
              <div class="rule-if-then-string rule-then rule-bottom">{{ $t('vue.then').toUpperCase() }}</div>
            </div>
          </scroll-view>
        </div>
      </vx-card>
    </scroll-view>
  </vs-sidebar>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import ScrollView from '@blackbp/vue-smooth-scrollbar'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import RuleCondition from './RuleCondition.vue'
import RuleDisplay from './RuleDisplay.vue'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    id: {
      type: String
    }
  },
  components: {
    ScrollView,
    vSelect,
    RuleCondition,
    RuleDisplay
  },
  data() {
    return {
      popupCondition: false,
      conditionType: null,
      operationType: null,
      ruleCondition: null,
      ruleConditionId: null,

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      countryCodes: require('@/assets/countryCodes.json'),
      languageCodes: require('@/assets/languageCodes.json'),
      enabled: false,
      loading: false,
      ruleName: '',
      condition: 'any',
      conditionOptions: [
        { label: this.translate('vue.all'), value: 'all' },
        { label: this.translate('vue.any'), value: 'any' }
      ],
      conditionTypeOptions: [
        { label: this.translate('vue.contains'), value: 'contains' },
        { label: this.translate('vue.equals'), value: 'equals' },
        { label: this.translate('vue.notEquals'), value: 'not-equals' },
        { label: this.translate('vue.doesNotContain'), value: 'does-not-contain' },
        { label: this.translate('vue.includes'), value: 'in' },
        { label: this.translate('vue.excludes'), value: 'not-in' }
      ],

      rule_conditions: [],
      assign_type: 'agent',
      assigned_to: null,
      dirty: false
    }
  },
  watch: {
    '$i18n.locale'() {
      this.conditionOptions = [
        { label: this.translate('vue.all'), value: 'all' },
        { label: this.translate('vue.any'), value: 'any' }
      ]
      this.conditionTypeOptions = [
        { label: this.translate('vue.contains'), value: 'contains' },
        { label: this.translate('vue.equals'), value: 'equals' },
        { label: this.translate('vue.notEquals'), value: 'not-equals' },
        { label: this.translate('vue.doesNotContain'), value: 'does-not-contain' },
        { label: this.translate('vue.includes'), value: 'in' },
        { label: this.translate('vue.excludes'), value: 'not-in' }
      ]
    },
    async id() {
      const vm = this
      vm.loading = true
      if (!vm.id) {
        vm.setDefaultValues()
        vm.loading = false
      } else {
        const assignmentRule = await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(vm.id).get()
        if (assignmentRule && assignmentRule.data()) {
          const rule = assignmentRule.data()
          vm.ruleName = rule.ruleName
          vm.condition = rule.condition
          vm.enabled = rule.enabled
          vm.rule_conditions = rule.rule_conditions
          vm.assign_type = rule.assign_type
          vm.assigned_to = rule.assigned_to
          setTimeout(() => {
            vm.loading = false
          }, 100)
        } else {
          vm.loading = false
        }
      }
    },
    assign_type() {
      if (!this.loading) {
        this.assigned_to = null
        this.$validator.reset()
      }
    }
  },
  computed: {
    ...mapGetters({
      users: 'users',
      user_groups: 'user_groups',
      assignment_rules: 'assignment_rules',
      activeUserInfo: 'activeUser',
      company: 'company'
    }),
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('routing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeAssignmentRuleSidebar')
        }
      }
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
    countryCodeOptions() {
      return this.countryCodes.map((x) => {
        return {
          label: x.countryName,
          value: x.code
        }
      })
    },
    validateForm() {
      return !this.errors.any()
    }
  },
  methods: {
    async saveAssignmentRule() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        const vm = this
        vm.dirty = true
        await vm.$validator.validate()

        if (!vm.validateForm) {
          return false
        }
        if (vm.rule_conditions.length === 0) {
          return false
        }
        vm.dirty = false
        const rule = {}
        rule.ruleName = vm.ruleName
        rule.condition = vm.condition
        rule.enabled = vm.enabled
        rule.rule_conditions = vm.rule_conditions
        rule.assign_type = vm.assign_type
        rule.assigned_to = vm.assigned_to
        if (!vm.id) {
          rule.position = vm.assignment_rules.length
          await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').add(rule)
        } else {
          await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(vm.id).set(rule, { merge: true })
        }
        vm.setDefaultValues()
        vm.isSidebarActiveLocal = false
        vm.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      }
    },
    setDefaultValues() {
      const vm = this
      vm.ruleName = ''
      vm.condition = 'any'
      vm.enabled = false
      vm.rule_conditions = []
      vm.assign_type = 'agent'
      vm.assigned_to = null
      vm.$validator.reset()
    },
    deleteCondition(rc, i, index) {
      rc.rules.splice(i, 1)
      if (rc.rules.length === 0) {
        this.rule_conditions.splice(index, 1)
      }
    },
    ruleConditionChanged(rc) {
      rc.condition_type = null
      rc.value = null
    },
    getInnerConditionType(condition) {
      return condition ? this.translate('vue.and') : this.translate('vue.or')
    },
    getConditionType(condition) {
      return condition === 'all' ? this.translate('vue.and') : this.translate('vue.or')
    },
    getConditionTypeOptions(rule) {
      if (rule === 'country' || rule === 'language') {
        return this.conditionTypeOptions.filter((x) => x.value === 'equals' || x.value === 'not-equals')
      }
      if (rule === 'dialogId') {
        return this.conditionTypeOptions.filter((x) => x.value === 'in' || x.value === 'not-in')
      }

      return this.conditionTypeOptions.filter((x) => x.value !== 'in' && x.value !== 'not-in')
    },
    translate(code) {
      return this.$i18n.t(code)
    },
    async pushMainCondition(condition) {
      this.rule_conditions.push({
        rules: [condition],
        inner_condition: false,
        id: btoa(new Date().toISOString() + Math.random().toString(36).substring(2, 15))
      })
      this.$validator.reset()
      this.popupCondition = false
    },

    addMainCondition() {
      this.conditionType = 'main'
      this.operationType = 'add'
      const condition = {
        id: btoa(new Date().toISOString() + Math.random().toString(36).substring(2, 15)),
        field: null,
        condition_type: null,
        value: null
      }
      this.ruleCondition = condition
      this.popupCondition = true
    },
    editCondition(ruleCondition, rule) {
      this.conditionType = 'inner'
      this.operationType = 'edit'
      const condition = {
        id: rule.id ? rule.id : btoa(new Date().toISOString() + Math.random().toString(36).substring(2, 15)),
        field: rule.field,
        condition_type: rule.condition_type,
        value: rule.value
      }
      this.ruleCondition = condition
      this.ruleConditionId = ruleCondition.id
      this.popupCondition = true
    },
    updateCondition(res) {
      const ruleCondition = this.rule_conditions.find((x) => x.id === res.id)
      if (ruleCondition && ruleCondition.rules.find((x) => x.id === res.condition.id)) {
        const condition = ruleCondition.rules.find((x) => x.id === res.condition.id)
        condition.field = res.condition.field
        condition.value = res.condition.value
        condition.condition_type = res.condition.condition_type
      }
      this.popupCondition = false
    },
    async pushInnerCondition(res) {
      this.rule_conditions.find((x) => x.id === res.id).rules.push(res.condition)
      this.popupCondition = false
    },
    addInnerCondition(rc) {
      this.conditionType = 'inner'
      this.operationType = 'add'
      this.ruleConditionId = rc.id
      const condition = {
        id: btoa(new Date().toISOString() + Math.random().toString(36).substring(2, 15)),
        field: null,
        value: null,
        condition_type: null
      }
      this.ruleCondition = condition
      this.popupCondition = true
    }
  }
}
</script>

<style lang="scss" scoped>
.header-text {
  font-weight: bold;
}
.assignment-rule-sidebar {
  .pa-rules {
    height: calc(100vh);
    padding: 20px 15px;
    .scroll-area {
      width: 100%;
      height: calc(105vh);
    }
  }

  .pa-rules .pa-rule-conditons {
    margin-top: 30px;
    .rule-condition {
      .rule {
        background-color: rgba(215, 215, 215, 0.2);
        border-radius: 12px;
        padding: 10px;
        .condition-switch {
          border-radius: 0 0 5px 5px;
          border-top: 1px solid rgba(9, 30, 66, 0.12);
          display: block;
          font-size: 14px;
          padding: 10px;
          text-align: center;
          margin: 15px -10px -15px -10px;
        }
      }
      .condition-rule-div {
        border-left: solid 1px rgba(18, 89, 141, 0.12);
        margin-left: 60px;
        max-height: calc(100vh - 10px);
        min-height: 10px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .rule-match {
        margin-left: 35px;
        padding: 5px;
        color: black;
        font-weight: 600;
        border-radius: 5px;
        width: fit-content;
        min-width: 50px;
        text-align: center;
        vertical-align: middle;
        margin: auto;
      }
      .rule-and {
        background-color: #3b86f7;
      }
      .rule-or {
        background-color: #b2b2b2;
      }
      .inner-rule-and {
        background-color: #f0a941;
      }
      .inner-rule-or {
        background-color: #b2b2b2;
      }
    }
  }
  .pa-rules .rule-if {
    margin-top: 10px;
    z-index: 10;
  }
  .pa-rules .rule-if-then-string {
    background-color: #12598d;
    border-radius: 3px;
    color: #fff;
    display: block;
    font-size: 14px;
    padding: 8px 15px 6px;
    position: relative;
    text-align: center;
    width: 120px;
  }

  .pa-rules .rule-bottom {
    margin-top: -185px !important;
  }

  .pa-rules .rule-div {
    border-left: solid 1px rgba(18, 89, 141, 0.12);
    margin-left: 60px;
    margin-top: -24px;
    max-height: calc(100vh - 700px);
    min-height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 12px;
    padding-left: 70px;
    .rule-match-select {
      max-width: fit-content;
      min-width: 80px;
    }
  }

  .rule-filters .rule-segments {
    border-left: solid 1px rgba(18, 89, 141, 0.12);
    margin-left: 18px;
    margin-top: 10px;
    max-height: calc(100vh - 650px);
    min-height: 450px;
    height: 450px;
    padding-bottom: 12px;
    padding-left: 20px;
    .scroll-area {
      width: 100%;
      height: 450px;
    }
  }
  .pa-rules .rule-then {
    margin-top: 0;
  }
  .rule-filters .title {
    color: rgba(9, 30, 66, 0.87);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .vs__search,
  .vs__selected,
  .vs__search:focus {
    line-height: 1.8 !important;
  }
  .assignment-actions {
    margin-left: 128px;
    margin-top: -50px;
    max-height: calc(100vh - 550px);
    min-height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 12px;
    padding-left: 20px;
  }

  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .v-select {
    min-width: 200px;
    padding: 5px;
    .vs__dropdown-toggle {
      padding: 4px !important;
    }
    .vs__dropdown-menu {
      max-width: 200px;
      text-transform: lowercase;
      font-variant: small-caps;
      max-height: 150px !important;
      position: initial;
    }
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.assignment-rule-sidebar {
  .vs-sidebar {
    max-width: 1200px !important;
  }
}

.main-popup .vs-popup {
  width: 450px !important;
  height: 400px !important;
}
</style>
