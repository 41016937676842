<template>
  <div class="upgrade-message-webhooks">
    <div class="flex flex-column justify-center items-center mat-100">
      <div class="flex flex-row justify-center items-center">
        <div class="lock mr-10">
          <lock-icon />
        </div>
        <div class="flex flex-column justify-center items-center">
          <div class="plan-info" v-html="$t('upgrade-messages.webhooks')"></div>
          <div class="upgrade-button mt-4">
            <router-link to="/payment">
              <vs-button color="primary" type="filled">
                <div class="flex flex-row justify-center items center">
                  <div class="upgrade-icon">
                    <upgrade-icon />
                  </div>
                  <div>
                    {{ $t('upgrade-messages.upgrade-your-plan') }}
                  </div>
                </div>
              </vs-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LockIcon from '@/components/icons/LockIcon'
import UpgradeIcon from '@/components/icons/UpgradeIcon'
export default {
  components: {
    LockIcon,
    UpgradeIcon
  }
}
</script>
<style lang="scss">
.upgrade-message-webhooks {
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  min-height: 300px;
  .mat-100 {
    margin-top: 100px;
  }
  .plan-info {
    max-width: 730px;
  }
  .upgrade-icon {
    height: 18px;
    margin-right: 10px;
  }
  .mat-100 {
    margin-top: 100px;
  }
}
</style>
