<template>
  <svg :width="width" :height="height" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0014 14.332V18.7768C21.0014 19.3662 20.7673 19.9315 20.3505 20.3483C19.9337 20.765 19.3685 20.9992 18.7791 20.9992H3.22238C2.63297 20.9992 2.0677 20.765 1.65092 20.3483C1.23414 19.9315 1 19.3662 1 18.7768V14.332"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M5.44458 8.77734L11.0005 14.3333L16.5565 8.77734" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.0007 14.3343V1" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3B86F7',
      required: false
    },
    width: {
      type: Number,
      default: 22,
      required: false
    },
    height: {
      type: Number,
      default: 22,
      required: false
    }
  }
}
</script>
