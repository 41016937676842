var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "add-new-data-sidebar items-no-padding",
      attrs: {
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
        spacer: "",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [
            _vm._v(
              _vm._s(
                _vm.id
                  ? _vm.$t("vue.editUserGroup").toUpperCase()
                  : _vm.$t("vue.addANewUserGroup").toUpperCase()
              )
            ),
          ]),
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "row" } },
            [
              _c("vs-button", {
                attrs: {
                  color: "primary",
                  disabled: !_vm.validateForm,
                  size: "large",
                  radius: "",
                  type: "flat",
                  icon: "save",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.saveUserGroup()
                  },
                },
              }),
              _c("vs-button", {
                attrs: {
                  color: "dark",
                  size: "large",
                  radius: "",
                  type: "flat",
                  icon: "close",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.isSidebarActiveLocal = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c(
        _vm.scrollbarTag,
        {
          key: _vm.$vs.rtl,
          tag: "component",
          staticClass: "scroll-area--data-list-add-new",
          attrs: { settings: _vm.settings },
        },
        [
          _c("vx-card", [
            _c(
              "div",
              { staticClass: "users-lists" },
              [
                _c("div", { staticClass: "header-text p-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.nameOfGroup"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full mb-2",
                  attrs: {
                    "data-vv-as": _vm.$t("vue.nameOfGroup"),
                    name: "group_name",
                    "label-placeholder": _vm.$t("vue.nameOfGroupDescription"),
                  },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("group_name"))),
                ]),
                _vm.remainingUsers.length > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "flex-start:align-items:center",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "vs-select",
                              {
                                staticClass: "w-full mr-2 user-select",
                                attrs: { autocomplete: "", color: "grey" },
                                model: {
                                  value: _vm.selectedUser,
                                  callback: function ($$v) {
                                    _vm.selectedUser = $$v
                                  },
                                  expression: "selectedUser",
                                },
                              },
                              _vm._l(_vm.remainingUsers, function (user) {
                                return _c("vs-select-item", {
                                  key: user.id,
                                  attrs: { value: user.id, text: user.name },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "ml-2" },
                            [
                              _c("vs-button", {
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.selectedUser === null,
                                  size: "large",
                                  radius: "",
                                  type: "flat",
                                  icon: "add",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addUserToGroup()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "scroll-view",
                  {
                    staticClass: "mt-10",
                    staticStyle: { "max-height": "200px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "box" },
                      _vm._l(_vm.usergroup, function (user, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "mb-4 ml-2 mr-2" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  "justify-content": "flex-end",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("div", { staticClass: "agent-name" }, [
                                  _vm._v(_vm._s(_vm.getUserName(user.id))),
                                ]),
                                _c("Avatar", {
                                  staticClass: "ml-2",
                                  attrs: {
                                    size: "35px",
                                    photoURL: _vm.getUserImage(user.id),
                                    name: _vm.getUserName(user.id),
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "ml-5" },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        attrs: {
                                          text: _vm.$t("vue.delete"),
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c("vs-button", {
                                          attrs: {
                                            color: "danger",
                                            type: "border",
                                            size: "medium",
                                            "icon-pack": "feather",
                                            icon: "icon-trash",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.deleteUser(user.id)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "wigets" },
              [
                _c("div", { staticClass: "header-text p-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.assignWidget"))),
                ]),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.$t("vue.assignWidgetDescription"))),
                ]),
                _vm.remainingWidgets.length > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "flex-start:align-items:center",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "vs-select",
                              {
                                staticClass: "w-full mr-2 user-select",
                                attrs: { autocomplete: "", color: "grey" },
                                model: {
                                  value: _vm.selectedWidgetUG,
                                  callback: function ($$v) {
                                    _vm.selectedWidgetUG = $$v
                                  },
                                  expression: "selectedWidgetUG",
                                },
                              },
                              _vm._l(_vm.remainingWidgets, function (widget) {
                                return _c("vs-select-item", {
                                  key: widget.id,
                                  attrs: {
                                    value: widget.id,
                                    text: widget.name,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "ml-2" },
                            [
                              _c("vs-button", {
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.selectedWidgetUG === null,
                                  size: "large",
                                  radius: "",
                                  type: "flat",
                                  icon: "add",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addWidgetToGroup()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "scroll-view",
                  {
                    staticClass: "mt-10",
                    staticStyle: { "max-height": "200px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "box" },
                      _vm._l(_vm.widgets, function (widget, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "mb-4 ml-2 mr-2" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  "justify-content": "flex-start",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("WidgetIcon"),
                                _c("div", { staticClass: "ml-5 widget-name" }, [
                                  _vm._v(_vm._s(_vm.getWidgetName(widget.id))),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "ml-5" },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        attrs: {
                                          text: _vm.$t("vue.delete"),
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c("vs-button", {
                                          attrs: {
                                            color: "danger",
                                            type: "border",
                                            size: "medium",
                                            "icon-pack": "feather",
                                            icon: "icon-trash",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.deleteWidget(widget.id)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }