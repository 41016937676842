<template>
  <form v-if="condition" class="rule-condition" :key="formKey">
    <vs-card>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <v-select class="mb-4 md:mb-0" :placeholder="$t('vue.chooseField')" :options="ruleConditionOptions" name="main-rule-field" :data-vv-as="$t('vue.condition')" v-validate="'required'" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="condition.field" :reduce="(op) => op.value" label="label" key="main-rule">
          </v-select>
          <span v-if="isRuleFieldValidated" class="text-danger text-sm ml-1">
            {{ errors.items.filter((x) => x.field === 'main-rule-field')[0].msg }}
          </span>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <v-select name="main-rule-type" :placeholder="$t('vue.chooseOperator')" :data-vv-as="$t('vue.operator')" v-validate="'required'" :options="getOperators(condition.field)" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="condition.condition_type" :reduce="(op) => op.value" label="label" key="main-rule-type">
          </v-select>
          <span v-if="isRuleTypeValidated" class="text-danger text-sm ml-1">
            {{ errors.items.filter((x) => x.field === 'main-rule-type')[0].msg }}
          </span>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <template v-if="condition.field && condition.field === 'country'">
            <v-select :multiple="condition.condition_type === 'in' || condition.condition_type === 'not-in'" :placeholder="$t('vue.chooseCountry')" :options="countryCodes" name="main-rule-country" :data-vv-as="$t('vue.country')" v-validate="'required'" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="condition.value" :reduce="(op) => op.code" label="countryName" autoscroll key="main-rule-country">
            </v-select>
            <span v-if="isCountryValidated" class="text-danger text-sm ml-1">
              {{ errors.items.filter((x) => x.field === 'main-rule-country')[0].msg }}
            </span>
          </template>
          <template v-else-if="condition.field && condition.field === 'city'">
            <vs-chips
              v-if="condition.condition_type === 'in' || condition.condition_type === 'not-in'"
              key="main-rule-city"
              color="rgb(145, 32, 159)"
              :placeholder="$t('vue.enterCities')"
              v-model="cities"
              :data-vv-as="$t('vue.city')"
              v-validate="'required|min:1'"
              name="main-rule-city"
              class="mt-1 mb-2 w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg"
            >
            </vs-chips>

            <vs-chip :key="`${city}-${index}`" @click="remove(city)" v-for="(city, index) in cities" closable>
              {{ city }}
            </vs-chip>

            <div v-if="isCityValidated" class="text-danger text-sm ml-1">
              {{ errors.items.filter((x) => x.field === 'main-rule-city')[0].msg }}
            </div>
          </template>

          <template v-else-if="condition.field && condition.field === 'locale'">
            <v-select :multiple="condition.condition_type === 'in' || condition.condition_type === 'not-in'" :placeholder="$t('vue.chooseLanguage')" :options="languageCodes" name="main-rule-language" :data-vv-as="$t('vue.language')" v-validate="'required'" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="condition.value" :reduce="(op) => op.code" label="nativeName" autoscroll key="main-rule-language">
            </v-select>

            <span v-if="isLanguageValidated" class="text-danger text-sm ml-1">
              {{ errors.items.filter((x) => x.field === 'main-rule-language')[0].msg }}
            </span>
          </template>
          <template v-else-if="condition.field && condition.field === 'dialogId'">
            <v-select :multiple="condition.condition_type === 'in' || condition.condition_type === 'not-in'" :placeholder="$t('vue.chooseDialog')" :options="dialogList" name="main-rule-dialog" :data-vv-as="$t('vue.widget')" v-validate="'required'" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="condition.value" :reduce="(op) => op.id" label="dialogName" autoscroll key="main-rule-dialog">
            </v-select>
            <span v-if="isDialogValidated" class="text-danger text-sm ml-1">
              {{ errors.items.filter((x) => x.field === 'main-rule-dialog')[0].msg }}
            </span>
          </template>
          <template v-else>
            <vs-input class="w-full mr-2" :key="formKey" :data-vv-as="$t('vue.field')" v-validate="'required'" name="main-rule-text" v-model="condition.value" />
            <span v-if="isTextValidated" class="text-danger text-sm ml-1">
              {{ errors.items.filter((x) => x.field === 'main-rule-text')[0].msg }}
            </span>
          </template>
        </div>
      </div>
      <div slot="footer"></div>
    </vs-card>
    <vs-row vs-justify="flex-start">
      <vs-button v-if="operationType === 'add'" class="mr-2" color="primary" icon="save" @click="saveCondition">{{ $t('vue.save') }}</vs-button>
      <vs-button v-if="operationType === 'edit'" class="mr-2" color="primary" icon="save" @click="updateCondition">{{ $t('vue.save') }}</vs-button>
    </vs-row>
  </form>
</template>
<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
export default {
  name: 'RuleCondition',
  props: {
    condition: {
      type: Object,
      required: true
    },
    conditionType: {
      type: String,
      required: true
    },
    operationType: {
      type: String,
      required: true
    },
    ruleConditionId: {
      type: String,
      required: false
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
      popupcondition: false,
      formKey: Math.random().toString(36).substring(2, 15),
      countryCodes: require('@/assets/countryCodes.json'),
      languageCodes: require('@/assets/languageCodes.json'),
      ruleConditionOptions: [
        { label: this.translate('vue.country'), value: 'country', type: 'select' },
        { label: this.translate('vue.city'), value: 'city', type: 'select' },
        { label: this.translate('vue.url'), value: 'location', type: 'text' },
        { label: this.translate('vue.language'), value: 'locale', type: 'select' },
        { label: this.translate('vue.widget'), value: 'dialogId', type: 'select' }
      ],
      conditionTypeOptions: [
        { label: this.translate('vue.contains'), value: 'contains' },
        { label: this.translate('vue.equals'), value: 'equals' },
        { label: this.translate('vue.notEquals'), value: 'not-equals' },
        { label: this.translate('vue.doesNotContain'), value: 'does-not-contain' },
        { label: this.translate('vue.includes'), value: 'in' },
        { label: this.translate('vue.excludes'), value: 'not-in' }
      ],
      cities: []
    }
  },
  computed: {
    ...mapGetters({
      dialogs: 'dialogs'
    }),
    dialogList() {
      return this.dialogs.map((x) => {
        return {
          id: x.id,
          dialogName: x.name
        }
      })
    },
    isRuleFieldValidated() {
      return this.errors && this.errors.items && this.errors.items.filter((x) => x.field === 'main-rule-field').length > 0
    },
    isRuleTypeValidated() {
      return this.errors && this.errors.items && this.errors.items.filter((x) => x.field === 'main-rule-type').length > 0
    },
    isCountryValidated() {
      return this.errors && this.errors.items && this.errors.items.filter((x) => x.field === 'main-rule-country').length > 0
    },
    isCityValidated() {
      return this.errors && this.errors.items && this.errors.items.filter((x) => x.field === 'main-rule-city').length > 0
    },
    isLanguageValidated() {
      return this.errors && this.errors.items && this.errors.items.filter((x) => x.field === 'main-rule-language').length > 0
    },
    isDialogValidated() {
      return this.errors && this.errors.items && this.errors.items.filter((x) => x.field === 'main-rule-dialog').length > 0
    },
    isTextValidated() {
      return this.errors && this.errors.items && this.errors.items.filter((x) => x.field === 'main-rule-text').length > 0
    }
  },
  created() {
    if (this.condition.field === 'city') {
      this.cities = this.condition.value || []
    }
  },
  watch: {
    '$i18n.locale'() {
      this.ruleConditionOptions = [
        { label: this.translate('vue.country'), value: 'country', type: 'select' },
        { label: this.translate('vue.city'), value: 'city', type: 'select' },
        { label: this.translate('vue.url'), value: 'location', type: 'text' },
        { label: this.translate('vue.language'), value: 'locale', type: 'select' },
        { label: this.translate('vue.widget'), value: 'dialogId', type: 'select' }
      ]
      this.conditionTypeOptions = [
        { label: this.translate('vue.contains'), value: 'contains' },
        { label: this.translate('vue.equals'), value: 'equals' },
        { label: this.translate('vue.notEquals'), value: 'not-equals' },
        { label: this.translate('vue.doesNotContain'), value: 'does-not-contain' },
        { label: this.translate('vue.includes'), value: 'in' },
        { label: this.translate('vue.excludes'), value: 'not-in' }
      ]
    },
    'condition.field'() {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach((field) => field.reset())
        this.$validator.fields.items.forEach((field) => this.errors.remove(field))
        this.$validator.resume()
      })
      this.formKey = Math.random().toString(36).substring(2, 15)
      this.condition.value = null
      this.cities = []

      if (this.condition.field === 'city') {
        this.condition.condition_type = 'in'
      }
    },
    'condition.value'() {
      if (this.condition.field === 'city') {
        this.cities = this.condition.value || []
      }
    },
    'cities'() {
      if (this.condition && this.condition.field && this.condition.field === 'city') {
        this.condition.value = this.cities
      }
    }
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    getOperators(rule) {
      if (rule === 'country' || rule === 'locale' || rule === 'dialogId') {
        return this.conditionTypeOptions.filter((x) => x.value === 'equals' || x.value === 'not-equals' || x.value === 'in' || x.value === 'not-in')
      } else if (rule === 'city') {
        return this.conditionTypeOptions.filter((x) => x.value === 'in' || x.value === 'not-in')
      }
      return this.conditionTypeOptions.filter((x) => x.value !== 'in' && x.value !== 'not-in')
    },
    async saveCondition() {
      const validate = await this.$validator.validate()
      if (!validate) return

      this.$validator.reset()
      if (this.conditionType === 'main') {
        this.$emit('save-condition', this.condition)
      } else {
        this.$emit('save-inner-condition', {
          condition: this.condition,
          id: this.ruleConditionId
        })
      }
    },
    async updateCondition() {
      const validate = await this.$validator.validate()
      if (!validate) return

      this.$validator.reset()

      this.$emit('update-condition', {
        condition: this.condition,
        id: this.ruleConditionId
      })
    },

    remove(item) {
      const cities = [...this.cities]

      cities.splice(cities.indexOf(item), 1)

      this.cities = cities
    },
  }
}
</script>

<style lang="scss">
.rule-condition {
  .vx-col span {
    font-size: 14px !important;
  }
  .vs__dropdown-menu {
    font-size: 14px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100px;
  }
  .vs__search {
    font-size: 14px !important;
  }
}
</style>
