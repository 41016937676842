var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "add-new-data-sidebar items-no-padding",
      attrs: {
        spacer: "",
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _vm.isCallCenter
            ? _c("h4", [
                _vm._v(
                  _vm._s(_vm.$t("info.addSettingsForTheCompany").toUpperCase())
                ),
              ])
            : _c("h4", [
                _vm._v(_vm._s(_vm.$t("info.callCenter").toUpperCase())),
              ]),
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "row" } },
            [
              _vm.isCallCenter
                ? _c("vs-button", {
                    attrs: {
                      radius: "",
                      color: "primary",
                      size: "large",
                      type: "flat",
                      icon: "save",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.saveSettings.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "dark",
                  size: "large",
                  type: "flat",
                  icon: "close",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.isSidebarActiveLocal = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _vm.isCallCenter
        ? _c(
            "connect-modal",
            {
              attrs: {
                bkcolor: "#F7F7F7",
                show: _vm.timeRangeDialog,
                width: "460px",
                height: "300px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-row visitor-list-delete-all" },
                [
                  _c("div", [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("inputs.selectTimeRange"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-left": "auto", cursor: "pointer" },
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          _vm.timeRangeDialog = false
                        },
                      },
                    },
                    [
                      _c("close-circle-icon", {
                        staticClass: "pa-bk-secondary",
                        attrs: { "show-circle": false, width: 14, height: 14 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex flex-wrap justify-center",
                  staticStyle: { height: "200px" },
                },
                [
                  _c(
                    "vs-row",
                    { attrs: { w: "12" } },
                    [
                      _c("vs-col", { attrs: { w: "12" } }, [
                        _c("label", { staticClass: "text-sm" }, [
                          _vm._v(_vm._s(_vm.$t("inputs.start"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("vue-timepicker", {
                    staticClass: "w-full mb-base",
                    attrs: {
                      "close-on-complete": "",
                      format: "HH:mm",
                      "minute-interval": _vm.minuteInterval,
                      "fixed-dropdown-button": "",
                      "drop-direction": "down",
                    },
                    on: { change: _vm.timeChange },
                    model: {
                      value: _vm.start,
                      callback: function ($$v) {
                        _vm.start = $$v
                      },
                      expression: "start",
                    },
                  }),
                  _c(
                    "vs-row",
                    { attrs: { w: "12" } },
                    [
                      _c("vs-col", { attrs: { w: "12" } }, [
                        _c("label", { staticClass: "text-sm" }, [
                          _vm._v(_vm._s(_vm.$t("inputs.end"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("vue-timepicker", {
                    staticClass: "w-full mb-base",
                    attrs: {
                      "close-on-complete": "",
                      "drop-direction": "up",
                      "fixed-dropdown-button": "",
                      "hide-disabled-items": "",
                      format: "HH:mm",
                      "hour-range": _vm.hourRange,
                      "minute-range": _vm.minuteRange,
                      "minute-interval": _vm.minuteInterval,
                    },
                    on: { change: _vm.timeChange, error: _vm.errorHanlder },
                    model: {
                      value: _vm.end,
                      callback: function ($$v) {
                        _vm.end = $$v
                      },
                      expression: "end",
                    },
                  }),
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full mb-base",
                      attrs: {
                        color: "primary",
                        type: "filled",
                        icon: "add",
                        disabled: _vm.hasError,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addUpdateTimeRange.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("vue.addTimeRange")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "vx-card",
        { staticClass: "card-sidebar" },
        [
          !_vm.isCallCenter
            ? _c("p", [
                _vm._v(_vm._s(_vm.$t("vue.waitingTimeInSeconds")) + ":"),
              ])
            : _vm._e(),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|greaterThanEqualsZero",
                expression: "'required|greaterThanEqualsZero'",
              },
            ],
            staticClass: "w-full mt-4 mb-2",
            class: {
              "mt-6": _vm.isCallCenter,
            },
            attrs: {
              name: "waitingTime",
              "label-placeholder": _vm.isCallCenter
                ? _vm.$t("vue.waitingTimeInSeconds")
                : "",
              "data-vv-as": _vm.isCallCenter
                ? _vm.$t("vue.waitingTimeInSeconds")
                : "",
              disabled: !_vm.isCallCenter,
              type: "Number",
            },
            model: {
              value: _vm.waitingTime,
              callback: function ($$v) {
                _vm.waitingTime = $$v
              },
              expression: "waitingTime",
            },
          }),
          _c("span", { staticClass: "text-danger text-sm" }, [
            _vm._v(_vm._s(_vm.errors.first("waitingTime"))),
          ]),
          _vm.isCallCenter
            ? [
                _c(
                  "div",
                  { staticClass: "mt-6" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-4 mb-2",
                      attrs: {
                        name: "statusName",
                        "label-placeholder": "Status name",
                      },
                      model: {
                        value: _vm.statusName,
                        callback: function ($$v) {
                          _vm.statusName = $$v
                        },
                        expression: "statusName",
                      },
                    }),
                    _c("p", { staticClass: "mt-5" }, [
                      _vm._v(_vm._s(_vm.$t("vue.statusType")) + ":"),
                    ]),
                    _c("multiselect", {
                      attrs: {
                        label: "type",
                        "track-by": "type",
                        options: _vm.statusColors,
                        multiple: false,
                        "close-on-select": false,
                        "clear-on-select": false,
                        "preserve-search": true,
                        placeholder: _vm.$t("vue.pickOne"),
                        selectLabel: _vm.$t("vue.pressEnterToSelect"),
                        selectedLabel: _vm.$t("vue.selectedLabel"),
                        deselectLabel: _vm.$t("vue.pressEnterToRemove"),
                        "preselect-first": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "singleLabel",
                            fn: function (ref) {
                              var option = ref.option
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(option.type) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1812309232
                      ),
                      model: {
                        value: _vm.statusType,
                        callback: function ($$v) {
                          _vm.statusType = $$v
                        },
                        expression: "statusType",
                      },
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mt-2 btn-main btn-add",
                        attrs: {
                          size: "medium",
                          color: "primary",
                          disabled: !_vm.statusName || !_vm.statusType,
                        },
                        on: { click: _vm.saveStatus },
                      },
                      [
                        _c("span", { staticClass: "btn-add__label" }, [
                          _vm._v(_vm._s(_vm.$t("inputs.add"))),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "mt-5" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("vue.statuses")) + ":")]),
                    ]),
                    _vm.statusList.length
                      ? _c(
                          "div",
                          { staticClass: "flex flex-wrap" },
                          _vm._l(_vm.statusList, function (status) {
                            return _c(
                              "vs-chip",
                              {
                                key: status.name,
                                staticClass: "mt-4 mr-2 float-none",
                                attrs: {
                                  closable: "",
                                  color: _vm.getStatusColor(status.type),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeStatus(status.name)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(status.name) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _c("p", { staticClass: "mt-4" }, [
                          _vm._v("You have not created any status"),
                        ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "ma-0 pt-0 pb-1 checkbox-main",
                  attrs: { "hide-details": "", disabled: !_vm.isCallCenter },
                  on: { change: _vm.acceptAgentsPrioritization },
                  model: {
                    value: _vm.isAgentsPrioritization,
                    callback: function ($$v) {
                      _vm.isAgentsPrioritization = $$v
                    },
                    expression: "isAgentsPrioritization",
                  },
                },
                [_vm._v("\n        Agents prioritization\n      ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("vue.agents")) + ":")]),
              _vm.isCallCenter
                ? [
                    _c("multiselect", {
                      attrs: {
                        label: "name",
                        "track-by": "name",
                        options: _vm.modifiedUsers,
                        multiple: true,
                        "close-on-select": false,
                        "clear-on-select": false,
                        "preserve-search": true,
                        placeholder: _vm.$t("vue.pickSome"),
                        selectLabel: _vm.$t("vue.pressEnterToSelect"),
                        selectedLabel: _vm.$t("vue.selectedLabel"),
                        deselectLabel: _vm.$t("vue.pressEnterToRemove"),
                        "preselect-first": false,
                      },
                      on: { input: _vm.updateSelectedAgents },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function (ref) {
                              var values = ref.values
                              var isOpen = ref.isOpen
                              return [
                                values.length && !isOpen
                                  ? _c(
                                      "span",
                                      { staticClass: "multiselect__single" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(values.length) +
                                            " " +
                                            _vm._s(
                                              values.length > 1
                                                ? _vm.$t("vue.usersSelected")
                                                : _vm.$t("vue.userSelected")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        353606120
                      ),
                      model: {
                        value: _vm.selectedAgents,
                        callback: function ($$v) {
                          _vm.selectedAgents = $$v
                        },
                        expression: "selectedAgents",
                      },
                    }),
                    _vm.isAgentsPrioritization &&
                    _vm.selectedAgents &&
                    _vm.selectedAgents.length > 0
                      ? _c("TableSelectedAgents", {
                          staticClass: "users-list",
                          attrs: { selectedAgents: _vm.sortedSelectedAgents },
                        })
                      : !_vm.isAgentsPrioritization
                      ? _c(
                          "div",
                          { staticClass: "users-list" },
                          _vm._l(_vm.selectedAgents, function (user) {
                            return _c(
                              "div",
                              { key: user.id, staticClass: "users-list__item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "users-list__item__main-info",
                                  },
                                  [
                                    _c("Avatar", {
                                      staticClass: "users-list__item__avatar",
                                      attrs: {
                                        size: "45px",
                                        photoURL: user.photoURL || user.image,
                                        name: user.displayName || user.name,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "users-list__item__name" },
                                      [
                                        _vm._v(
                                          _vm._s(user.displayName || user.name)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                : _c(
                    "div",
                    { staticClass: "users-list" },
                    _vm._l(_vm.sortedSelectedAgents, function (user) {
                      return _c(
                        "div",
                        { key: user.id, staticClass: "users-list__item" },
                        [
                          _c(
                            "div",
                            { staticClass: "users-list__item__main-info" },
                            [
                              _c("Avatar", {
                                staticClass: "users-list__item__avatar",
                                attrs: {
                                  size: "45px",
                                  photoURL: user.photoURL || user.image,
                                  name: user.displayName || user.name,
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "users-list__item__name" },
                                [_vm._v(_vm._s(user.displayName || user.name))]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
            ],
            2
          ),
          _c("div", { staticClass: "mt-5" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("vue.acceptanceTimes")) + ":")]),
            _vm.isCallCenter
              ? _c(
                  "div",
                  { staticClass: "mt-2 flex flex-wrap items-center mb-base" },
                  _vm._l(_vm.availableTimeSlots, function (slot, d) {
                    return _c("vs-row", { key: d }, [
                      _c("div", { staticClass: "vx-row slot-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "ma-0 pt-0 pb-1 checkbox-main",
                                attrs: { "hide-details": "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.slotUpdated(slot, d)
                                  },
                                },
                                model: {
                                  value: slot.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(slot, "enabled", $$v)
                                  },
                                  expression: "slot.enabled",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.modifyDayNameToLong(slot.day))
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            slot.enabled
                              ? _c(
                                  "div",
                                  { staticClass: "slot-details" },
                                  [
                                    _vm._l(slot.times, function (time, t) {
                                      return _c(
                                        "div",
                                        { key: t, staticClass: "slot-item" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "slot-item__time" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.format12HrTime(time.start)
                                                ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm.format12HrTime(time.end)
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "slot-item__actions",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "flex border-none cursor-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.editTime(
                                                        d,
                                                        t,
                                                        time
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("PenEditIcon")],
                                                1
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "flex border-none cursor-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteTime(
                                                        t,
                                                        slot
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("DeleteIcon")],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "btn-main btn-add",
                                        attrs: {
                                          size: "medium",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addTime(d)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "btn-add__label" },
                                          [_vm._v(_vm._s(_vm.$t("inputs.add")))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                  1
                )
              : _c("div", [
                  _vm.companySettings &&
                  _vm.companySettings.acceptanceTimes &&
                  _vm.companySettings.acceptanceTimes.findIndex(function (
                    time
                  ) {
                    return time.enabled
                  }) > -1
                    ? _c(
                        "div",
                        { staticClass: "users-list__item__slots-list" },
                        _vm._l(
                          _vm.getFilteredAvailableTimeSlots(
                            _vm.companySettings.acceptanceTimes
                          ),
                          function (slot, d) {
                            return _c(
                              "div",
                              {
                                key: d,
                                staticClass:
                                  "users-list__item__slots-list__slot",
                              },
                              [
                                _c("vs-icon", {
                                  staticClass:
                                    "users-list__item__slots-list__slot__icon",
                                  attrs: { size: "17px", icon: "check" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-2 users-list__item__slots-list__slot__day",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            _vm.modifyDayNameToLong(slot.day)
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._l(slot.times, function (time, t) {
                                  return _c(
                                    "div",
                                    { key: t, staticClass: "mt-1" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .format12HrTime(time.start)
                                              .toLowerCase()
                                          ) +
                                            " - " +
                                            _vm._s(
                                              _vm
                                                .format12HrTime(time.end)
                                                .toLowerCase()
                                            )
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", { staticClass: "mt-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("vue.timeSlotsHaveNotYetBeenSelected")
                            ) +
                            "\n        "
                        ),
                      ]),
                ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }