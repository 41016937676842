var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "assignment-rule-sidebar items-no-padding",
      attrs: {
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
        spacer: "",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _vm.ruleCondition
        ? _c(
            "vs-popup",
            {
              staticClass: "main-popup",
              attrs: {
                title: _vm.$t("vue.ruleCondition"),
                active: _vm.popupCondition,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupCondition = $event
                },
              },
            },
            [
              _c("rule-condition", {
                attrs: {
                  condition: _vm.ruleCondition,
                  "rule-condition-id": _vm.ruleConditionId,
                  "operation-type": _vm.operationType,
                  "condition-type": _vm.conditionType,
                },
                on: {
                  "save-condition": _vm.pushMainCondition,
                  "save-inner-condition": _vm.pushInnerCondition,
                  "update-condition": _vm.updateCondition,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "scroll-view",
        { staticClass: "scroll-area" },
        [
          _c(
            "div",
            { staticClass: "mt-6 flex items-center justify-between px-6" },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.id
                      ? _vm.$t("vue.editAssignmentRule").toUpperCase()
                      : _vm.$t("vue.addANewAssignmentRule").toUpperCase()
                  )
                ),
              ]),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-direction": "row" } },
                [
                  _c(
                    "vs-button",
                    {
                      staticStyle: { padding: "1rem !important" },
                      attrs: {
                        color: "primary",
                        disabled: !_vm.validateForm,
                        size: "large",
                        radius: "",
                        type: "flat",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.saveAssignmentRule()
                        },
                      },
                    },
                    [
                      _c("vs-icon", {
                        attrs: {
                          "icon-pack": "material-icons",
                          icon: "save",
                          size: "35px",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-button",
                    {
                      staticStyle: { padding: "1rem" },
                      attrs: {
                        color: "dark",
                        size: "large",
                        radius: "",
                        type: "flat",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isSidebarActiveLocal = false
                        },
                      },
                    },
                    [
                      _c("vs-icon", {
                        attrs: {
                          "icon-pack": "material-icons",
                          icon: "close",
                          size: "35px",
                          color: "#b2b2b2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("vs-divider", { staticClass: "mb-0" }),
          _c(
            "vx-card",
            [
              _c("div", { staticClass: "header-text p-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.nameOfRule"))),
              ]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full mb-2",
                attrs: {
                  "data-vv-as": _vm.$t("vue.nameOfRule"),
                  name: "rule_name",
                  "label-placeholder": _vm.$t("vue.rulePlaceholder"),
                },
                model: {
                  value: _vm.ruleName,
                  callback: function ($$v) {
                    _vm.ruleName = $$v
                  },
                  expression: "ruleName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("rule_name"))),
              ]),
              _c(
                "div",
                { staticClass: "pa-rules" },
                [
                  _c("scroll-view", { staticClass: "scroll-area" }, [
                    _c("div", { staticClass: "pa-rule-conditons" }, [
                      _c("strong", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("vue.ruleConditions")) + " "
                        ),
                      ]),
                      _c(
                        "span",
                        { staticClass: "rule-if-then-string rule-if" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("vue.if").toUpperCase()) + " "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "rule-div" }, [
                        _c("div", { staticClass: "rule-filters" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.$t("vue.filter"))),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "justify-items": "flex-end",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("div", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.$t("vue.if"))),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.conditionOptions,
                                      clearable: false,
                                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                      reduce: function (op) {
                                        return op.value
                                      },
                                      label: "label",
                                    },
                                    model: {
                                      value: _vm.condition,
                                      callback: function ($$v) {
                                        _vm.condition = $$v
                                      },
                                      expression: "condition",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ml-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.condition === "any"
                                      ? _vm.$t("vue.ofBelowConditionsApplies")
                                      : _vm.$t("vue.ofBelowConditionsApply")
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "rule-segments" },
                            [
                              _c(
                                "scroll-view",
                                { staticClass: "scroll-area" },
                                _vm._l(
                                  _vm.rule_conditions,
                                  function (rc, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "rule-condition",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "rule" },
                                          [
                                            _c(
                                              "vs-row",
                                              [
                                                _c(
                                                  "vs-col",
                                                  {
                                                    attrs: {
                                                      "vs-type": "flex",
                                                      "vs-w": "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "w-full",
                                                        staticStyle: {
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                          "justify-items":
                                                            "flex-start",
                                                          "align-items":
                                                            "normal",
                                                          "flex-shrink": "0",
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          rc.rules,
                                                          function (r, i) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: i,
                                                                staticClass:
                                                                  "mb-2",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#cdcdcd",
                                                                  "border-radius":
                                                                    "5px",
                                                                  display:
                                                                    "flex",
                                                                  "flex-direction":
                                                                    "row",
                                                                  "justify-items":
                                                                    "flex-end",
                                                                  "align-items":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "vs-col",
                                                                  {
                                                                    attrs: {
                                                                      "vs-type":
                                                                        "flex",
                                                                      "vs-w":
                                                                        "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "rule-display",
                                                                      {
                                                                        attrs: {
                                                                          rule: r,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "vs-col",
                                                                  {
                                                                    attrs: {
                                                                      "vs-type":
                                                                        "flex",
                                                                      "vs-w":
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "vs-button",
                                                                      {
                                                                        attrs: {
                                                                          radius:
                                                                            "",
                                                                          size: "large",
                                                                          color:
                                                                            "primary",
                                                                          type: "flat",
                                                                          icon: "edit",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.editCondition(
                                                                                rc,
                                                                                r
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "vs-button",
                                                                      {
                                                                        attrs: {
                                                                          radius:
                                                                            "",
                                                                          size: "large",
                                                                          color:
                                                                            "danger",
                                                                          type: "flat",
                                                                          icon: "delete",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteCondition(
                                                                                rc,
                                                                                i,
                                                                                index
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "vs-button",
                                                                      {
                                                                        attrs: {
                                                                          radius:
                                                                            "",
                                                                          disabled:
                                                                            !rc.rules ||
                                                                            i !==
                                                                              rc
                                                                                .rules
                                                                                .length -
                                                                                1,
                                                                          size: "large",
                                                                          color:
                                                                            "primary",
                                                                          type: "flat",
                                                                          icon: "add",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.addInnerCondition(
                                                                                rc
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    i > 0
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "rule-match",
                                                                            class:
                                                                              !rc.inner_condition
                                                                                ? "inner-rule-or"
                                                                                : "inner-rule-and",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                    " +
                                                                                _vm._s(
                                                                                  _vm.getInnerConditionType(
                                                                                    rc.inner_condition
                                                                                  )
                                                                                ) +
                                                                                "\n                                  "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        rc.rules &&
                                                        rc.rules.length > 1
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "condition-switch",
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "flex-direction":
                                                                    "row",
                                                                  "align-items":
                                                                    "center",
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "vue.or"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "vs-switch",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "#275D73",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            rc.inner_condition,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                rc,
                                                                                "inner_condition",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "rc.inner_condition",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "vue.and"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        index < _vm.rule_conditions.length - 1
                                          ? _c("div", {
                                              staticClass: "condition-rule-div",
                                            })
                                          : _vm._e(),
                                        index < _vm.rule_conditions.length - 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "rule-match",
                                                class:
                                                  _vm.condition === "any"
                                                    ? "rule-or"
                                                    : "rule-and",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.getConditionType(
                                                        _vm.condition
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        index < _vm.rule_conditions.length - 1
                                          ? _c("div", {
                                              staticClass: "condition-rule-div",
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _c("vs-button", {
                                attrs: {
                                  color: "primary",
                                  type: "border",
                                  icon: "add",
                                },
                                on: { click: _vm.addMainCondition },
                              }),
                              _vm.rule_conditions.length === 0 && _vm.dirty
                                ? _c(
                                    "span",
                                    { staticClass: "text-danger text-sm ml-1" },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "vue.atleastOneConditionRequired"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "assignment-actions" },
                        [
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name": "assign_type",
                                "vs-value": "agent",
                              },
                              model: {
                                value: _vm.assign_type,
                                callback: function ($$v) {
                                  _vm.assign_type = $$v
                                },
                                expression: "assign_type",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("vue.assignToAgent")))]
                          ),
                          _vm.user_groups.length > 0
                            ? _c(
                                "vs-radio",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    "vs-name": "assign_type",
                                    "vs-value": "group",
                                  },
                                  model: {
                                    value: _vm.assign_type,
                                    callback: function ($$v) {
                                      _vm.assign_type = $$v
                                    },
                                    expression: "assign_type",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("vue.assignToGroup")) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "vs-radio",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                "vs-name": "assign_type",
                                "vs-value": "block",
                              },
                              model: {
                                value: _vm.assign_type,
                                callback: function ($$v) {
                                  _vm.assign_type = $$v
                                },
                                expression: "assign_type",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("vue.block")))]
                          ),
                          _vm.assign_type === "agent"
                            ? _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  options: _vm.users,
                                  name: "assigned_to",
                                  "data-vv-as": _vm.$t("vue.agent"),
                                  "validate-on": "blur",
                                  clearable: true,
                                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                  reduce: function (op) {
                                    return op.id
                                  },
                                  placeholder: _vm.$t("vue.chooseAgent"),
                                  label: "name",
                                  autoscroll: "",
                                },
                                model: {
                                  value: _vm.assigned_to,
                                  callback: function ($$v) {
                                    _vm.assigned_to = $$v
                                  },
                                  expression: "assigned_to",
                                },
                              })
                            : _vm._e(),
                          _vm.assign_type === "group"
                            ? _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  options: _vm.user_groups,
                                  name: "assigned_to",
                                  "data-vv-as": _vm.$t("vue.group"),
                                  "validate-on": "blur",
                                  clearable: false,
                                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                  reduce: function (op) {
                                    return op.id
                                  },
                                  label: "name",
                                  placeholder: _vm.$t("vue.chooseGroup"),
                                  autoscroll: "",
                                },
                                model: {
                                  value: _vm.assigned_to,
                                  callback: function ($$v) {
                                    _vm.assigned_to = $$v
                                  },
                                  expression: "assigned_to",
                                },
                              })
                            : _vm._e(),
                          _vm.errors &&
                          _vm.errors.items &&
                          _vm.errors.items.filter(function (x) {
                            return x.field === "assigned_to"
                          }).length > 0
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.errors.items.filter(function (x) {
                                          return x.field === "assigned_to"
                                        })[0].msg
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "rule-if-then-string rule-then rule-bottom",
                        },
                        [_vm._v(_vm._s(_vm.$t("vue.then").toUpperCase()))]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }