<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ id ? $t('vue.editUserGroup').toUpperCase() : $t('vue.addANewUserGroup').toUpperCase() }}</h4>
      <div style="display: flex; flex-direction: row">
        <vs-button color="primary" :disabled="!validateForm" @click.stop="saveUserGroup()" size="large" radius type="flat" icon="save"></vs-button>
        <vs-button color="dark" @click.stop="isSidebarActiveLocal = false" size="large" radius type="flat" icon="close"></vs-button>
      </div>
    </div>

    <vs-divider class="mb-0"></vs-divider>
    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <vx-card>
        <div class="users-lists">
          <div class="header-text p-2">{{ $t('vue.nameOfGroup') }}</div>
          <vs-input
            :data-vv-as="$t('vue.nameOfGroup')"
            name="group_name"
            class="w-full mb-2"
            :label-placeholder="$t('vue.nameOfGroupDescription')"
            v-model="name"
            v-validate="'required'"
          />
          <span class="text-danger text-sm">{{ errors.first('group_name') }}</span>

          <div style="display:flex;flex-direction:row;justify-content:flex-start:align-items:center" v-if="remainingUsers.length > 0">
            <div>
              <vs-select class="w-full mr-2 user-select" v-model="selectedUser" autocomplete color="grey">
                <vs-select-item :value="user.id" :text="user.name" v-for="user in remainingUsers" :key="user.id" />
              </vs-select>
            </div>
            <div>
              <div class="ml-2">
                <vs-button
                  color="primary"
                  :disabled="selectedUser === null"
                  @click.stop="addUserToGroup()"
                  size="large"
                  radius
                  type="flat"
                  icon="add"
                ></vs-button>
              </div>
            </div>
          </div>

          <scroll-view class="mt-10" style="max-height: 200px">
            <div class="box">
              <div v-for="(user, index) in usergroup" :key="index" class="mb-4 ml-2 mr-2">
                <div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center">
                  <div class="agent-name">{{ getUserName(user.id) }}</div>

                  <Avatar class="ml-2" size="35px" :photoURL="getUserImage(user.id)" :name="getUserName(user.id)" />

                  <div class="ml-5">
                    <vx-tooltip :text="$t('vue.delete')" position="top">
                      <vs-button color="danger" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click.stop="deleteUser(user.id)"></vs-button>
                    </vx-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </scroll-view>
        </div>

        <div class="wigets">
          <div class="header-text p-2">{{ $t('vue.assignWidget') }}</div>
          <span> {{ $t('vue.assignWidgetDescription') }}</span>

          <div style="display:flex;flex-direction:row;justify-content:flex-start:align-items:center" v-if="remainingWidgets.length > 0">
            <div>
              <vs-select class="w-full mr-2 user-select" v-model="selectedWidgetUG" autocomplete color="grey">
                <vs-select-item :value="widget.id" :text="widget.name" v-for="widget in remainingWidgets" :key="widget.id" />
              </vs-select>
            </div>
            <div>
              <div class="ml-2">
                <vs-button
                  color="primary"
                  :disabled="selectedWidgetUG === null"
                  @click.stop="addWidgetToGroup()"
                  size="large"
                  radius
                  type="flat"
                  icon="add"
                ></vs-button>
              </div>
            </div>
          </div>

          <scroll-view class="mt-10" style="max-height: 200px">
            <div class="box">
              <div v-for="(widget, index) in widgets" :key="index" class="mb-4 ml-2 mr-2">
                <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
                  <WidgetIcon />

                  <div class="ml-5 widget-name">{{ getWidgetName(widget.id) }}</div>

                  <div class="ml-5">
                    <vx-tooltip :text="$t('vue.delete')" position="top">
                      <vs-button
                        color="danger"
                        type="border"
                        size="medium"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="deleteWidget(widget.id)"
                      ></vs-button>
                    </vx-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </scroll-view>
        </div>
      </vx-card>
    </component>
  </vs-sidebar>
</template>
<script>
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import vSelect from 'vue-select'

import Avatar from '@/components/Avatar'
import WidgetIcon from '@/components/icons/WidgetIcon.vue'

import ScrollView from '@blackbp/vue-smooth-scrollbar'

export default {
  data() {
    return {
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      selectedUser: null,
      selectedWidgetUG: null,
      name: '',
      usergroup: [],
      widgets: []
    }
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    id: {
      type: String
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    Avatar,
    ScrollView,
    WidgetIcon
  },
  computed: {
    ...mapGetters({
      users: 'users',
      dialogs: 'dialogs'
    }),
    remainingUsers() {
      const vm = this
      //Find values that are in result1 but not in result2
      const uniqueResultOne = vm.users.filter(function (obj) {
        return !vm.usergroup.some(function (obj2) {
          return obj.id === obj2.id
        })
      })

      //Find values that are in result2 but not in result1
      const uniqueResultTwo = vm.usergroup.filter(function (obj) {
        return !vm.users.some(function (obj2) {
          return obj.id === obj2.id
        })
      })

      //Combine the two arrays of unique entries
      return uniqueResultOne.concat(uniqueResultTwo)
    },
    remainingWidgets() {
      const vm = this
      const dialogs = vm.dialogs.map((x) => {
        return {
          id: x.id,
          name: x.name
        }
      })

      //Find values that are in result1 but not in result2
      const uniqueResultOne = dialogs.filter(function (obj) {
        return !vm.widgets.some(function (obj2) {
          return obj.id === obj2.id
        })
      })

      //Find values that are in result2 but not in result1
      const uniqueResultTwo = vm.widgets.filter(function (obj) {
        return !dialogs.some(function (obj2) {
          return obj.id === obj2.id
        })
      })

      //Combine the two arrays of unique entries
      return uniqueResultOne.concat(uniqueResultTwo)
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeUserGroupsSidebar')
        }
      }
    },
    validateForm() {
      return !this.errors.any() && this.usergroup.length > 0
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  watch: {
    async id() {
      const vm = this
      if (!vm.id) {
        vm.setDefaultValues()
      } else {
        const userGroupRef = await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('user-groups').doc(vm.id).get()
        if (userGroupRef && userGroupRef.data()) {
          const group = userGroupRef.data()
          vm.name = group.name
          vm.usergroup = group.users.map((x) => {
            const name = this.users.filter((y) => y.id === x.id).length === 0 ? null : this.users.filter((y) => y.id === x.id)[0].name

            return {
              id: x.id,
              name
            }
          })

          vm.usergroup = vm.usergroup.filter((x) => x.name)

          vm.widgets = group.widgets.map((x) => {
            const name = this.dialogs.filter((y) => y.id === x).length === 0 ? null : this.dialogs.filter((y) => y.id === x)[0].name
            return {
              id: x,
              name
            }
          })

          vm.widgets = vm.widgets.filter((x) => x.name)
        }
      }
    }
  },
  methods: {
    setDefaultValues() {
      const vm = this
      vm.usergroup = []
      vm.name = ''
      vm.widgets = []
      vm.$validator.reset()
    },
    translate(code) {
      return this.$i18n.t(code)
    },
    async saveUserGroup() {
      try {
        const vm = this
        await vm.$validator.validate()
        if (!vm.validateForm) {
          return false
        }
        const usergroup = {}
        usergroup.name = vm.name
        usergroup.users = vm.usergroup

        if (vm.widgets && vm.widgets.length > 0) {
          usergroup.widgets = vm.widgets.map((x) => x.id)
        } else if (vm.id) {
          usergroup.widgets = this.$firebase.firestore.FieldValue.delete()
        }

        if (!vm.id) {
          usergroup.created = new Date()
          usergroup.createdBy = this.activeUserInfo.uid
          await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('user-groups').add(usergroup)
        } else {
          usergroup.modified = new Date()
          usergroup.modifiedBy = this.activeUserInfo.uid
          await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('user-groups').doc(vm.id).set(usergroup, { merge: true })
        }
        vm.setDefaultValues()

        vm.isSidebarActiveLocal = false
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    },
    addUserToGroup() {
      this.usergroup.push({ id: this.selectedUser })
      this.selectedUser = null
    },
    addWidgetToGroup() {
      this.widgets.push({ id: this.selectedWidgetUG })
      this.selectedWidgetUG = null
    },
    getWidgetName(id) {
      return this.dialogs.filter((x) => x.id === id).length === 0 ? null : this.dialogs.filter((x) => x.id === id)[0].name
    },
    getUserName(id) {
      return this.users.filter((x) => x.id === id).length === 0 ? null : this.users.filter((x) => x.id === id)[0].name
    },
    getUserImage(id) {
      return this.users.filter((x) => x.id === id).length === 0 ? false : this.users.filter((x) => x.id === id)[0].image
    },
    deleteUser(id) {
      const itemIndex = this.usergroup.findIndex((p) => p.id === id)
      this.usergroup.splice(itemIndex, 1)
    },
    deleteWidget(id) {
      const itemIndex = this.widgets.findIndex((p) => p.id === id)
      this.widgets.splice(itemIndex, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.box > * {
  flex: 0 0 45%;
}
.agent-name,
.widget-name {
  font-size: 0.95rem;
  font-weight: 500;
  width: 50%;
  flex-grow: 0.6;
}
.user-select {
  max-width: fit-content;
  min-width: 250px;
}
.header-text {
  font-weight: bold;
}
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 1024x;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.add-new-data-sidebar {
  .vs-sidebar {
    max-width: 800px !important;
  }
}

.vs__search,
.vs__selected,
.vs__search:focus {
  line-height: 1.8 !important;
}
</style>
