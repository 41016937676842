<template>
  <svg
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :stroke="color"
  >
    <path d="M9 2H3C2.44772 2 2 2.44772 2 3V9M11 4V10C11 10.5523 10.5523 11 10 11H4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.5 1V3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.50024 1V3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 1L1 12" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 5H9.5H11" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff',
    },

    width: {
      type: Number,
      default: 35,
    },

    height: {
      type: Number,
      default: 35,
    },
  },
}
</script>
