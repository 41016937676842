<template>
  <div class="p-2">
    <div style="display: flex; flex-direction: row">
      <div class="ml-2">
        <vs-chip color="primary">
          <strong>{{ fieldList.find((x) => x.value === rule.field).label }}</strong>
        </vs-chip>
      </div>
      <div class="ml-2">
        <vs-chip color="success">{{ operatorList.find((x) => x.value === rule.condition_type).label.toUpperCase() }}</vs-chip>
      </div>
    </div>

    <div class="mt-2" style="display: flex; flex-direction: row">
      <template v-if="rule.field === 'dialogId'">
        <template v-if="rule.condition_type === 'in' || rule.condition_type === 'not-in'">
          <div v-for="(v, index) in rule.value" :key="index" class="ml-2">
            <vs-chip> {{ dialogs.find((x) => x.id === v).name }} {{ rule.value.length > 1 && rule.value.length > index + 1 ? ', ' : '' }} </vs-chip>
          </div>
        </template>
        <template v-else>
          <div class="ml-2">
            <vs-chip>{{ dialogs.find((x) => x.id === rule.value).name }}</vs-chip>
          </div>
        </template>
      </template>
      <template v-if="rule.field === 'locale'">
        <template v-if="rule.condition_type === 'in' || rule.condition_type === 'not-in'">
          <div v-for="(v, index) in rule.value" :key="index" class="ml-2">
            <vs-chip>
              {{ languageCodes.find((x) => x.code === v).nativeName }} {{ rule.value.length > 1 && rule.value.length > index + 1 ? ', ' : '' }}
            </vs-chip>
          </div>
        </template>
        <template v-else>
          <div class="ml-2">
            <vs-chip>
              {{ languageCodes.find((x) => x.code === rule.value).nativeName }}
            </vs-chip>
          </div>
        </template>
      </template>
      <template v-if="rule.field === 'country'">
        <template v-if="rule.condition_type === 'in' || rule.condition_type === 'not-in'">
          <div v-for="(v, index) in rule.value" :key="index" class="ml-2">
            <vs-chip>
              {{ countryCodes.find((x) => x.code === v).countryName }} {{ rule.value.length > 1 && rule.value.length > index + 1 ? ', ' : '' }}
            </vs-chip>
          </div>
        </template>
        <template v-else>
          <div class="ml-2">
            <vs-chip>
              {{ countryCodes.find((x) => x.code === rule.value).countryName }}
            </vs-chip>
          </div>
        </template>
      </template>
      <template v-if="rule.field === 'city'">
        <template v-if="rule.condition_type === 'in' || rule.condition_type === 'not-in'">
          <div v-for="v in rule.value" :key="v" class="ml-2">
            <vs-chip>{{ v }}</vs-chip>
          </div>
        </template>
      </template>
      <template v-if="rule.field === 'location'">
        <div class="ml-2">
          <vs-chip>{{ rule.value }}</vs-chip>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RuleDisplay',
  props: {
    rule: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      dialogs: 'dialogs'
    })
  },
  data() {
    return {
      countryCodes: require('@/assets/countryCodes.json'),
      languageCodes: require('@/assets/languageCodes.json'),
      fieldList: [
        { label: this.translate('vue.country'), value: 'country' },
        { label: this.translate('vue.city'), value: 'city' },
        { label: this.translate('vue.url'), value: 'location' },
        { label: this.translate('vue.language'), value: 'locale' },
        { label: this.translate('vue.widget'), value: 'dialogId' }
      ],
      operatorList: [
        { label: this.translate('vue.contains'), value: 'contains' },
        { label: this.translate('vue.equals'), value: 'equals' },
        { label: this.translate('vue.notEquals'), value: 'not-equals' },
        { label: this.translate('vue.doesNotContain'), value: 'does-not-contain' },
        { label: this.translate('vue.includes'), value: 'in' },
        { label: this.translate('vue.excludes'), value: 'not-in' }
      ]
    }
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    }
  }
}
</script>
