var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "agency-view" } },
    [
      _c("AgencySidebar", {
        attrs: {
          isSidebarActive: _vm.isOpenedSidebar,
          appActiveUser: _vm.activeUserInfo,
        },
        on: { closeAppointmentsSidebar: _vm.toggleSidebar },
      }),
      _c(
        "vx-card",
        [
          _vm.isAgency
            ? _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        color: "primary",
                        type: "filled",
                        "icon-size": "24px",
                        icon: "add",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.activePrompt = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("inputs.inviteCompany")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm.activeUserInfo.superadmin
                    ? _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "primary",
                            type: "filled",
                            "icon-size": "24px",
                            icon: "add",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggleSidebar(true)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("inputs.createAccount")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-table",
            {
              ref: "table",
              attrs: {
                pagination: "",
                "max-items": _vm.itemsPerPage,
                search: "",
                data: _vm.allowedCompanies,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var data = ref.data
                    return [
                      _c(
                        "tbody",
                        _vm._l(data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].name } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].cooperation } },
                                [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        color: _vm.setCooperationColorByType(
                                          data[indextr].cooperation
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "vue." + data[indextr].cooperation
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].phone } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].phone) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].url } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].url) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                data[indextr].street
                                  ? _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.street")) + " : "
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(data[indextr].street) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                data[indextr].city
                                  ? _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.city")) + " : "
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(data[indextr].city) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                data[indextr].zipcode
                                  ? _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.zipcode")) + " : "
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(data[indextr].zipcode) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                data[indextr].country
                                  ? _c("p", [
                                      data[indextr].country.countryName
                                        ? _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.$t("vue.country")) +
                                                " : "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(
                                          data[indextr].country.countryName
                                        ) + "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "vs-td",
                                { staticClass: "whitespace-no-wrap" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _vm.isAgency
                                        ? _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                position: "top",
                                                text: _vm.$t(
                                                  "vue.openSettingsForTheCompany"
                                                ),
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  color:
                                                    data[indextr].approved ===
                                                    "confirmed"
                                                      ? "primary"
                                                      : "dark",
                                                  icon: "icon-info",
                                                  disabled:
                                                    data[indextr].approved !==
                                                    "confirmed",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleSidebar(
                                                      true,
                                                      data[indextr]
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isAgency
                                        ? _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                position: "top",
                                                text: _vm.$t(
                                                  "vue.logInToCustomerAccount"
                                                ),
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-user",
                                                  color:
                                                    data[indextr].approved ===
                                                    "confirmed"
                                                      ? "primary"
                                                      : "dark",
                                                  disabled:
                                                    data[indextr].approved !==
                                                    "confirmed",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.impersonate(
                                                      data[indextr].createdBy
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isAgency &&
                                      _vm.activeUserInfo.superadmin
                                        ? _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                position: "top",
                                                text: _vm.$t(
                                                  "vue.sendSignInLinkToTheCustomersEmailAddress"
                                                ),
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-send",
                                                  color:
                                                    data[indextr].approved ===
                                                    "confirmed"
                                                      ? "primary"
                                                      : "dark",
                                                  disabled:
                                                    data[indextr].approved !==
                                                    "confirmed",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sendSignInLink(
                                                      data[indextr].customer
                                                        .email
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.activeUserInfo.superadmin
                                        ? _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                position: "top",
                                                text: _vm.$t(
                                                  "vue.cancelTheCooperation"
                                                ),
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-trash",
                                                  color: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openDeletingPrompt(
                                                      data[indextr]
                                                        .uidAgencySettings
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap-reverse items-center flex-grow justify-between",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", {
                    staticClass: "flex flex-wrap-reverse items-center",
                  }),
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer mb-4 mr-4",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentPage * _vm.itemsPerPage -
                                  (_vm.itemsPerPage - 1)
                              ) +
                                " -\n              " +
                                _vm._s(
                                  _vm.allowedCompanies.length -
                                    _vm.currentPage * _vm.itemsPerPage >
                                    0
                                    ? _vm.currentPage * _vm.itemsPerPage
                                    : _vm.allowedCompanies.length
                                ) +
                                " of\n              " +
                                _vm._s(_vm.queriedItems)
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 5
                                },
                              },
                            },
                            [_c("span", [_vm._v("5")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 10
                                },
                              },
                            },
                            [_c("span", [_vm._v("10")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 15
                                },
                              },
                            },
                            [_c("span", [_vm._v("15")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 20
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { "sort-key": "name" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("vue.name")) + "\n        "
                    ),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "cooperation" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.cooperation")) +
                        "\n        "
                    ),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "phone" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.phone")) +
                        "\n        "
                    ),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "url" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("vue.url")) + "\n        "
                    ),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "city" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.address")) +
                        "\n        "
                    ),
                  ]),
                  _c("vs-th", [_vm._v(_vm._s(_vm.$t("vue.action")))]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.activePrompt
        ? _c(
            "vs-prompt",
            {
              attrs: {
                "cancel-text": _vm.$t("vue.cancel"),
                "accept-text": _vm.$t("vue.confirm"),
                title: _vm.$t("inputs.inviteCompany"),
                active: _vm.activePrompt,
              },
              on: {
                "update:active": function ($event) {
                  _vm.activePrompt = $event
                },
                accept: _vm.inviteCompany,
              },
            },
            [
              _c(
                "div",
                { staticClass: "con-exemple-prompt" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("inputs.enterCompanyId")))]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "mt-3 w-full",
                    attrs: {
                      placeholder: _vm.$t("inputs.companyId"),
                      name: _vm.$t("inputs.companyId"),
                      "data-vv-validate-on": "blur",
                    },
                    model: {
                      value: _vm.inviteData.companyId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inviteData, "companyId", $$v)
                      },
                      expression: "inviteData.companyId",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.activeDeletingPrompt
        ? _c(
            "vs-prompt",
            {
              attrs: {
                color: "danger",
                "cancel-text": _vm.$t("vue.cancel"),
                "accept-text": _vm.$t("vue.delete"),
                title: _vm.$t("inputs.deleteCompany"),
                active: _vm.activeDeletingPrompt,
              },
              on: {
                "update:active": function ($event) {
                  _vm.activeDeletingPrompt = $event
                },
                accept: _vm.deleteCompany,
              },
            },
            [
              _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("info.terminateTheCooperationWithTheCompany"))
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }