<template>
  <div>
    <ag-grid-vue
      ref="agGridTable"
      class="ag-theme-material w-100 mt-2 mb-4 ag-grid-table"
      rowSelection="multiple"
      colResizeDefault="shift"
      :style="{
        height: `${selectedAgents.length * 47 + 55}px !important`
      }"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :columnTypes="columnTypes"
      :rowData="selectedAgents"
      :pagination="false"
      :suppressPaginationPanel="true"
      :suppressPropertyNamesCheck="true"
      :enableRtl="$vs.rtl"
      @row-drag-end="onRowDragEnd"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererName from './cell-renderer/CellRendererName'
import CellRendererRingingDuration from './cell-renderer/CellRendererRingingDuration'
import CellRendererActions from './cell-renderer/CellRendererActions'

export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line
    CellRendererName,
    // eslint-disable-next-line
    CellRendererRingingDuration,
    // eslint-disable-next-line
    CellRendererActions
  },

  props: {
    selectedAgents: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      gridOptions: {
        suppressPropertyNamesCheck: true,
        suppressRowDrag: false
      },
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true
      },
      columnTypes: {
        textColumn: { editable: false }
      }
    }
  },

  computed: {
    columnDefs() {
      return [
        {
          headerName: this.translate('vue.name'),
          filter: true,
          cellRendererFramework: 'CellRendererName',
          type: 'textColumn',
          width: 200,
          resizable: true,
          rowDrag: true
        },
        {
          headerName: 'Ringing duration',
          cellRendererFramework: 'CellRendererRingingDuration',
          width: 150,
          resizable: true
        },
        {
          headerName: this.translate('vue.action'),
          field: 'id',
          cellRendererFramework: 'CellRendererActions',
          width: 150,
          resizable: true
        }
      ]
    },
  },

  methods: {
    onRowDragEnd(event) {
      this.$serverBus.$emit('on-row-drag-end', {
        overIndex: event.overIndex,
        id: event.node.data.id,
        rowIndex: event.node.rowIndex
      })
    },

    translate(code) {
      return this.$i18n.t(code)
    },
  }
}
</script>
