var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { width: "32", height: "32", rx: "10.6667", fill: "#3034F7" },
      }),
      _c(
        "mask",
        {
          staticStyle: { "mask-type": "alpha" },
          attrs: {
            id: "mask0_7908_499",
            maskUnits: "userSpaceOnUse",
            x: "0",
            y: "0",
            width: "32",
            height: "32",
          },
        },
        [
          _c("rect", {
            attrs: { width: "32", height: "32", rx: "10.6667", fill: "white" },
          }),
        ]
      ),
      _c("g", { attrs: { mask: "url(#mask0_7908_499)" } }),
      _c("path", {
        attrs: {
          d: "M16.5332 8.5332C12.1221 8.5332 8.5332 11.5093 8.5332 15.1675C8.56074 16.9477 9.36936 18.6244 10.7419 19.7473L9.1419 22.9594C9.07546 23.0927 9.10021 23.2537 9.20355 23.3606C9.3069 23.4675 9.46616 23.4967 9.60033 23.4335L13.8779 21.4293C14.7411 21.6789 15.6351 21.8045 16.5332 21.8025C20.9443 21.8025 24.5332 18.8264 24.5332 15.1675C24.5332 11.5086 20.9443 8.5332 16.5332 8.5332Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }