<template>
  <div>
    <div class="flex" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
      <vs-button radius size="large" color="dark" type="flat" :disabled="isUpArrowDisabled" icon="arrow_circle_up" @click="moveUp()"></vs-button>
      <vs-button radius size="large" color="dark" type="flat" :disabled="isDownArrowDisabled" icon="arrow_circle_down" @click="moveDown()"></vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null
    }
  },

  computed: {
    isUpArrowDisabled() {
      return this.currentRow === 0
    },

    isDownArrowDisabled() {
      return this.totalCount === 0 || this.currentRow === this.totalCount
    },

    currentRow() {
      return this.params.rowIndex
    },

    totalCount() {
      return this.params.api.getDisplayedRowCount() > 0 ? this.params.api.getDisplayedRowCount() - 1 : this.params.api.getDisplayedRowCount()
    }
  },

  mounted() {
    this.id = this.params.value
  },

  methods: {
    moveUp() {
      this.$serverBus.$emit('move-rule-up', {
        id: this.id,
        newIndex: this.currentRow - 1
      })
    },

    moveDown() {
      this.$serverBus.$emit('move-rule-down', {
        id: this.id,
        newIndex: this.currentRow + 1
      })
    },

  }
}
</script>
