var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material w-100 mt-2 mb-4 ag-grid-table",
        style: {
          height: _vm.selectedAgents.length * 47 + 55 + "px !important",
        },
        attrs: {
          rowSelection: "multiple",
          colResizeDefault: "shift",
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          columnTypes: _vm.columnTypes,
          rowData: _vm.selectedAgents,
          pagination: false,
          suppressPaginationPanel: true,
          suppressPropertyNamesCheck: true,
          enableRtl: _vm.$vs.rtl,
        },
        on: { "row-drag-end": _vm.onRowDragEnd },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }