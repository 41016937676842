<template>
  <div>
    <!-- <<<TRACKER>>> -->
    <noscript><img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=781026859376593&ev=PageView&noscript=1" /></noscript>
    <img height="1" width="1" style="display: none" alt="" src="https://px.ads.linkedin.com/collect/?pid=2737946&conversionId=3589866&fmt=gif" />
    <vs-popup class="company-setup-popup" title="" :active.sync="showSetupMessage">
      <vx-card style="width: 490px; height: 430px" class="text-center bg-primary-gradient greet-user">
        <div class="flex" style="flex-direction: column">
          <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200" />
          <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175" />
          <img src="@/assets/images/elements/rocket.png" class="rocket" alt="rocket" width="200" />
          <h1 style="margin-top: 93px" class="text-white">{{ $t('vue.congratulations') }}</h1>
          <h1 class="mb-6 text-white">{{ `${activeUserInfo.firstname},` }}</h1>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">{{ $t('vue.youAreReadyToSetupYourDialog') }}</p>
        </div>
      </vx-card>
    </vs-popup>

    <vs-tabs v-show="isTabsLoaded" v-model="activeTabIndex" :key="tabKey" class="company-setup-tabs">
      <vs-tab :label="$t('routes.companySetup')">
        <div class="con-tab-ejemplo">
          <PartnerAgreement v-show="partnerAgreement"></PartnerAgreement>
          <div class="vx-row" v-if="!partnerAgreement">
            <div class="vx-col w-full lg:w-2/3 mb-base" :class="companyName === 'Incomplete Registration' ? 'cs-zindex' : ''">
              <vs-card id="company-details" class="custom-card">
                <div slot="header" class="custom-card__header">
                  <h4>{{ $t(companyName === 'Incomplete Registration' ? 'vue.completeYourRegistration' : 'vue.setupYourCompanyDetails') }}</h4>

                  <div v-if="company.id" class="custom-card__header__label">Account-ID: {{ company.id }}</div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.companyDisplayName') }}</span>
                  </div>
                  <div class="vx-col sm:w-5/6 w-full">
                    <vs-input
                      :placeholder="$t('vue.companyDisplayName')"
                      id="companySetup_companyDisplayName"
                      class="w-full"
                      v-model="company.companyDisplayName"
                      v-validate="'min:3'"
                      name="companyDisplayName"
                      data-vv-as="companyDisplayName"
                      validate-on="blur"
                    />
                    <span class="text-danger text-sm">{{ errors.first('name') }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.companyName') }}</span>
                  </div>
                  <div class="vx-col sm:w-5/6 w-full">
                    <vs-input
                      :placeholder="$t('vue.myAwesomeWebsite')"
                      id="companySetup_WebsiteName"
                      class="w-full"
                      v-model="company.name"
                      v-validate="'required|min:3'"
                      name="name"
                      data-vv-as="name"
                      validate-on="blur"
                    />
                    <span class="text-danger text-sm">{{ errors.first('name') }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.street') }}</span>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <vs-input class="w-full" v-model="company.street" />
                  </div>
                  <div class="vx-col sm:w-1/6 w-full">
                    <span class="w-full">{{ $t('vue.houseNumber') }}</span>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <vs-input class="w-full" v-model="company.houseNumber" />
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.zipcode') }}</span>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <vs-input class="w-full" v-model="company.zipcode" v-validate="'required'" name="zipcode" data-vv-as="zipcode" validation-on="change" />
                    <span class="text-danger text-sm">{{ errors.first('zipcode') }}</span>
                  </div>
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.city') }}</span>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <vs-input class="w-full" v-model="company.city" />
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.country') }}</span>
                  </div>
                  <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                      ref="country"
                      v-model="company.country"
                      :options="countryCodes"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :placeholder="''"
                      :selectLabel="''"
                      :selectedLabel="''"
                      :deselectLabel="''"
                      label="name"
                      track-by="name"
                      :name="$t('inputs.country')"
                      open-direction="bottom"
                      v-validate="'required'"
                      :data-vv-as="$t('inputs.country')"
                      :preserve-search="true"
                      :preselect-first="false"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.phone') }}</span>
                  </div>
                  <div class="vx-col sm:w-5/6 w-full">
                    <vs-input
                      placeholder="+1 656 234242"
                      class="w-full"
                      :v-validate="companyData && companyData.isAppSumoAccount ? '' : 'required'"
                      name="phone"
                      data-vv-as="phone"
                      validation-on="change"
                      v-model="company.phone"
                    />
                    <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.vatNumber') }}</span>
                    <div class="font10">({{ $t('vue.optional') }})</div>
                  </div>
                  <div class="vx-col sm:w-5/6 w-full">
                    <input
                      ref="vatNumber"
                      type="text"
                      class="vs-inputx vs-input--input normal"
                      v-model="company.vatNumber"
                      :name="$t('inputs.vatNumber')"
                      :label-placeholder="$t('inputs.vatNumber')"
                      v-validate="'isvatvalid'"
                      data-vv-validate-on="blur"
                    />

                    <span v-if="errors.first($t('inputs.vatNumber'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.vatNumber')) }}
                    </span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/6 w-full">
                    <span>{{ $t('vue.website') }}</span>
                  </div>
                  <div class="vx-col sm:w-5/6 w-full">
                    <vs-input
                      placeholder="https://www.mycompanyname"
                      v-validate="'isURLValid'"
                      name="url"
                      data-vv-as="url"
                      class="w-full"
                      v-model="company.url"
                      validate-on="blur"
                    />
                    <span class="text-danger text-sm">{{ errors.first('url') }}</span>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col flex items-center justify-end w-full">
                    <vs-button @click="registerCompany" :disabled="!validateForm || loading || !defaultAffiliate" class="mb-2">{{ $t('vue.save') }}</vs-button>
                    <vs-button v-if="activeUserInfo && activeUserInfo.company" color="warning" @click="onSkipCompanyRegistration" class="ml-3 mb-2">
                      {{ $t('vue.skip') }}
                    </vs-button>
                  </div>
                </div>
              </vs-card>
            </div>
            <div class="vx-col w-full lg:w-1/3 mb-base">
              <vx-card class="text-center bg-primary-gradient greet-user">
                <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200" />
                <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175" />
                <feather-icon icon="AwardIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
                <h1 class="mb-6 text-white">{{ $t('vue.congratulations') }}</h1>
                <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">{{ $t('vue.youAreReadyToEngageYourVisitors') }}</p>
              </vx-card>
              <vx-card class="text-center bg-primary-gradient mt-3 greet-user">
                <div class="vx-col center ml-auto">
                  <a href="mailto:office@pathadvice.at" :disabled="loading" class="mr-3 mb-2 flat">
                    <feather-icon icon="AwardIcon" class="p-3 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
                    <h3 class="partner">{{ $t('vue.becomePartner') }}</h3>
                  </a>
                </div>
              </vx-card>
            </div>
          </div>
        </div>
      </vs-tab>

      <vs-tab :label="$t('routes.users')">
        <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
          <users-list />
        </div>
      </vs-tab>

      <vs-tab :label="$t('routes.legal')">
        <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
          <legal />
        </div>
      </vs-tab>

      <vs-tab v-if="hasAccessToViewTab" icon-pack="feather" icon="icon-user-check" :label="!isSmallerScreen ? $t('vue.assignmentRules') : ''">
        <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
          <assignment-rules />
        </div>
      </vs-tab>

      <vs-tab v-if="hasAccessToViewTab" icon-pack="feather" icon="icon-users" :label="!isSmallerScreen ? $t('vue.userGroups') : ''">
        <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
          <user-groups />
        </div>
      </vs-tab>

      <vs-tab v-if="hasAccessToViewTab" icon-pack="feather" icon="icon-calendar" :label="!isSmallerScreen ? $t('routes.appointmentSettings') : ''">
        <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
          <Appointments @setActiveTabIndex="activeTabIndex = $event" />
        </div>
      </vs-tab>
      <!-- <vs-tab v-if="HAS_CALL_CENTER_ACCESS || isCallCenterList" icon-pack="feather" icon="icon-phone" label="Managed Service"> -->
      <!-- Call Center Code Commented Start -->
      <vs-tab
        v-if="HAS_CALL_CENTER_ACCESS && hasAccessToViewTab"
        icon-pack="feather"
        icon="icon-phone"
        :label="!isSmallerScreen ? $t('register.callCenterService') : ''"
      >
        <CallCenter :isCallCenter="companyData && companyData.isCallCenter" :isCallCenterList="isCallCenterList" />
      </vs-tab>
      <!-- Call Center Code Commented End -->
      <vs-tab
        v-if="(HAS_AGENCY_ACCESS || isAgencyList) && hasAccessToViewTab"
        icon-pack="feather"
        icon="icon-server"
        :label="!isSmallerScreen ? 'Managed Agency' : ''"
      >
        <Agency :isAgency="companyData && companyData.isAgency" />
      </vs-tab>

      <vs-tab v-if="hasAccessToViewTab" icon-pack="feather" icon="icon-briefcase" :label="!isSmallerScreen ? 'API Keys' : ''">
        <ApiKeys />
      </vs-tab>

      <vs-tab v-if="hasAccessToViewTab" icon-pack="feather" icon="icon-link-2" :label="!isSmallerScreen ? 'Webhooks' : ''">
        <WebHooks />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<!-- Global site tag (gtag.js) - Google Ads: 472108440 -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-472108440"></script>

<script>
window.dataLayer = window.dataLayer || []
function gtag() {
  dataLayer.push(arguments)
}
gtag('js', new Date())

gtag('config', 'AW-472108440')
gtag('event', 'conversion', {
  send_to: 'AW-472108440/YWzdCNGb1fMBEJibj-EB',
  transaction_id: ''
})
</script>
<!-- Facebook Pixel Code -->
<script>
!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  }
  if (!f._fbq) f._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
  t = b.createElement(e)
  t.async = !0
  t.src = v
  s = b.getElementsByTagName(e)[0]
  s.parentNode.insertBefore(t, s)
})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
fbq('init', '781026859376593')
fbq('track', 'PageView')
</script>
<!-- End Facebook Pixel Code -->
<script>
fbq('track', 'Purchase', { currency: 'EUR', value: 49.0 })
</script>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

import { Validator } from 'vee-validate'

import { mapGetters } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import PartnerAgreement from './pages/PartnerAgreement'
import AssignmentRules from './pages/company/assignment-rules/AssignmentRules.vue'
import UserGroups from './pages/company/user-groups/UserGroups.vue'
import Appointments from './pages/company/appointments/Appointments.vue'
import CallCenter from './pages/company/call-center/CallCenter.vue'
import Agency from './pages/company/agency/Agency'
import Legal from './pages/company/legal/Legal.vue'
import UsersList from '@/views/UsersList.vue'
import ApiKeys from './pages/company/api-keys/ApiKeys.vue'
import WebHooks from './pages/company/web-hooks/WebHooks.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'

export default {
  components: {
    PartnerAgreement,
    AssignmentRules,
    UserGroups,
    Appointments,
    CallCenter,
    Legal,
    UsersList,
    Agency,
    Multiselect,
    ApiKeys,
    WebHooks
  },

  data() {
    return {
      isMounted: false,
      isTabsLoaded: false,
      isRegistrationIncomplete: false,
      isCallCenterList: false,
      isAgencyList: false,
      activeTabIndex: 0,
      tabKey: Math.random().toString(36).substring(2, 15),
      company: {
        name: '',
        url: '',
        phone: '',
        zipcode: '',
        street: '',
        city: '',
        country: '',
        countryCode: '',
        houseNumber: '',
        companyDisplayName: '',
        vatNumber: '',
        previewUrl: null,
        isCallCenter: false,
        isAgency: false
      },
      loading: false,
      partnerAgreement: false,
      companyName: '',
      countryCodes: require('@/assets/countryCodes.json'),
      showSetupMessage: false,
      disallowedRoles: ['financial', 'view-only', 'vega-analyze']
    }
  },

  watch: {
    'company.country.code'() {
      if (this.isMounted && this.company && this.company.country && this.company.country.code) {
        this.$validator.validate(this.$i18n.t('inputs.vatNumber'))
      }
    },
    'company.country'(value) {
      const countryCode = this.countryCodes.find((country) => country.code === value.code)
      this.company.countryCode = countryCode && countryCode.code ? countryCode.code : ''
    },
    'company.vatNumber'() {
      if (this.company && this.company.vatNumber) {
        this.company.vatNumber = this.company.vatNumber.toUpperCase()
        this.checkIfVatHasCountryCode()
      }
    },
    HAS_CALL_CENTER_ACCESS() {
      this.tabKey = Math.random().toString(36).substring(2, 15)
    },
    HAS_AGENCY_ACCESS() {
      this.tabKey = Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      companyData: 'company',
      dialogs: 'dialogs'
    }),

    hasAccessToViewTab() {
      if (!this.activeUserInfo || !this.activeUserInfo.role) return false

      return !this.disallowedRoles.includes(this.activeUserInfo.role)
    },

    validateForm() {
      return !this.errors.any() && this.company.name !== ''
    },

    defaultAffiliate() {
      return (
        (this.company.name === 'PATHADVICE International GmbH' && this.companyName === 'PATHADVICE International GmbH') ||
        this.company.name !== 'PATHADVICE International GmbH'
      )
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    locale() {
      return this.$i18n.locale
    },
    HAS_CALL_CENTER_ACCESS() {
      if (!this.companyData) {
        return false
      }
      let claims = this.companyData.claims || []
      const custom_claims = this.companyData.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return (
        claims.includes('call-center') &&
        this.companyData.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.companyData.expiryDate.seconds)) &&
        (this.companyData.isCallCenter || this.companyData.isCallCenterCustomer)
      )
    },
    HAS_AGENCY_ACCESS() {
      return this.companyData && this.companyData.expiryDate && dayjs().isBefore(dayjs.unix(this.companyData.expiryDate.seconds)) && this.companyData.isAgency
    }
  },

  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },

  async mounted() {
    const vm = this
    await vm.$vs.loading()

    Validator.extend('isvatvalid', {
      validate: this.isVatValid,
      getMessage: (field, params, data) => data.message
    })

    await vm.getCompanyInfo()
    vm.$serverBus.$on('close-partnerAgreement', () => {
      vm.partnerAgreement = false
    })
    if ((vm.company && vm.company.name === 'Incomplete Registration') || vm.company.name === '') {
      document.getElementById('pa-overlay').style.display = 'block'
    } else {
      document.getElementById('pa-overlay').style.display = 'none'
    }
    await vm.$vs.loading.close()
    vm.isMounted = true

    vm.setConfirmationEmailInterval()
    this.useRouteParams()
  },

  beforeDestroy() {
    this.$serverBus.$off('close-partnerAgreement')
  },

  methods: {
    notifySuccess() {
      this.$vs.notify({
        text: this.$t('vue.changesSavedSuccessfully'),
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'success'
      })
    },
    hasNumber(code) {
      return /\d/.test(code)
    },
    checkIfVatHasCountryCode() {
      if (!this.company || !this.company.vatNumber || this.company.vatNumber.length < 2) return false
      const vat = this.company.vatNumber.toLowerCase()
      const countryCodeVat = vat.substr(0, 2)
      /* Return false if country code has digits */
      if (this.hasNumber(countryCodeVat)) return false

      const countryCode = this.countryCodes.find((x) => x.code === countryCodeVat)
      if (countryCode) {
        this.company.country = countryCode
        this.company.countryCode = countryCode.code
      }

      if (!countryCode) {
        return false
      }

      return true
    },
    isVatValid(xx) {
      const vm = this

      return new Promise(async (resolve) => {
        if (!vm.checkIfVatHasCountryCode()) {
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_002')
            }
          })
        }

        if (!vm.company || (vm.company && vm.company.name && vm.company.name.trim().length === 0)) {
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_004')
            }
          })
        }

        if (!vm.company.country || !vm.company.country.code) {
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_001')
            }
          })
        }

        if (!vm.company.vatNumber || vm.company.vatNumber.length <= 2) {
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_003')
            }
          })
        }

        const axios = require('axios')
        const vatNumber = vm.company.vatNumber.substring(2)
        const companyName = encodeURI(vm.company.name)
        axios
          .get(`${vm.$functionsURL}/validateVat?cc=${vm.company.country.code}&vat=${vatNumber}&cp=${companyName}`)
          .then(function (response) {
            if (response && response.data && response.data.success) {
              return resolve({
                valid: true
              })
            }
            if (response && response.data && response.data.error) {
              return resolve({
                valid: false,
                data: {
                  message: vm.$i18n.t(response.data.error)
                }
              })
            }
          })
          .catch(function (error) {
            return resolve({
              valid: false,
              data: {
                message: error.message
              }
            })
          })
      })
    },
    useRouteParams() {
      if (this.$route.params.action) {
        switch (this.$route.params.action) {
          case 'create-user-group':
            setTimeout(() => {
              this.activeTabIndex = 4
            }, 200)

            break
          case 'create-appointment':
            setTimeout(() => {
              this.activeTabIndex = 5

              this.$nextTick(() => {
                this.$serverBus.$emit('toggle-sidebar', true)
              })
            }, 200)

            break

          case 'show-call-center-settings':
            setTimeout(() => {
              this.activeTabIndex = 6
              const { data } = this.$route.params
              this.$nextTick(() => {
                this.$serverBus.$emit('toggle-active-call-center-sidebar', {
                  isOpened: true,
                  data
                })
              })
            }, 500)

            break
        }
      }
    },

    setConfirmationEmailInterval() {
      const vm = this
      vm.resendConfirmationEmailInterval = setInterval(() => {
        if (vm.timeoutResendConfirmationEmail > 0) {
          vm.timeoutResendConfirmationEmail = vm.timeoutResendConfirmationEmail - 1000
        } else {
          clearInterval(vm.resendConfirmationEmailInterval)
        }
      }, 1000)
    },
    async sendConfirmationEmail() {
      this.$vs.loading()
      this.timeoutResendConfirmationEmail = 30000
      this.setConfirmationEmailInterval()
      const sendEmailVerificationEmail = this.$functions.httpsCallable('sendEmailVerificationEmail')
      const result = await sendEmailVerificationEmail({ uid: this.activeUserInfo.uid })
      if (result.data && result.data.status === true) {
        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.sentConfirmationEmail'),
          color: 'green'
        })
      } else {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.couldNotSendConfirmationEmail'),
          color: 'red'
        })
      }
      this.$vs.loading.close()
    },

    openFile(file) {
      const today = new Date()
      const iToday = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`)
      window.open(`/documents/${file}?v=${iToday.getTime()}`, '_blank')
    },

    async getCompanyInfo() {
      const firebaseCurrentUser = firebase.auth().currentUser
      await firebaseCurrentUser.getIdToken(true)
      const idTokenResult = await firebaseCurrentUser.getIdTokenResult()

      if (idTokenResult && idTokenResult.claims.company) {
        const companyRef = await this.$db.collection('company').doc(idTokenResult.claims.company).get()
        const company = companyRef.data()

        if (company && company.name === '' && idTokenResult.claims.name) {
          company.name = idTokenResult.claims.name
          await this.$db.collection('company').doc(companyRef.id).set({ name: idTokenResult.claims.name }, { merge: true })
          const updatedCompany = { companyInfo: company }
          await this.$store.commit('UPDATE_USER_INFO', updatedCompany)
        }

        if (company && (company.name === 'Incomplete Registration' || company.name === '')) {
          const companyName = this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
          await firebase.firestore().collection('company').doc(company.id).set({ name: companyName }, { merge: true })
          const companyRef = await firebase.firestore().collection('company').doc(company.id).get()
          const updatedCompany = { companyInfo: companyRef.data() }
          await this.$store.commit('UPDATE_USER_INFO', updatedCompany)

          company.name = companyRef.data().name
          company.url = ''
          company.phone = ''
          company.zipcode = ''
          company.street = ''
          company.city = ''
          company.country = ''
          company.countryCode = ''
          company.houseNumber = ''
          company.companyDisplayName = ''
          company.vatNumber = ''

          this.isRegistrationIncomplete = false
        }

        if (company) {
          const cp = company

          cp.country = company.country ? company.country : ''
          cp.countryCode = company.countryCode ? company.countryCode : ''
          cp.id = companyRef.id
          this.company = cp
          this.company.isCallCenter = !!cp.isCallCenter
          this.company.isAgency = !!cp.isAgency
          this.companyName = companyRef.data().name

          if (!this.company.isCallCenter) {
            await this.getCustomerInfo()
          }

          if (!this.company.isAgency) {
            await this.getAgencyCustomerInfo()
          }
        }
      }

      this.isTabsLoaded = true

      this.$vs.loading.close()
    },

    async getCustomerInfo() {
      const getCallCenterListQuerySnapshot = await this.$db
        .collection('call-center-settings')
        .where('customerId', '==', this.activeUserInfo.company)
        .where('approved', '==', 'confirmed')
        .get()

      if (!getCallCenterListQuerySnapshot.empty) {
        this.isCallCenterList = true
      }
    },

    async getAgencyCustomerInfo() {
      const getAgencyListQuerySnapshot = await this.$db
        .collection('agency-settings')
        .where('customerId', '==', this.activeUserInfo.company)
        .where('approved', '==', 'confirmed')
        .get()

      if (!getAgencyListQuerySnapshot.empty) {
        this.isAgencyList = true
      }
    },

    getCookie(cname) {
      const name = `${cname}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    async onSkipCompanyRegistration() {
      const companyName = this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
      await this.$db.collection('company').doc(this.activeUserInfo.company).set({ name: companyName }, { merge: true })
      this.company.name = companyName
      this.companyName = this.company.name
      const company = { companyInfo: this.company }
      await this.$store.commit('UPDATE_USER_INFO', company)
      document.getElementById('pa-overlay').style.display = 'none'
      setTimeout(() => {
        this.$vs.loading.close('#company-details > .con-vs-loading')
      }, 100)
      setTimeout(() => {
        location.href = '/web-connect'
      }, 500)
    },
    async registerCompany() {
      const vm = this

      // If form is not validated or user is already login return
      if (!vm.validateForm) {
        return
      }
      vm.loading = true

      const user = vm.activeUserInfo
      vm.company.previewUrl = null
      //await firebase.functions().useFunctionsEmulator('http://localhost:5001')
      vm.$vs.loading({
        container: '#company-details',
        type: 'point',
        scale: 0.45
      })
      if (!vm.activeUserInfo.company) {
        const setCompanyClaim = vm.$functions.httpsCallable('setCompanyClaim')
        const affiliateId = vm.getCookie('afid')
        const couponId = vm.getCookie('cpid')
        const claim = {
          company: vm.company,
          user: {
            uid: user.uid
          }
        }
        if (affiliateId && affiliateId.trim().length > 0) {
          claim.afid = affiliateId
        }
        if (couponId && couponId.trim().length > 0) {
          claim.cpid = couponId
        }
        await setCompanyClaim(claim)
        const firebaseCurrentUser = firebase.auth().currentUser
        await firebaseCurrentUser.getIdToken(true)
        const idTokenResult = await firebaseCurrentUser.getIdTokenResult()
        if (idTokenResult && idTokenResult.claims.company) {
          this.$store.commit('UPDATE_USER_INFO', {
            company: idTokenResult.claims.company,
            companyInfo: vm.company
          })
        }
        vm.companyName = vm.company.name
        vm.showSetupMessage = true
        setTimeout(() => {
          vm.$vs.loading.close('#company-details > .con-vs-loading')
        }, 100)
        document.getElementById('pa-overlay').style.display = 'none'
        setTimeout(() => {
          vm.showSetupMessage = false
          if (!vm.dialogs || vm.dialogs.length === 0) {
            setTimeout(() => {
              location.href = '/web-connect'
            }, 500)
          }
        }, 4000)
      } else {
        await vm.$db.collection('company').doc(vm.activeUserInfo.company).set(vm.company, { merge: true })
        vm.companyName = vm.company.name
        const company = { companyInfo: vm.company }
        await this.$store.commit('UPDATE_USER_INFO', company)
        document.getElementById('pa-overlay').style.display = 'none'
        setTimeout(() => {
          vm.$vs.loading.close('#company-details > .con-vs-loading')
        }, 100)
        this.notifySuccess()
        if (vm.isRegistrationIncomplete) {
          vm.showSetupMessage = true
          setTimeout(() => {
            vm.showSetupMessage = false
            if (!vm.dialogs || vm.dialogs.length === 0) {
              setTimeout(() => {
                location.href = '/web-connect'
              }, 500)
            }
          }, 4000)
        } else {
          if (!vm.dialogs || vm.dialogs.length === 0) {
            setTimeout(() => {
              location.href = '/web-connect'
            }, 500)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.primary {
  color: rgba(var(--vs-primary), 1) !important;
}
</style>

<style lang="scss">
.custom-card {
  .multiselect__single {
    text-transform: capitalize;
  }
  .vs-card {
    &--header {
      padding: 1.5rem 1.5rem 0;
      box-shadow: none;
    }

    &--content {
      padding: 1.5rem;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin-right: 10px;
    }

    &__label {
      margin: 5px 0;
      color: rgb(18, 89, 141);
      font-size: 10px;
      font-weight: 600;
      line-height: 1.3;
    }
  }
}

.partner {
  color: white;
}

.font10 {
  font-size: 10px;
}
.dialog {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.rocket {
  position: absolute;
  top: 87px;
  left: 153px;
  transform: rotate(25deg);
}
.company-setup-popup .vs-popup {
  width: auto !important;
  height: auto !important;
}
.vs-popup--content {
  overflow: hidden;
}
.company-setup-popup .vs-popup--title h3 {
  color: #efa278;
}
.company-setup-popup .vs-popup--header {
  background: #fcf0e1 !important;
  box-shadow: 0 6px 15px -7px rgba(var(--vs-warning), 0.4);
}
.company-setup-popup .vs-popup--content {
  color: #efa278;
}
.subPopup .vs-popup--title h3 {
  color: #e8546f;
}
.subPopup .vs-popup--header {
  background: #f9e5e5 !important;
  box-shadow: 0 6px 15px -7px rgba(var(--vs-danger), 0.4);
}
.subPopup p {
  color: #e8546f !important;
}
.cs-zindex {
  z-index: 999999;
}
.vs-select--options {
  z-index: 9999999;
}
.con-vs-loading {
  width: 100%;
  height: 100%;
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(68, 66, 66, 0.4);
}

.con-box {
  width: calc(100% - 30px);
  margin-left: 15px;
}

.company-setup-tabs.vs-tabs {
  .vs-tabs--li {
    span {
      text-transform: capitalize;
    }
    .vs-tabs--btn {
      position: relative;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        position: absolute;
        left: 10px;
        display: flex;
        align-items: center;
        height: 14px;
        width: 14px;
        background-size: 100% 100%;
      }
    }
    // company
    &:first-child {
      .vs-tabs--btn {
        padding-left: 35px;
        &:before {
          top: 11px;
          background: url('./../assets/images/company/company.svg') center center no-repeat;
          background-size: 100% 100%;
        }
        &:hover:before {
          background: url('./../assets/images/company/company-filled.svg') center center no-repeat;
          background-size: 100% 100%;
        }
      }
      &.activeChild {
        .vs-tabs--btn {
          &:before {
            background: url('./../assets/images/company/company-filled.svg') center center no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    // team
    &:nth-child(2) {
      .vs-tabs--btn {
        padding-left: 35px;
        &:before {
          background: url('./../assets/images/company/team.svg') center center no-repeat;
          background-size: 100% 100%;
          width: 16px;
        }
        &:hover:before {
          background: url('./../assets/images/company/team-filled.svg') center center no-repeat;
          background-size: 100% 100%;
        }
      }
      &.activeChild {
        .vs-tabs--btn {
          &:before {
            background: url('./../assets/images/company/team-filled.svg') center center no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    // legal
    &:nth-child(3) {
      .vs-tabs--btn {
        padding-left: 35px;
        &:before {
          background: url('./../assets/images/company/legal.svg') center center no-repeat;
          background-size: 100% 100%;
          height: 13px;
        }
        &:hover:before {
          background: url('./../assets/images/company/legal-filled.svg') center center no-repeat;
          background-size: 100% 100%;
        }
      }
      &.activeChild {
        .vs-tabs--btn {
          &:before {
            background: url('./../assets/images/company/legal-filled.svg') center center no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
