<template>
  <div id="user-groups-view" class="user-groups-container">
    <user-group-sidebar :isSidebarActive="addGroup" @closeUserGroupsSidebar="toggleRulesDataSidebar" :id="id" />
    <div class="user-groups" :class="!HAS_ACCESS ? 'blur-background' : ''">
      <vx-card :title="$t('vue.userGroups')" title-color="primary" subtitle-color="danger" class="mb-4">
        <p class="mb-3">{{ $t('vue.userGroupsInfo') }}</p>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button :disabled="!HAS_ACCESS" color="primary" type="filled" icon-size="24px" icon="add" @click="addNewGroup"
              >{{ $t('vue.addNewGroup') }}
            </vs-button>
          </vs-row>
        </div>
      </vx-card>
      <vx-card v-if="user_groups && user_groups.length > 0">
        <vs-table ref="table" pagination :max-items="itemsPerPage" :data="user_groups" search :noDataText="$t('views.home.noDataAvailable')">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-end">
            <vs-dropdown vs-trigger-click class="cursor-pointer items-per-page-handle mr-4 mb-4">
              <div
                class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                style="border-radius: 5px"
              >
                <span class="mr-2">
                  {{ firstRecordNumber }} - {{ lastRecordNumber }} {{ $t('vue.of') }}
                  {{ queriedItems }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 3">
                  <span>3</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <template slot="thead">
            <vs-th sort-key="name" style="width: 40%">{{ $t('vue.nameOfGroup') }}</vs-th>
            <vs-th sort-key="users" style="width: 40%">{{ $t('vue.users') }}</vs-th>
            <vs-th style="width: 300px">{{ $t('vue.action') }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="tr.id" v-for="tr in data">
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.name }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ getUsersList(tr.users) }}
                  </p>
                </vs-td>
                <vs-td>
                  <div>
                    <vs-prompt
                      :title="$t('modals.deleteUserGroup')"
                      @cancel="onCloseModal"
                      @accept="deleteGroup"
                      @close="onCloseModal"
                      color="danger"
                      :cancel-text="$t('vue.cancel')"
                      :accept-text="$t('vue.delete')"
                      :active.sync="removeGroup"
                    >
                      <div class="con-exemple-prompt" v-if="selectedGroup">
                        <p>
                          <strong>{{ selectedGroup.name }}</strong>
                        </p>
                        {{ $t('modals.deleteUserGroupQuestion') }}
                      </div>
                    </vs-prompt>
                    <div class="flex" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
                      <vx-tooltip :text="$t('vue.edit')" position="top" class="ml-3">
                        <vs-button
                          :disabled="!HAS_ACCESS"
                          color="primary"
                          type="border"
                          size="medium"
                          icon-pack="feather"
                          icon="icon-edit"
                          @click="() => editGroup(tr.id)"
                        ></vs-button>
                      </vx-tooltip>
                      <vx-tooltip :text="$t('vue.delete')" position="top" class="ml-3">
                        <vs-button
                          :disabled="!HAS_ACCESS"
                          color="danger"
                          type="border"
                          size="medium"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="() => onDeleteClick(tr)"
                        ></vs-button>
                      </vx-tooltip>
                    </div>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <user-groups-message v-if="!HAS_ACCESS"></user-groups-message>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters } from 'vuex'
import UserGroupSidebar from './UserGroupSidebar.vue'
import UserGroupsMessage from '@/components/upgrade-messages/UserGroupsMessage.vue'

export default {
  components: {
    UserGroupSidebar,
    UserGroupsMessage
  },
  data() {
    return {
      itemsPerPage: 5,
      addGroup: false,
      removeGroup: false,
      selectedGroup: null,
      id: null
    }
  },
  computed: {
    ...mapGetters({
      user_groups: 'user_groups',
      users: 'users',
      company: 'company'
    }),
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('user-groups') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    firstRecordNumber() {
      let currentPage = 1
      if (this.$refs.table && this.$refs.table.currentx) {
        currentPage = this.$refs.table.currentx
      }
      if (this.queriedItems === 0) {
        return 0
      }

      const x = (currentPage - 1) * this.itemsPerPage
      return x + 1
    },
    lastRecordNumber() {
      const recordNumber = this.firstRecordNumber + this.itemsPerPage - 1
      return recordNumber < this.queriedItems ? recordNumber : this.queriedItems
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.user_groups.length
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    onCloseModal() {
      this.removeGroup = false
      this.selectedGroup = null
      this.id = null
    },
    onDeleteClick(tr) {
      if (!this.HAS_ACCESS) {
        return
      }
      this.removeGroup = true
      this.selectedGroup = tr
      this.id = tr.id
    },
    editGroup(id) {
      if (!this.HAS_ACCESS) {
        return
      }
      this.id = null
      setTimeout(() => {
        this.id = id
        this.addGroup = true
      }, 100)
    },
    async deleteGroup() {
      if (!this.HAS_ACCESS) {
        return
      }

      setTimeout(async () => {
        this.removeGroup = false
        await this.$db.collection('company').doc(this.activeUserInfo.company).collection('user-groups').doc(this.id).delete()
      }, 500)
    },
    getUsersList(ids) {
      if (ids && ids.length) {
        return ids
          .map((x) => {
            const users = this.users.filter((u) => u.id === x.id)
            return users.length > 0 ? users[0].name : ''
          })
          .join(' , ')
      }
      return null
    },
    toggleRulesDataSidebar(val = false) {
      this.addGroup = val
    },
    addNewGroup() {
      if (!this.HAS_ACCESS) {
        return
      }
      this.id = null
      this.addGroup = true
    }
  }
}
</script>
<style lang="scss">
.user-groups-container {
  min-height: 300px;
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.blur-background {
  filter: blur(3px);
}
</style>
