var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "add-new-data-sidebar items-no-padding",
      attrs: {
        spacer: "",
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _vm.isNewAccount
            ? _c("h4", [
                _vm._v(
                  _vm._s(_vm.$t("info.addSettingsForTheCompany").toUpperCase())
                ),
              ])
            : _c("h4", [
                _vm._v(_vm._s(_vm.$t("info.companySettings").toUpperCase())),
              ]),
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "row" } },
            [
              _vm.isNewAccount
                ? _c("vs-button", {
                    attrs: {
                      radius: "",
                      color: "primary",
                      size: "large",
                      type: "flat",
                      icon: "save",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.saveSettings.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "dark",
                  size: "large",
                  type: "flat",
                  icon: "close",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.isSidebarActiveLocal = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c("vx-card", { staticClass: "card-sidebar" }, [
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full" }, [
            _vm.isNewAccount
              ? _c("p", [
                  _vm._v(_vm._s(_vm.$t("info.setupCustomerPersonalDetails"))),
                ])
              : _c("p", [
                  _vm._v(_vm._s(_vm.$t("info.customerPersonalDetails"))),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("inputs.firstName")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  name: "firstName",
                  placeholder: _vm.$t("inputs.firstName"),
                  "data-vv-validate-on": "blur",
                  "data-vv-as": "name",
                  "validate-on": "blur",
                  disabled: !_vm.isNewAccount,
                },
                model: {
                  value: _vm.customer.firstname,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "firstname", $$v)
                  },
                  expression: "customer.firstname",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("firstName"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("inputs.lastName")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  name: "lastName",
                  placeholder: _vm.$t("inputs.lastName"),
                  "data-vv-validate-on": "blur",
                  "data-vv-as": "lastname",
                  "validate-on": "blur",
                  disabled: !_vm.isNewAccount,
                },
                model: {
                  value: _vm.customer.lastname,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "lastname", $$v)
                  },
                  expression: "customer.lastname",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("lastName"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("inputs.email")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  name: "email",
                  type: "email",
                  placeholder: _vm.$t("inputs.email"),
                  "data-vv-validate-on": "blur",
                  "data-vv-as": "email",
                  "validate-on": "blur",
                  disabled: !_vm.isNewAccount,
                },
                model: {
                  value: _vm.customer.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "email", $$v)
                  },
                  expression: "customer.email",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("email"))),
              ]),
            ],
            1
          ),
        ]),
        _vm.isNewAccount
          ? _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "ma-0 pt-0 pb-1",
                      attrs: { "hide-details": "" },
                      model: {
                        value: _vm.isSendingSingLink,
                        callback: function ($$v) {
                          _vm.isSendingSingLink = $$v
                        },
                        expression: "isSendingSingLink",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "vue.sendSignInLinkToTheCustomersEmailAddress"
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full" }, [
            _vm.isNewAccount
              ? _c("p", [
                  _vm._v(_vm._s(_vm.$t("info.setupCustomerCompanyDetails"))),
                ])
              : _c("p", [
                  _vm._v(_vm._s(_vm.$t("info.customerCompanyDetails"))),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.companyName")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:3",
                    expression: "'required|min:3'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  placeholder: _vm.$t("vue.myAwesomeWebsite"),
                  id: "companySetup_WebsiteName",
                  name: "name",
                  "data-vv-as": "name",
                  "validate-on": "blur",
                  disabled: !_vm.isNewAccount,
                },
                model: {
                  value: _vm.company.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "name", $$v)
                  },
                  expression: "company.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.street")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-1/3 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { disabled: !_vm.isNewAccount },
                model: {
                  value: _vm.company.street,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "street", $$v)
                  },
                  expression: "company.street",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", { staticClass: "w-full" }, [
              _vm._v(_vm._s(_vm.$t("vue.houseNumber"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-1/3 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { disabled: !_vm.isNewAccount },
                model: {
                  value: _vm.company.houseNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "houseNumber", $$v)
                  },
                  expression: "company.houseNumber",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.zipcode")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-1/3 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  name: "zipcode",
                  "data-vv-as": "zipcode",
                  "validation-on": "change",
                  disabled: !_vm.isNewAccount,
                },
                model: {
                  value: _vm.company.zipcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "zipcode", $$v)
                  },
                  expression: "company.zipcode",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("zipcode"))),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.city")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-1/3 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { disabled: !_vm.isNewAccount },
                model: {
                  value: _vm.company.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "city", $$v)
                  },
                  expression: "company.city",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.country")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c(
                "vs-select",
                {
                  staticClass: "w-full",
                  attrs: {
                    autocomplete: "",
                    color: "grey",
                    disabled: !_vm.isNewAccount,
                  },
                  model: {
                    value: _vm.company.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.company, "country", $$v)
                    },
                    expression: "company.country",
                  },
                },
                _vm._l(_vm.countryCodes, function (country) {
                  return _c("vs-select-item", {
                    key: country.code,
                    attrs: {
                      value: country.countryName,
                      text: country.countryName,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.phone")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  placeholder: "+1 656 234242",
                  name: "phone",
                  "data-vv-as": "phone",
                  "validation-on": "change",
                  disabled: !_vm.isNewAccount,
                },
                model: {
                  value: _vm.company.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "phone", $$v)
                  },
                  expression: "company.phone",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("phone"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.vatNumber")))]),
            _c("div", { staticClass: "font10" }, [
              _vm._v("(" + _vm._s(_vm.$t("vue.optional")) + ")"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { disabled: !_vm.isNewAccount },
                model: {
                  value: _vm.company.vatNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "vatNumber", $$v)
                  },
                  expression: "company.vatNumber",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/6 w-full" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.website")))]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-5/6 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "isURLValid",
                    expression: "'isURLValid'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  placeholder: "https://www.mycompanyname",
                  name: "url",
                  "data-vv-as": "url",
                  "validate-on": "blur",
                  disabled: !_vm.isNewAccount,
                },
                model: {
                  value: _vm.company.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "url", $$v)
                  },
                  expression: "company.url",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("url"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }