var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _c("Avatar", {
        staticClass: "mr-3",
        attrs: {
          size: "35px",
          photoURL: _vm.params.data.image,
          name: _vm.params.data.name,
        },
      }),
      _vm._v("\n\n  " + _vm._s(_vm.params.data.name) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }