var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "api-key-container", attrs: { id: "apikey-view" } },
    [
      _c(
        "vx-card",
        { class: !_vm.HAS_ACCESS ? "blur-background" : "" },
        [
          _c(
            "vs-row",
            { staticClass: "mb-5", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "start",
                    "vs-align": "center",
                  },
                },
                [_c("h2", [_vm._v("Zapier API Key")])]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "5",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c("vs-input", {
                    staticClass: "api-key w-full",
                    attrs: {
                      disabled: !_vm.HAS_ACCESS,
                      placeholder: "API Key",
                      readonly: true,
                    },
                    model: {
                      value: _vm.zapierKey,
                      callback: function ($$v) {
                        _vm.zapierKey = $$v
                      },
                      expression: "zapierKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "3",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        disabled: !_vm.HAS_ACCESS,
                        color: "primary",
                        type: "filled",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.generateAPIKey("zapier")
                        },
                      },
                    },
                    [_vm._v("Generate New Api Key")]
                  ),
                ],
                1
              ),
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "1",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        disabled: !_vm.HAS_ACCESS,
                        color: "primary",
                        type: "filled",
                        icon: "save",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveAPIKey("zapier")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.HAS_ACCESS ? _c("api-keys-message") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }