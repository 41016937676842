<template>
  <div class="api-key-container" id="apikey-view">
    <vx-card :class="!HAS_ACCESS ? 'blur-background' : ''">
      <vs-row class="mt-5" vs-w="12">
        <vs-col vs-type="flex" class="mr-2 mb-2" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <div class="flex flex-col w-full">
            <h4>{{ $t('vue.webHooks.newBusinessVisitor') }}</h4>
            <vs-input
              :disabled="!HAS_ACCESS"
              placeholder="URL"
              class="w-full"
              v-model="webHooks.newBusinessVisitor"
              name="newBusinessVisitor"
              v-validate="'isURLValid'"
            />
            <label>{{ $t('vue.webHooks.newBusinessVisitorInfo') }}</label>
            <span class="text-danger text-sm">{{ errors.first('newBusinessVisitor') }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5" vs-w="12">
        <vs-col vs-type="flex" class="mr-2 mb-2" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <div class="flex flex-col w-full">
            <h4>{{ $t('vue.webHooks.newVisitorMessage') }}</h4>
            <vs-input
              :disabled="!HAS_ACCESS"
              placeholder="URL"
              class="w-full"
              v-model="webHooks.newVisitorMessage"
              name="newVisitorMessage"
              v-validate="'isURLValid'"
            />
            <label>{{ $t('vue.webHooks.newVisitorMessageInfo') }}</label>
            <span class="text-danger text-sm">{{ errors.first('newVisitorMessage') }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5" vs-w="12">
        <vs-col vs-type="flex" class="mr-2 mb-2" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <div class="flex flex-col w-full">
            <h4>{{ $t('vue.webHooks.newConversationEnded') }}</h4>
            <vs-input
              :disabled="!HAS_ACCESS"
              placeholder="URL"
              class="w-full"
              name="newConversationEnded"
              v-validate="'isURLValid'"
              v-model="webHooks.newConversationEnded"
            />
            <label>{{ $t('vue.webHooks.newConversationEndedInfo') }}</label>
            <span class="text-danger text-sm">{{ errors.first('newConversationEnded') }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5" vs-w="12">
        <vs-col vs-type="flex" class="mr-2 mb-2" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <div class="flex flex-col w-full">
            <h4>{{ $t('vue.webHooks.newLead') }}</h4>
            <vs-input :disabled="!HAS_ACCESS" placeholder="URL" class="w-full" v-model="webHooks.newLead" name="newLead" v-validate="'isURLValid'" />
            <label>{{ $t('vue.webHooks.newLeadInfo') }}</label>
            <span class="text-danger text-sm">{{ errors.first('newLead') }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5" vs-w="12">
        <vs-col vs-type="flex" class="mr-2 mb-2" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <div class="flex flex-col w-full">
            <h4>{{ $t('vue.webHooks.newMeeting') }}</h4>
            <vs-input :disabled="!HAS_ACCESS" placeholder="URL" class="w-full" v-model="webHooks.newMeeting" name="newMeeting" v-validate="'isURLValid'" />
            <label>{{ $t('vue.webHooks.newMeetingInfo') }}</label>
            <span class="text-danger text-sm">{{ errors.first('newMeeting') }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5" vs-w="12">
        <vs-col vs-type="flex" class="mr-2 mb-2" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <div class="flex flex-col w-full">
            <h4>{{ $t('vue.webHooks.visitorScoreInfo') }}</h4>
            <vs-input
              :disabled="!HAS_ACCESS"
              placeholder="URL"
              class="w-full"
              v-model="webHooks.visitorScoreUpdate"
              name="visitorScoreUpdate"
              v-validate="'isURLValid'"
            />
            <label>{{ $t('vue.webHooks.visitorScoreInfoInfo') }}</label>
            <span class="text-danger text-sm">{{ errors.first('visitorScoreUpdate') }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5" vs-w="12">
        <vs-col vs-type="flex" class="mr-2 mb-2" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <div class="flex flex-col w-full">
            <h4>{{ $t('vue.webHooks.returningVisitor') }}</h4>
            <vs-input
              :disabled="!HAS_ACCESS"
              placeholder="URL"
              class="w-full"
              v-model="webHooks.returningVisitor"
              name="returningVisitor"
              v-validate="'isURLValid'"
            />
            <label>{{ $t('vue.webHooks.returningVisitorInfo') }}</label>
            <span class="text-danger text-sm">{{ errors.first('returningVisitor') }}</span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row vs-w="12">
        <vs-col class="mr-2 mb-2" vs-type="flex" vs-justify="center" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11">
          <vs-button :disabled="!HAS_ACCESS" color="primary" class="w-full" type="filled" icon="save" @click="saveWebhooks">
            {{ $t('vue.save') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>

    <webhooks-message v-if="!HAS_ACCESS"></webhooks-message>
  </div>
</template>

<script>
import { Validator } from 'vee-validate'
import validationDictionary from '@/validations/validationDictionary'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)
import { mapGetters } from 'vuex'
import WebhooksMessage from '@/components/upgrade-messages/WebhooksMessage.vue'
export default {
  components: {
    WebhooksMessage
  },

  data: () => ({
    zapierKey: '',
    webHooks: {
      newBusinessVisitor: null,
      newConversationEnded: null,
      newLead: null,
      newMeeting: null
    }
  }),

  async mounted() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])

    await this.$vs.loading()

    if (this.company && this.company.webHooks) {
      this.webHooks = this.company.webHooks
    }
    await this.$vs.loading.close()
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company'
    }),
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('api-keys') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    }
  },

  methods: {
    async saveWebhooks() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      const isValidated = await this.$validator.validateAll()
      if (!isValidated) return

      await this.$db.collection('company').doc(this.activeUserInfo.company).set({ webHooks: this.webHooks }, { merge: true })

      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.changesSavedSuccessfully'),
        iconPack: 'feather',
        icon: 'icon-check-circle',
        color: 'success',
        position: 'bottom-right'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.blur-background {
  filter: blur(3px);
}

.api-key-container {
  min-height: 300px;

  .api-key {
    .vs-con-input {
      .vs-input--input.normal {
        font-size: 1.1rem !important;
      }
    }
  }
}
</style>
