<template>
  <div class="flex">
    <Avatar
      size="35px"
      class="mr-3"
      :photoURL="params.data.image"
      :name="params.data.name"
    />

    {{ params.data.name }}
  </div>
</template>

<script>
import Avatar from '@/components/Avatar'

export default {
  components: {
    Avatar
  }
}
</script>
