<template>
  <vs-input
    class="w-full mt-1"
    name="ringingDuration"
    type="number"
    v-model="ringingDuration"
    v-validate="'required|numeric'"
    @input="inputRingingDuration"
  />
</template>

<script>
export default {
  data() {
    return {
      id: null,
      ringingDuration: ''
    }
  },

  mounted() {
    this.id = this.params.data.id
    this.ringingDuration = this.params.data.ringingDuration
  },

  methods: {
    inputRingingDuration() {
      this.$serverBus.$emit('on-input-ringing-duration', {
        id: this.id,
        ringingDuration: this.ringingDuration
      })
    }
  }
}
</script>
