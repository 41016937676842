<template>
  <vs-sidebar
    spacer
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4 v-if="isCallCenter">{{ $t('info.addSettingsForTheCompany').toUpperCase() }}</h4>
      <h4 v-else>{{ $t('info.callCenter').toUpperCase() }}</h4>

      <div style="display: flex; flex-direction: row">
        <vs-button v-if="isCallCenter" radius color="primary" size="large" type="flat" icon="save" @click.stop="saveSettings" />

        <vs-button radius color="dark" size="large" type="flat" icon="close" @click.stop="isSidebarActiveLocal = false" />
      </div>
    </div>

    <vs-divider class="mb-0" />

    <connect-modal v-if="isCallCenter" bkcolor="#F7F7F7" :show="timeRangeDialog" :width="'460px'" :height="'300px'">
      <div class="flex flex-row visitor-list-delete-all">
        <div>
          <h3>{{ $t('inputs.selectTimeRange') }}</h3>
        </div>
        <div disabled style="margin-left: auto; cursor: pointer" @click="timeRangeDialog = false">
          <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
        </div>
      </div>
      <div class="flex flex-wrap justify-center" style="height: 200px">
        <!-- 12-hour format, with AM/PM picker -->
        <vs-row w="12">
          <vs-col w="12">
            <label class="text-sm">{{ $t('inputs.start') }}</label>
          </vs-col>
        </vs-row>

        <vue-timepicker
          close-on-complete
          class="w-full mb-base"
          format="HH:mm"
          :minute-interval="minuteInterval"
          v-model="start"
          @change="timeChange"
          fixed-dropdown-button
          drop-direction="down"
        />

        <vs-row w="12">
          <vs-col w="12">
            <label class="text-sm">{{ $t('inputs.end') }}</label>
          </vs-col>
        </vs-row>

        <vue-timepicker
          close-on-complete
          drop-direction="up"
          fixed-dropdown-button
          hide-disabled-items
          class="w-full mb-base"
          format="HH:mm"
          :hour-range="hourRange"
          :minute-range="minuteRange"
          :minute-interval="minuteInterval"
          v-model="end"
          @change="timeChange"
          @error="errorHanlder"
        />

        <vs-button class="w-full mb-base" color="primary" type="filled" icon="add" :disabled="hasError" @click.prevent="addUpdateTimeRange">
          {{ $t('vue.addTimeRange') }}
        </vs-button>
      </div>
    </connect-modal>

    <vx-card class="card-sidebar">
      <p v-if="!isCallCenter">{{ $t('vue.waitingTimeInSeconds') }}:</p>
      <vs-input
        name="waitingTime"
        class="w-full mt-4 mb-2"
        :class="{
          'mt-6': isCallCenter
        }"
        :label-placeholder="isCallCenter ? $t('vue.waitingTimeInSeconds') : ''"
        :data-vv-as="isCallCenter ? $t('vue.waitingTimeInSeconds') : ''"
        :disabled="!isCallCenter"
        v-model="waitingTime"
        type="Number"
        v-validate="'required|greaterThanEqualsZero'"
      />

      <span class="text-danger text-sm">{{ errors.first('waitingTime') }}</span>

      <template v-if="isCallCenter">
        <div class="mt-6">
          <vs-input name="statusName" class="w-full mt-4 mb-2" :label-placeholder="'Status name'" v-model="statusName" />

          <p class="mt-5">{{ $t('vue.statusType') }}:</p>

          <multiselect
            v-model="statusType"
            label="type"
            track-by="type"
            :options="statusColors"
            :multiple="false"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('vue.pickOne')"
            :selectLabel="$t('vue.pressEnterToSelect')"
            :selectedLabel="$t('vue.selectedLabel')"
            :deselectLabel="$t('vue.pressEnterToRemove')"
            :preselect-first="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.type }}
            </template>
          </multiselect>

          <vs-button size="medium" color="primary" class="mt-2 btn-main btn-add" :disabled="!statusName || !statusType" @click="saveStatus">
            <span class="btn-add__label">{{ $t('inputs.add') }}</span>
          </vs-button>

          <div class="mt-5">
            <p>{{ $t('vue.statuses') }}:</p>
          </div>

          <div v-if="statusList.length" class="flex flex-wrap">
            <vs-chip
              v-for="status in statusList"
              closable
              class="mt-4 mr-2 float-none"
              :key="status.name"
              :color="getStatusColor(status.type)"
              @click="removeStatus(status.name)"
            >
              {{ status.name }}
            </vs-chip>
          </div>

          <p v-else class="mt-4">You have not created any status</p>
        </div>
      </template>

      <div class="mt-5">
        <vs-checkbox
          hide-details
          class="ma-0 pt-0 pb-1 checkbox-main"
          :disabled="!isCallCenter"
          v-model="isAgentsPrioritization"
          @change="acceptAgentsPrioritization"
        >
          Agents prioritization
        </vs-checkbox>
      </div>

      <div class="mt-5">
        <p>{{ $t('vue.agents') }}:</p>

        <template v-if="isCallCenter">
          <multiselect
            v-model="selectedAgents"
            label="name"
            track-by="name"
            :options="modifiedUsers"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('vue.pickSome')"
            :selectLabel="$t('vue.pressEnterToSelect')"
            :selectedLabel="$t('vue.selectedLabel')"
            :deselectLabel="$t('vue.pressEnterToRemove')"
            :preselect-first="false"
            @input="updateSelectedAgents"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span v-if="values.length && !isOpen" class="multiselect__single">
                {{ values.length }} {{ values.length > 1 ? $t('vue.usersSelected') : $t('vue.userSelected') }}
              </span>
            </template>
          </multiselect>

          <TableSelectedAgents
            v-if="isAgentsPrioritization && selectedAgents && selectedAgents.length > 0"
            class="users-list"
            :selectedAgents="sortedSelectedAgents"
          />

          <div v-else-if="!isAgentsPrioritization" class="users-list">
            <div v-for="user in selectedAgents" class="users-list__item" :key="user.id">
              <div class="users-list__item__main-info">
                <Avatar size="45px" class="users-list__item__avatar" :photoURL="user.photoURL || user.image" :name="user.displayName || user.name" />

                <div class="users-list__item__name">{{ user.displayName || user.name }}</div>
              </div>
            </div>
          </div>
        </template>

        <div v-else class="users-list">
          <div v-for="user in sortedSelectedAgents" class="users-list__item" :key="user.id">
            <div class="users-list__item__main-info">
              <Avatar size="45px" class="users-list__item__avatar" :photoURL="user.photoURL || user.image" :name="user.displayName || user.name" />
              <div class="users-list__item__name">{{ user.displayName || user.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <p>{{ $t('vue.acceptanceTimes') }}:</p>

        <div v-if="isCallCenter" class="mt-2 flex flex-wrap items-center mb-base">
          <vs-row v-for="(slot, d) in availableTimeSlots" :key="d">
            <div class="vx-row slot-wrapper">
              <div class="vx-col w-full">
                <vs-checkbox hide-details class="ma-0 pt-0 pb-1 checkbox-main" v-model="slot.enabled" @change="slotUpdated(slot, d)">
                  {{ $t(modifyDayNameToLong(slot.day)) }}
                </vs-checkbox>

                <div v-if="slot.enabled" class="slot-details">
                  <div v-for="(time, t) in slot.times" class="slot-item" :key="t">
                    <div class="slot-item__time">{{ format12HrTime(time.start) }} - {{ format12HrTime(time.end) }}</div>

                    <div class="slot-item__actions">
                      <button class="flex border-none cursor-pointer" @click.prevent="editTime(d, t, time)">
                        <PenEditIcon />
                      </button>

                      <button class="flex border-none cursor-pointer" @click.prevent="deleteTime(t, slot)">
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>

                  <vs-button size="medium" color="primary" class="btn-main btn-add" @click="addTime(d)">
                    <span class="btn-add__label">{{ $t('inputs.add') }}</span>
                  </vs-button>
                </div>
              </div>
            </div>
          </vs-row>
        </div>

        <div v-else>
          <div
            v-if="companySettings && companySettings.acceptanceTimes && companySettings.acceptanceTimes.findIndex((time) => time.enabled) > -1"
            class="users-list__item__slots-list"
          >
            <div v-for="(slot, d) in getFilteredAvailableTimeSlots(companySettings.acceptanceTimes)" class="users-list__item__slots-list__slot" :key="d">
              <vs-icon size="17px" icon="check" class="users-list__item__slots-list__slot__icon" />

              <div class="mb-2 users-list__item__slots-list__slot__day">
                {{ $t(modifyDayNameToLong(slot.day)) }}
              </div>

              <div v-for="(time, t) in slot.times" class="mt-1" :key="t">
                <div>{{ format12HrTime(time.start).toLowerCase() }} - {{ format12HrTime(time.end).toLowerCase() }}</div>
              </div>
            </div>
          </div>

          <div v-else class="mt-2">
            {{ $t('vue.timeSlotsHaveNotYetBeenSelected') }}
          </div>
        </div>
      </div>
    </vx-card>
  </vs-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

import ConnectModal from '@/components/ConnectModal.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'
import PenEditIcon from '@/components/icons/PenEditIcon.vue'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import Avatar from '@/components/Avatar'

import TableSelectedAgents from './TableSelectedAgents'

export default {
  components: {
    VueTimepicker,
    Multiselect,
    ConnectModal,
    CloseCircleIcon,
    PenEditIcon,
    DeleteIcon,
    Avatar,
    TableSelectedAgents
  },

  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },

    appActiveUser: {
      type: Object,
      required: true
    },

    isCallCenter: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      companySettings: null,

      statusColors: [
        {
          type: 'positive',
          color: 'rgb(40, 199, 111)'
        },
        {
          type: 'neutral',
          color: 'rgb(245, 220, 0)'
        },
        {
          type: 'negative',
          color: 'rgb(234, 84, 85)'
        }
      ],
      statusName: '',
      statusType: {},
      statusList: [],

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

      waitingTime: 0,
      isAgentsPrioritization: false,
      selectedAgents: [],

      timeRangeDialog: false,
      timeRangeDialogType: null,
      start: {
        HH: '00',
        mm: '00'
      },
      end: {
        HH: '00',
        mm: '00'
      },
      minuteInterval: 10,
      selectedDay: null,
      selectedTime: null,
      minuteRange: [[0, 60]],
      hasError: true,

      previousTimeslots: [],
      availableTimeSlots: [
        {
          day: 'system.weekdays.short.mon',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.tue',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.wed',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.thu',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.fri',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.sat',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.sun',
          enabled: false,
          times: []
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      users: 'users',
      company: 'company'
    }),

    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },

      set(val) {
        if (!val) {
          this.$emit('closeAppointmentsSidebar')
        }
      }
    },

    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },

    filteredUsers() {
      if (this.users && this.company && this.company.id) {
        return this.users.filter((user) => user.company === this.company.id)
      }

      return []
    },

    modifiedUsers() {
      if (!this.isAgentsPrioritization) {
        return this.filteredUsers
      }

      return this.filteredUsers.map((user) => {
        return {
          ...user,
          priority: '',
          ringingDuration: ''
        }
      })
    },

    hourRange() {
      const startHour = parseInt(this.start.HH)

      return startHour === 23 ? [23] : [[startHour, 23]]
    },

    sortedSelectedAgents() {
      return _.sortBy(this.selectedAgents, 'priority')
    }
  },

  created() {
    this.$serverBus.$on('company-settings-data', this.setInitialData)
    this.$serverBus.$on('on-row-drag-end', this.onRowDragEnd)
    this.$serverBus.$on('move-rule-up', this.moveRuleUp)
    this.$serverBus.$on('move-rule-down', this.moveRuleDown)
    this.$serverBus.$on('on-input-ringing-duration', this.onInputRingingDuration)
  },

  beforeDestroy() {
    this.$serverBus.$off('company-settings-data')
    this.$serverBus.$off('on-row-drag-end')
    this.$serverBus.$off('move-rule-up')
    this.$serverBus.$off('move-rule-down')
    this.$serverBus.$off('on-input-ringing-duration')
  },

  methods: {
    async saveSettings() {
      const result = await this.$validator.validate()

      if (!result) {
        return
      }

      await this.$vs.loading()

      this.availableTimeSlots = this.availableTimeSlots.map((x) => {
        return {
          enabled: x.times.length > 0,
          times: x.times,
          day: x.day
        }
      })

      const timeslots = _.cloneDeep([...this.availableTimeSlots])

      this.previousTimeslots = [...timeslots]

      const data = {
        waitingTime: Number(this.waitingTime),
        agents: this.modifyUsers(this.selectedAgents),
        acceptanceTimes: timeslots,
        modified: new Date(),
        modifiedBy: this.appActiveUser.uid,
        isAgentsPrioritization: this.isAgentsPrioritization,
        statusList: this.statusList,
        callCenterName: this.company && this.company.name ? this.company.name : ''
      }

      await this.$db.collection('call-center-settings').doc(this.companySettings.uidCallCenterSettings).set(data, { merge: true })

      await this.$vs.loading.close()

      this.isSidebarActiveLocal = false
    },

    async setInitialData(data) {
      await this.$vs.loading()

      this.companySettings = data

      if (!data) {
        await this.$vs.loading.close()

        return
      }

      if (this.isCallCenter) {
        this.statusList = this.companySettings.statusList ? [...this.companySettings.statusList] : []

        this.getAvailabilityForCompany(this.companySettings.acceptanceTimes || [])
      }

      this.waitingTime = this.companySettings.waitingTime || 0

      this.isAgentsPrioritization = this.companySettings.isAgentsPrioritization || false

      if (!this.companySettings.agents) {
        this.selectedAgents = []

        await this.$vs.loading.close()

        return
      }

      if (this.isCallCenter) {
        const filteredUsers = this.users.filter((user) => this.companySettings.agents.findIndex((agent) => agent.id === user.id) > -1)

        this.selectedAgents = filteredUsers.map((user) => {
          const userCallCenterSettings = this.companySettings.agents.find((agent) => agent.id === user.id)

          return {
            ...user,
            ...userCallCenterSettings
          }
        })
      } else {
        this.selectedAgents = []

        await Promise.all(
          this.companySettings.agents.map(async (agent) => {
            const userRef = await this.$db.collection('users').doc(agent.id).get()

            const userData = {
              ...userRef.data(),
              id: agent.id,
              priority: agent.priority || 0
            }

            this.selectedAgents.push(userData)
          })
        )
      }

      await this.$vs.loading.close()
    },

    saveStatus() {
      this.statusList.push({
        name: this.statusName,
        type: this.statusType.type
      })

      this.statusName = ''
      this.statusType = {}
    },

    removeStatus(name) {
      const index = this.statusList.findIndex((status) => status.name === name)

      this.statusList.splice(index, 1)
    },

    modifyUsers(users) {
      return users.map((user) => {
        return {
          id: user.id,
          priority: this.isAgentsPrioritization ? user.priority : '',
          ringingDuration: this.isAgentsPrioritization ? user.ringingDuration : ''
        }
      })
    },

    getStatusColor(type) {
      const status = this.statusColors.find((status) => status.type === type)

      if (!status || !status.color) {
        return ''
      }

      return status.color
    },

    onInputRingingDuration(obj) {
      this.selectedAgents.forEach((agent) => {
        if (agent.id === obj.id) {
          agent.ringingDuration = obj.ringingDuration
        }
      })
    },

    acceptAgentsPrioritization() {
      this.selectedAgents = this.selectedAgents.map((agent, index) => {
        return {
          ...agent,
          priority: index
        }
      })
    },

    updateSelectedAgents() {
      this.selectedAgents = this.selectedAgents.map((agent, index) => {
        return {
          ...agent,
          priority: index
        }
      })
    },

    onRowDragEnd(obj) {
      const replaceNode = { ...this.selectedAgents.find((agent) => agent.priority === obj.overIndex) }
      const changedNode = { ...this.selectedAgents.find((agent) => agent.id === obj.id) }
      const newIndex = obj.overIndex
      const oldIndex = obj.rowIndex

      if (!(replaceNode && replaceNode.id && changedNode && changedNode.id)) {
        return
      }

      this.selectedAgents = this.selectedAgents.map((agent) => {
        if (agent.id === changedNode.id) {
          return {
            ...agent,
            priority: newIndex
          }
        } else if (agent.id === replaceNode.id) {
          return {
            ...agent,
            priority: oldIndex
          }
        }

        return agent
      })
    },

    moveRuleUp(obj) {
      const nodeMoveUp = this.selectedAgents.find((agent) => agent.id === obj.id)
      const nodeMoveDown = this.selectedAgents.find((agent) => agent.priority === obj.newIndex)
      const upIndex = obj.newIndex
      const downIndex = obj.newIndex + 1

      if (!(nodeMoveUp && nodeMoveUp.id && nodeMoveDown && nodeMoveDown.id)) {
        return
      }

      this.selectedAgents = this.selectedAgents.map((agent) => {
        if (agent.id === nodeMoveUp.id) {
          return {
            ...agent,
            priority: upIndex
          }
        } else if (agent.id === nodeMoveDown.id) {
          return {
            ...agent,
            priority: downIndex
          }
        }

        return agent
      })
    },

    moveRuleDown(obj) {
      const nodeMoveDown = this.selectedAgents.find((agent) => agent.id === obj.id)
      const nodeMoveUp = this.selectedAgents.find((agent) => agent.priority === obj.newIndex)
      const downIndex = obj.newIndex
      const upIndex = obj.newIndex - 1

      if (!(nodeMoveUp && nodeMoveUp.id && nodeMoveDown && nodeMoveDown.id)) {
        return
      }

      this.selectedAgents = this.selectedAgents.map((agent) => {
        if (agent.id === nodeMoveUp.id) {
          return {
            ...agent,
            priority: upIndex
          }
        } else if (agent.id === nodeMoveDown.id) {
          return {
            ...agent,
            priority: downIndex
          }
        }

        return agent
      })
    },

    getAvailabilityForCompany(availableTimeSlots) {
      if (!(availableTimeSlots && availableTimeSlots.length > 0)) {
        this.availableTimeSlots = [
          {
            day: 'system.weekdays.short.mon',
            enabled: false,
            times: []
          },
          {
            day: 'system.weekdays.short.tue',
            enabled: false,
            times: []
          },
          {
            day: 'system.weekdays.short.wed',
            enabled: false,
            times: []
          },
          {
            day: 'system.weekdays.short.thu',
            enabled: false,
            times: []
          },
          {
            day: 'system.weekdays.short.fri',
            enabled: false,
            times: []
          },
          {
            day: 'system.weekdays.short.sat',
            enabled: false,
            times: []
          },
          {
            day: 'system.weekdays.short.sun',
            enabled: false,
            times: []
          }
        ]
        this.previousTimeslots = []

        return
      }

      this.availableTimeSlots = [...[]]
      this.previousTimeslots = [...[]]

      this.availableTimeSlots = _.cloneDeep([...availableTimeSlots])
      this.previousTimeslots = _.cloneDeep(this.availableTimeSlots)
    },

    errorHanlder(e) {
      this.hasError = e.length > 0
    },

    timeChange() {
      const startHour = parseInt(this.start.HH)
      const startMinute = parseInt(this.start.mm)
      const endHour = parseInt(this.end.HH)

      let minuteRange = []

      if (startHour === endHour) {
        const endMinute = startMinute + this.minuteInterval

        minuteRange = endMinute > 60 - this.minuteInterval ? [] : [[endMinute, 60]]
      } else {
        minuteRange = [[0, 60]]
      }

      this.minuteRange = minuteRange
    },

    addUpdateTimeRange() {
      if (this.timeRangeDialogType === 'system.add') {
        if (this.start !== null && this.end !== null) {
          this.availableTimeSlots[this.selectedDay].times.push({
            start: `${this.start.HH}:${this.start.mm}`,
            end: `${this.end.HH}:${this.end.mm}`
          })
        }
      }

      if (this.timeRangeDialogType === 'system.save') {
        if (this.start !== null && this.end !== null) {
          this.availableTimeSlots[this.selectedDay].times[this.selectedTime] = {
            start: `${this.start.HH}:${this.start.mm}`,
            end: `${this.end.HH}:${this.end.mm}`
          }
        }
      }

      this.start = {
        HH: '00',
        mm: '00'
      }

      this.end = {
        HH: '00',
        mm: '00'
      }

      this.selectedDay = null
      this.selectedTime = null
      this.timeRangeDialog = false
    },

    slotUpdated(slot) {
      if (!slot.enabled) {
        slot.times = []
      }
    },

    addTime(i) {
      this.selectedDay = i
      this.timeRangeDialog = true
      this.timeRangeDialogType = 'system.add'
    },

    editTime(d, t, time) {
      this.selectedDay = d
      this.selectedTime = t

      if (time.start && !time.start.HH && time.start.indexOf(':') > -1) {
        const start = {
          HH: time.start.split(':')[0],
          mm: time.start.split(':')[1]
        }

        this.start = start
      } else {
        this.start = time.start
      }

      if (time.end && !time.end.HH && time.end.indexOf(':') > -1) {
        const end = {
          HH: time.end.split(':')[0],
          mm: time.end.split(':')[1]
        }

        this.end = end
      } else {
        this.end = time.end
      }

      this.timeRangeDialog = true
      this.timeRangeDialogType = 'system.save'
    },

    deleteTime(i, slot) {
      slot.times.splice(i, 1)
    },

    format12HrTime(time24hr) {
      return moment(time24hr, 'HH:mm').locale(this.$i18n.locale).format('LT')
    },

    modifyDayNameToLong(value) {
      if (!value || !(typeof value === 'string' || value instanceof String)) {
        return value
      }

      return value.replace('short', 'long')
    },

    getFilteredAvailableTimeSlots(slots) {
      return slots ? slots.filter((slot) => slot.enabled) : []
    }
  }
}
</script>

<style lang="scss" scoped>
.card-sidebar {
  height: calc(100% - 80px);
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgb(171, 171, 171);
    border-radius: 6px;
  }
}

.header-text {
  font-weight: bold;
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}

.slot {
  &-wrapper {
    margin-bottom: 15px;
  }

  &-details {
    @media screen and (min-width: 768px) {
      margin-left: 48px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background: rgba(230, 236, 241, 0.5);
    border-radius: 6px;

    &__time {
      width: 175px;
    }

    &__actions {
      display: flex;

      & > * {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.pa-bk-secondary {
  background: rgba(var(--vs-secondary), 1) !important;
  border-radius: 10px;
  color: #fff !important;
}

.add-new-data-sidebar {
  .vs-sidebar {
    max-width: 600px !important;
  }
}

.vue__time-picker {
  .dropdown {
    ul {
      li:not([disabled]) {
        &.active,
        &.active:focus,
        &.active:hover {
          color: rgb(255, 255, 255);
          background: rgba(var(--vs-primary), 1);
        }
      }
    }
  }
}

.vs-popup {
  width: 460px !important;
}

.vue__time-picker {
  input.display-time {
    width: 100%;
  }
}

.btn {
  &-main {
    font-size: 16px;
  }

  &-add {
    &__label {
      position: relative;
      margin-left: 35px;
      margin-right: 35px;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        height: 1.5px;
        background: rgb(255, 255, 255);
      }

      &::before {
        left: -22px;
        width: 13px;
      }

      &::after {
        left: -21px;
        width: 12px;
        transform: rotate(90deg);
      }
    }
  }
}

.checkbox-main {
  font-size: 14px;

  .vs-checkbox {
    margin-right: 15px;
    width: 20px;
    height: 20px;
  }
}

.users-list {
  margin: 5px -1.5rem 0;

  &__item {
    padding: 10px 1.5rem 10px 1.5rem;
    border-bottom: 1px solid rgb(196, 196, 196);

    &:first-child {
      border-top: 1px solid rgb(196, 196, 196);
    }

    &__main-info {
      display: flex;
      align-items: center;
    }

    &__avatar {
      margin-right: 12px;
      min-width: 40px;
      min-height: 40px;
    }

    &__name {
      width: 25%;
      min-width: 125px;
    }

    &__slots-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      padding-left: 40px;

      &__slot {
        position: relative;
        width: 50%;
        margin-bottom: 15px;

        &__icon {
          position: absolute;
          top: 4px;
          left: -26px;
          color: rgb(59, 134, 247);
          font-weight: 600;
        }

        &__day {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
