<template>
  <vs-sidebar spacer click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ id ? $t('info.editBookableAppointment').toUpperCase() : $t('info.addNewBookableAppointment').toUpperCase() }}</h4>

      <div style="display: flex; flex-direction: row">
        <vs-button radius color="primary" size="large" type="flat" icon="save" @click.stop="saveAppointment" />

        <vs-button radius color="dark" size="large" type="flat" icon="close" @click.stop="onClose" />
      </div>
    </div>

    <vs-divider class="mb-0" />

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <vx-card>
        <vs-input name="name" class="w-full mb-2" :label-placeholder="$t('inputs.nameOfAppointment')" :data-vv-as="$t('inputs.nameOfAppointment')" v-model="name" v-validate="'required'" />

        <span class="text-danger text-sm">{{ errors.first('name') }}</span>

        <vs-input name="company-display-name" class="w-full mt-6 mb-2" :label-placeholder="$t('inputs.setDisplayCompanyName')" :data-vv-as="$t('inputs.setDisplayCompanyName')" v-model="companyDisplayName" />

        <vs-input name="leadTime" type="number" class="w-full mt-6 mb-2" :label-placeholder="$t('info.leadTimeForTheNextBookableAppointmentInMinutes')" :data-vv-as="$t('info.leadTimeForTheNextBookableAppointmentInMinutes')" v-model="leadTime" v-validate="'required|numeric'" />

        <span class="text-danger text-sm">{{ errors.first('leadTime') }}</span>

        <vs-input name="duration" type="number" class="w-full mt-6 mb-2" :label-placeholder="$t('info.appointmentDurationInMinutes')" :data-vv-as="$t('info.appointmentDurationInMinutes')" v-model="duration" v-validate="'required|numeric'" />

        <span class="text-danger text-sm">{{ errors.first('duration') }}</span>

        <div class="mt-5">
          <div>{{ $t('info.setPeriodForUsersToBookOnYourCalendar') }}</div>
          <v-select autoscroll name="bookingPeriodInWeeks" label="name" :options="bookingPeriodInWeeksOptions" :data-vv-as="$t('info.bookingPeriodInWeeks')" :clearable="true" :dir="$vs.rtl ? 'rtl' : 'ltr'" :reduce="(op) => op.id" v-model="bookingPeriodInWeeks" v-validate="'required'" validate-on="blur" />

          <div>
            <span class="text-danger text-sm">{{ errors.first('bookingPeriodInWeeks') }}</span>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="isPrivacyInformationEnabled = !isPrivacyInformationEnabled">
              <toggle-switch-icon :enabled="isPrivacyInformationEnabled" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.enablePrivacyInformation') }}</div>
        </div>
        <div class="mt-4 mr-2 w-full privacy-quill" v-if="isPrivacyInformationEnabled">
          <quill-editor v-model="privacyInformationHtml" ref="quillEditorA" :options="editorOption"> </quill-editor>
        </div>

        <div class="mt-5 assignment-actions">
          <vs-radio v-model="assignType" vs-name="assignType" vs-value="agent" @change="assignedTo = ''">
            {{ $t('vue.assignToAgent') }}
          </vs-radio>

          <vs-radio v-if="userGroups.length" class="ml-2" v-model="assignType" vs-name="assignType" vs-value="group" @change="assignedTo = ''">
            {{ $t('vue.assignToGroup') }}
          </vs-radio>

          <v-select v-if="assignType === 'agent'" autoscroll name="assignedTo" label="name" :options="currentCompanyUsers" :data-vv-as="$t('vue.agent')" :clearable="true" :dir="$vs.rtl ? 'rtl' : 'ltr'" :reduce="(op) => op.id" :placeholder="$t('vue.chooseAgent')" v-model="assignedTo" v-validate="'required'" validate-on="blur">
            <template v-slot:option="option">
              <div class="select-item">
                {{ option.name }}

                <CalendarIcon v-if="option.isSynced" color="#575757" :width="13" :height="13" />

                <CalendarCrossedIcon v-else color="#F05541" :width="13" :height="13" />
              </div>
            </template>
          </v-select>

          <v-select v-if="assignType === 'group'" autoscroll name="assignedTo" label="name" :data-vv-as="$t('vue.group')" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" :options="userGroups" :reduce="(op) => op.id" :placeholder="$t('vue.chooseGroup')" v-validate="'required'" validate-on="blur" v-model="assignedTo" />

          <span class="text-danger text-sm">{{ errors.first('assignedTo') }}</span>
        </div>

        <div class="mt-5">Appointemnt picture</div>

        <div class="mt-2 add-new-data-sidebar__upload-picture">
          <div class="add-new-data-sidebar__upload-picture__avatar-wrapper">
            <croppa name="appointmentPicture" v-validate="'required_if:assignedTo,group'" class="upload-img shadow-md" v-model="originalPhotoURL" :canvas-color="'default'" ref="myPhoto" :width="70" :height="70" style="left: 20px; top: 5px" :placeholder-font-size="14" :placeholder="$t('vue.clickHere')" :show-remove-button="false" :image-border-radius="80" prevent-white-space initial-size="contain" :disable="false" accept=".jpeg,.jpg,.gif,.png" @file-type-mismatch="onImageRemoved" :file-size-limit="2048000" @file-size-exceed="onFileSizeExceed" @new-image-drawn="onNewImageDrawn" @image-remove="onImageRemoved" @file-choose="onFileChoose">
              <Avatar style="position: absolute; top: 0; left: 0" size="70px" fontSize="28px" :photoURL="appointmentPhotoURL" />
            </croppa>
          </div>

          <div class="add-new-data-sidebar__upload-picture__buttons-wrapper">
            <vs-button class="upload-photo mr-4 sm:mb-0 mb-2" @click="selectImage()">{{ uploadPhotoButtonText }}</vs-button>
            <vs-button type="border" color="danger" class="upload-photo mr-4 mb-2" v-if="hasPhoto" @click="removePhoto()">{{
            $t('vue.deletePhoto')
          }}</vs-button>
          </div>
        </div>

        <div>
          <span class="text-danger text-sm">{{ errors.first('appointmentPicture') }}</span>
        </div>

        <div v-if="assignedTo" class="mt-5">
          <div v-if="assignType === 'agent'">{{ $t('inputs.agentAvailability') }}</div>
          <div v-else>{{ $t('inputs.groupAvailability') }}</div>

          <div class="users-list">
            <div v-for="user in filteredListOfSelectedUsers" class="users-list__item" :key="user.id" :ref="user.id">
              <div class="users-list__item__main-info">
                <Avatar class="users-list__item__avatar" size="45px" :photoURL="user.image" :name="user.name" />

                <div class="users-list__item__name">{{ user.name }}</div>

                <div class="users-list__item__calendar-info">
                  <template v-if="user.isSynced">
                    <CalendarIcon color="#575757" :width="13" :height="13" />

                    <div class="users-list__item__calendar-info__label">{{ $t('calendar.syncedCalendar') }}</div>
                  </template>

                  <template v-else>
                    <CalendarCrossedIcon color="#F05541" :width="13" :height="13" />

                    <div class="users-list__item__calendar-info__label">{{ $t('calendar.calendarNotSynced') }}</div>
                  </template>
                </div>

                <button class="users-list__item__button-toggle" @click.stop="toggleClassByRef(user.id, 'users-list__item_expanded')">
                  <feather-icon icon="ChevronDownIcon" />
                </button>
              </div>

              <div v-if="getFilteredAvailableTimeSlots(user).length" class="users-list__item__slots-list">
                <div v-for="(slot, d) in getFilteredAvailableTimeSlots(user)" class="users-list__item__slots-list__slot" :key="d">
                  <vs-icon size="17px" icon="check" class="users-list__item__slots-list__slot__icon" />

                  <div class="mb-2 users-list__item__slots-list__slot__day">
                    {{ $t(modifyDayNameToLong(slot.day)) }}
                  </div>

                  <div v-for="(time, t) in slot.times" class="mt-1" :key="t">
                    <div>{{ format12HrTime(time.start).toLowerCase() }} - {{ format12HrTime(time.end).toLowerCase() }}</div>
                  </div>
                </div>
              </div>

              <div v-else class="users-list__item__no-slots">
                <vs-icon class="users-list__item__no-slots__icon" icon-pack="material-icons" icon="error" size="22px" color="#F05541" />

                {{ $t('info.agentHasNotYetAnyAvailabilities') }}
              </div>
            </div>
          </div>
        </div>

        <div class="advanced-settings">
          {{ $t('info.advancedSettings') }}

          <button class="advanced-settings__button-toggle" @click="isOpenedAdvancedSettings = !isOpenedAdvancedSettings">
            <feather-icon v-if="!isOpenedAdvancedSettings" icon="ChevronDownIcon" />

            <feather-icon v-else icon="ChevronUpIcon" />
          </button>
        </div>

        <template v-if="isOpenedAdvancedSettings">
          <div class="advanced-settings__info">
            <vs-icon class="advanced-settings__info__icon" icon-pack="material-icons" icon="error" size="22px" color="#F05541" />

            <span class="attention-label">{{ $t('vue.attention') }}</span> {{ $t('info.advancedSettingsInfoOfAssignmentRules') }}

            <ul class="options-list">
              <li class="options-list__item">
                {{ $t('info.advancedSettingsDescritpionOfAssignmentRules') }}
                <span class="options-list__item__link" @click="$emit('setActiveTabIndex', 2)">{{ $t('vue.assignmentRules').toLowerCase() }}</span>
              </li>
            </ul>
          </div>

          <div class="flex items-center mt-4">
            <vs-switch color="#275D73" key="areAssignmentRules" class="mr-3" v-model="areAssignmentRules" />

            <div>{{ $t('vue.useTheAssignmentRules') }}</div>
          </div>
        </template>
      </vx-card>
    </component>
  </vs-sidebar>
</template>

<script>
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'

const Link = Quill.import('formats/link')
class Mylink extends Link {
  static create(value) {
    const node = super.create(value)
    value = this.sanitize(value)
    if (!value.startsWith('http')) {
      value = `https://${value}`
    }
    node.setAttribute('href', value)
    return node
  }
}
Quill.debug('error')
Quill.register(Mylink)

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import vSelect from 'vue-select'
import moment from 'moment'

import Avatar from '@/components/Avatar'
import CalendarIcon from '@/components/icons/CalendarIcon'
import CalendarCrossedIcon from '@/components/icons/CalendarCrossedIcon'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

export default {
  components: {
    quillEditor,
    VuePerfectScrollbar,
    vSelect,
    Avatar,
    CalendarIcon,
    CalendarCrossedIcon,
    ToggleSwitchIcon
  },

  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },

    id: {
      type: String
    }
  },

  data() {
    return {
      uploadedFile: null,
      appointmentId: '',
      originalPhotoURL: null,

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

      uploadTask: false,
      defaultPhotoURL: require('@/assets/images/visitor/user.png'),
      appointmentPhotoURL: require('@/assets/images/visitor/user.png'),
      serverAppointmentPhotoURL: null,
      myPhoto: {},
      uploadPhotoButton: 'vue.uploadPhoto',
      isFileSelected: false,
      hasPhoto: false,

      name: '',
      duration: '',
      leadTime: '',
      bookingPeriodInWeeks: '',
      assignType: 'agent',
      assignedTo: '',
      companyDisplayName: null,
      isPrivacyInformationEnabled: false,
      privacyInformationHtml: '',
      areAssignmentRules: false,
      isOpenedAdvancedSettings: false,

      bookingPeriodInWeeksOptions: [{ name: 'This week and next week.', id: 2 }, { name: '3 Weeks', id: 3 }, { name: '4 Weeks', id: 4 }, { name: '5 Weeks', id: 5 }],

      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'link']]
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      users: 'users',
      userGroups: 'user_groups',
      company: 'company'
    }),

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('scheduling') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    uploadPhotoButtonText() {
      return this.$i18n.t(this.uploadPhotoButton)
    },

    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },

      set(val) {
        if (!val) {
          this.$emit('closeAppointmentsSidebar')
        }
      }
    },

    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },

    currentCompanyUsers() {
      if (this.users && this.users.length && this.activeUserInfo) {
        return this.users.filter((user) => user.company === this.activeUserInfo.company)
      }

      return []
    },

    filteredListOfSelectedUsers() {
      if (this.assignedTo) {
        if (this.assignType === 'agent') {
          return this.currentCompanyUsers.filter((user) => user.id === this.assignedTo)
        }

        const selectedGroup = this.userGroups.find((userGroup) => userGroup.id === this.assignedTo)

        if (!selectedGroup) {
          return
        }

        const usersOfSelectedGroup = selectedGroup.users || []

        return usersOfSelectedGroup.map((selectedUser) => {
          return this.currentCompanyUsers.find((user) => user.id === selectedUser.id)
        })
      }

      return []
    }
  },

  mounted() {
    if (this.serverAppointmentPhotoURL) {
      this.hasPhoto = true
    }
  },

  watch: {
    async id() {
      if (!this.id) {
        this.setDefaultValues()
      } else {
        const appointmentRef = await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').doc(this.id).get()

        if (appointmentRef && appointmentRef.data()) {
          const appointment = appointmentRef.data()

          this.name = appointment.name
          this.leadTime = appointment.leadTime
          this.duration = appointment.duration
          this.bookingPeriodInWeeks = appointment.bookingPeriodInWeeks
          this.assignType = appointment.assignType
          this.assignedTo = appointment.assignedTo
          this.appointmentPhotoURL = appointment.appointmentPhotoURL || this.defaultPhotoURL
          this.serverAppointmentPhotoURL = appointment.appointmentPhotoURL
          this.areAssignmentRules = appointment.areAssignmentRules
          this.companyDisplayName = appointment.companyDisplayName || (this.company && this.company.companyDisplayName) || null
          this.isPrivacyInformationEnabled = Boolean(appointment.isPrivacyInformationEnabled)
          this.privacyInformationHtml = appointment.privacyInformationHtml || ''

          if (this.serverAppointmentPhotoURL) {
            this.hasPhoto = true
          }
        }

        this.$validator.pause()
        this.$nextTick(() => {
          this.$validator.errors.clear()
          this.$validator.fields.items.forEach((field) => field.reset())
          this.$validator.fields.items.forEach((field) => this.errors.remove(field))
          this.$validator.resume()
        })
      }
    }
  },

  methods: {
    onFileChoose() {
      this.isFileSelected = true
    },
    selectImage() {
      this.$refs.myPhoto.chooseFile()
    },
    onNewImageDrawn() {
      if (this.isFileSelected) {
        this.uploadCroppedImage()
      }
    },
    onImageRemoved() {
      this.uploadPhotoButton = 'vue.uploadPhoto'
    },
    async removePhoto() {
      const vm = this
      const storageService = firebase.storage()
      if (this.appointmentPhotoURL !== undefined && this.appointmentPhotoURL !== null && this.appointmentPhotoURL !== this.defaultPhotoURL && this.appointmentPhotoURL.length > 0) {
        if (!this.serverAppointmentPhotoURL) {
          this.appointmentPhotoURL = this.defaultPhotoURL
          this.hasPhoto = false

          return
        }
        // Use the storage service the delete the file and url
        try {
          await storageService.refFromURL(vm.serverAppointmentPhotoURL).delete()

          const appointmentId = this.appointmentId || this.id

          const appointmentRef = await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').doc(appointmentId)
          await appointmentRef.set({ appointmentPhotoURL: null }, { merge: true })
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.removed'),
            text: vm.$i18n.t('vue.photoRemovedSuccessfully'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          this.hasPhoto = false
          this.appointmentPhotoURL = this.defaultPhotoURL
          this.serverAppointmentPhotoURL = null
        } catch (err) {
          let message = err.message
          if (err.code === 'storage/object-not-found') {
            message = vm.$i18n.t('vue.objectDoesNotExists')
          }
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.error'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
      setTimeout(() => {
        this.$vs.loading.close()
      }, 100)
    },
    onFileTypeMismatch() {
      const vm = this
      vm.isFileSelected = false
      this.$vs.notify({
        time: 8800,
        title: vm.$i18n.t('vue.fileTypeNotSupported'),
        text: vm.$i18n.t('vue.fileTypeNotSupportedMessage'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onFileSizeExceed() {
      const vm = this
      vm.isFileSelected = false
      this.$vs.notify({
        time: 8800,
        title: vm.$i18n.t('vue.fileSizeLimitExceeded'),
        text: vm.$i18n.t('vue.maximumSize'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    async uploadCroppedImageToServer(appointmentId) {
      const vm = this

      const storageService = firebase.storage()
      const storageRef = storageService.ref()

      const metadata = {
        contentType: 'image/png',
        cacheControl: 'max-age=604800, public'
      }

      let uploadTask = null
      uploadTask = storageRef.child(`images/appointments/${appointmentId}`).put(this.uploadedFile, metadata)
      this.uploadTask = true
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          this.progressUpload = progress
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              vm.text = 'fields.storage/unauthorized'
              break
            case 'storage/canceled':
              // User canceled the upload
              vm.text = 'fields.storage/canceled'
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              vm.text = 'fields.storage/unknown'
              break
          }

          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.error'),
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          vm.uploadTask = false
        },
        async () => {
          // Upload completed successfully, now we can get the download URL
          this.uploadTask = false
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          const appointmentRef = await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').doc(appointmentId)
          appointmentRef.set({ appointmentPhotoURL: downloadURL }, { merge: true })

          this.appointmentPhotoURL = downloadURL
          this.serverAppointmentPhotoURL = downloadURL

          if (this.appointmentPhotoURL !== null) {
            this.hasPhoto = true
          }
        }
      )
    },
    async uploadCroppedImage() {
      const vm = this
      vm.isFileSelected = false
      try {

        if (this.originalPhotoURL && this.originalPhotoURL.originalImage && this.originalPhotoURL.originalImage.src) {
          this.appointmentPhotoURL = this.originalPhotoURL.originalImage.src
          this.hasPhoto = true
        }

        vm.$refs.myPhoto.generateBlob(
          async (blob) => {
            const file = blob

            this.uploadedFile = file

            this.$validator.errors.remove('appointmentPicture')
          },
          'image/png',
          0.8
        ) // 80% compressed jpeg file
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },

    onClose() {
      this.isSidebarActiveLocal = false
      this.setDefaultValues()
    },
    async saveAppointment() {
      if (this.assignType === 'group' && (!this.appointmentPhotoURL || this.appointmentPhotoURL === this.defaultPhotoURL)) {
        this.errors.add({
          field: 'appointmentPicture',
          msg: 'The appointment picture is required'
        })

        return
      }

      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        const result = await this.$validator.validate()

        if (!result) {
          return
        }

        const appointment = {
          name: this.name,
          duration: this.duration,
          leadTime: this.leadTime,
          bookingPeriodInWeeks: this.bookingPeriodInWeeks,
          assignType: this.assignType,
          assignedTo: this.assignedTo,
          areAssignmentRules: this.areAssignmentRules,
          privacyInformationHtml: this.privacyInformationHtml,
          isPrivacyInformationEnabled: this.isPrivacyInformationEnabled,
          companyDisplayName: this.companyDisplayName
        }

        let appointmentId = this.id

        if (!this.id) {
          appointment.created = new Date()
          appointment.createdBy = this.activeUserInfo.uid

          const result = await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').add(appointment)

          appointmentId = result.id
        } else {
          appointment.modified = new Date()
          appointment.modifiedBy = this.activeUserInfo.uid

          await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').doc(this.id).set(appointment, { merge: true })
        }

        this.appointmentId = appointmentId

        if (this.appointmentPhotoURL !== undefined && this.appointmentPhotoURL !== null && this.appointmentPhotoURL !== this.defaultPhotoURL && this.appointmentPhotoURL !== this.serverAppointmentPhotoURL) {
          await this.uploadCroppedImageToServer(this.appointmentId)
        }

        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
        this.isSidebarActiveLocal = false
        this.setDefaultValues()
      }
    },

    setDefaultValues() {
      this.name = ''
      this.duration = ''
      this.leadTime = ''
      this.bookingPeriodInWeeks = ''
      this.assignType = 'agent'
      this.assignedTo = ''
      this.appointmentPhotoURL = this.defaultPhotoURL
      this.serverAppointmentPhotoURL = null
      this.isPrivacyInformationEnabled = false
      this.privacyInformationHtml = ''

      this.errors.clear()
      this.$validator.reset()

      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach((field) => field.reset())
        this.$validator.fields.items.forEach((field) => this.errors.remove(field))
        this.$validator.resume()
      })
    },

    format12HrTime(time24hr) {
      return moment(time24hr, 'HH:mm').locale(this.$i18n.locale).format('LT')
    },

    modifyDayNameToLong(value) {
      if (!value || !(typeof value === 'string' || value instanceof String)) {
        return value
      }

      return value.replace('short', 'long')
    },

    toggleClassByRef(ref, className) {
      const $el = this.$refs[ref][0]

      $el.classList.contains(className) ? $el.classList.remove(className) : $el.classList.add(className)
    },

    getFilteredAvailableTimeSlots(user) {
      return user.availableTimeSlots ? user.availableTimeSlots.filter((slot) => slot.enabled) : []
    }
  }
}
</script>

<style lang="scss" scoped>
.agent-name {
  font-size: 0.95rem;
  font-weight: 500;
}
.user-select {
  max-width: fit-content;
  min-width: 250px;
}
.header-text {
  font-weight: bold;
}
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}

.select-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.users-list {
  margin: 5px -1.5rem 0;

  &__item {
    padding: 10px 1.5rem 10px 1.5rem;
    border-bottom: 1px solid rgb(196, 196, 196);

    &:first-child {
      border-top: 1px solid rgb(196, 196, 196);
    }

    &__main-info {
      display: flex;
      align-items: center;
    }

    &__avatar {
      margin-right: 12px;
    }

    &__name {
      width: 25%;
      min-width: 125px;
    }

    &__calendar-info {
      display: flex;
      align-items: center;
      font-size: 12px;

      &__label {
        margin-left: 5px;
      }
    }

    &__button-toggle {
      margin-left: auto;
      margin-right: 25px;
      background: none;
      border: none;
    }

    &__slots-list {
      display: none;
      flex-wrap: wrap;
      margin-top: 20px;
      padding-left: 40px;

      &__slot {
        position: relative;
        width: 50%;
        margin-bottom: 15px;

        &__icon {
          position: absolute;
          top: 4px;
          left: -26px;
          color: rgb(59, 134, 247);
          font-weight: 600;
        }

        &__day {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }

    &__no-slots {
      display: none;
      margin-top: 30px;
      padding-left: 57px;

      &__icon {
        margin-top: -1px;
        margin-right: 7px;
      }
    }

    &_expanded {
      padding-bottom: 30px;
      background: rgba(196, 196, 196, 0.15);

      .users-list__item {
        &__button-toggle {
          transform: rotate(180deg) translateY(5px);
        }

        &__slots-list,
        &__no-slots {
          display: flex;
        }
      }
    }
  }
}

.advanced-settings {
  position: relative;
  margin: 50px -1.5rem 0;
  padding: 11px 1.5rem;
  color: rgb(255, 255, 255);
  background: rgb(18, 89, 141);

  &__button-toggle {
    position: absolute;
    top: 50%;
    right: 50px;
    width: 20px;
    height: 23px;
    color: rgb(255, 255, 255);
    background: none;
    border: none;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
  }

  &__info {
    position: relative;
    margin-top: 25px;
    padding-left: 35px;

    &__icon {
      position: absolute;
      top: -1px;
      left: 0;
    }
  }
}

.attention-label {
  color: rgb(240, 85, 65);
  font-weight: 600;
}

.options-list {
  padding-left: 13px;

  &__item {
    position: relative;
    padding-left: 9px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: -3px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: rgb(87, 87, 87);
    }

    &__link {
      color: rgb(59, 134, 247);
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
.agent-image .con-vs-image {
  border-radius: 18px !important;
  width: 35px !important;
  height: 35px !important;
}
.agent-image .vs-image--img {
  background-size: cover;
  border-radius: 0px !important;
  min-width: 10px;
}
.add-new-data-sidebar {
  .vs-sidebar {
    max-width: 600px !important;
  }
}

.vs__search,
.vs__selected,
.vs__search:focus {
  line-height: 1.8 !important;
}

.privacy-quill .ql-editing {
  left: 0px !important;
  z-index: 1 !important;
}

.add-new-data-sidebar__upload-picture {
  display: flex;

  &__avatar-wrapper {
    position: relative;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;

    .upload-img {
      top: 0 !important;
      left: 0 !important;
    }
  }

  &__buttons-wrapper {
    margin-left: 12px;
  }
}

.avatar-placeholer-wrapper {
  img {
    object-fit: cover;
  }
}
</style>
