<template>
  <vs-sidebar
    spacer
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4 v-if="isNewAccount">{{ $t('info.addSettingsForTheCompany').toUpperCase() }}</h4>
      <h4 v-else>{{ $t('info.companySettings').toUpperCase() }}</h4>

      <div style="display: flex; flex-direction: row">
        <vs-button v-if="isNewAccount" radius color="primary" size="large" type="flat" icon="save" @click.stop="saveSettings" />

        <vs-button radius color="dark" size="large" type="flat" icon="close" @click.stop="isSidebarActiveLocal = false" />
      </div>
    </div>

    <vs-divider class="mb-0" />

    <vx-card class="card-sidebar">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <p v-if="isNewAccount">{{ $t('info.setupCustomerPersonalDetails') }}</p>
          <p v-else>{{ $t('info.customerPersonalDetails') }}</p>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('inputs.firstName') }}</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            name="firstName"
            class="w-full"
            :placeholder="$t('inputs.firstName')"
            v-validate="'required'"
            data-vv-validate-on="blur"
            v-model="customer.firstname"
            data-vv-as="name"
            validate-on="blur"
            :disabled="!isNewAccount"
          />
          <span class="text-danger text-sm">{{ errors.first('firstName') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('inputs.lastName') }}</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            name="lastName"
            :placeholder="$t('inputs.lastName')"
            class="w-full"
            v-validate="'required'"
            data-vv-validate-on="blur"
            v-model="customer.lastname"
            data-vv-as="lastname"
            validate-on="blur"
            :disabled="!isNewAccount"
          />
          <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('inputs.email') }}</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            name="email"
            type="email"
            class="w-full"
            :placeholder="$t('inputs.email')"
            v-validate="'required'"
            data-vv-validate-on="blur"
            v-model="customer.email"
            data-vv-as="email"
            validate-on="blur"
            :disabled="!isNewAccount"
          />
          <span class="text-danger text-sm">{{ errors.first('email') }}</span>
        </div>
      </div>

      <div v-if="isNewAccount" class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-checkbox hide-details class="ma-0 pt-0 pb-1" v-model="isSendingSingLink">
            {{ $t('vue.sendSignInLinkToTheCustomersEmailAddress') }}
          </vs-checkbox>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <p v-if="isNewAccount">{{ $t('info.setupCustomerCompanyDetails') }}</p>
          <p v-else>{{ $t('info.customerCompanyDetails') }}</p>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.companyName') }}</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            :placeholder="$t('vue.myAwesomeWebsite')"
            id="companySetup_WebsiteName"
            class="w-full"
            v-model="company.name"
            v-validate="'required|min:3'"
            name="name"
            data-vv-as="name"
            validate-on="blur"
            :disabled="!isNewAccount"
          />
          <span class="text-danger text-sm">{{ errors.first('name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.street') }}</span>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <vs-input class="w-full" v-model="company.street" :disabled="!isNewAccount" />
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <span class="w-full">{{ $t('vue.houseNumber') }}</span>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <vs-input class="w-full" v-model="company.houseNumber" :disabled="!isNewAccount" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.zipcode') }}</span>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <vs-input
            class="w-full"
            v-model="company.zipcode"
            v-validate="'required'"
            name="zipcode"
            data-vv-as="zipcode"
            validation-on="change"
            :disabled="!isNewAccount"
          />
          <span class="text-danger text-sm">{{ errors.first('zipcode') }}</span>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.city') }}</span>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <vs-input class="w-full" v-model="company.city" :disabled="!isNewAccount" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.country') }}</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-select class="w-full" v-model="company.country" autocomplete color="grey" :disabled="!isNewAccount">
            <vs-select-item :value="country.countryName" :text="country.countryName" v-for="country in countryCodes" :key="country.code" />
          </vs-select>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.phone') }}</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            placeholder="+1 656 234242"
            class="w-full"
            v-validate="'required'"
            name="phone"
            data-vv-as="phone"
            validation-on="change"
            v-model="company.phone"
            :disabled="!isNewAccount"
          />
          <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.vatNumber') }}</span>
          <div class="font10">({{ $t('vue.optional') }})</div>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input class="w-full" v-model="company.vatNumber" :disabled="!isNewAccount" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>{{ $t('vue.website') }}</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            placeholder="https://www.mycompanyname"
            v-validate="'isURLValid'"
            name="url"
            data-vv-as="url"
            class="w-full"
            v-model="company.url"
            validate-on="blur"
            :disabled="!isNewAccount"
          />
          <span class="text-danger text-sm">{{ errors.first('url') }}</span>
        </div>
      </div>
    </vx-card>
  </vs-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},

  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      countryCodes: require('@/assets/countryCodes.json'),

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

      isNewAccount: false,
      isSendingSingLink: false,

      customer: {
        firstname: '',
        lastname: '',
        email: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),

    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },

      set(val) {
        if (!val) {
          this.$emit('closeAppointmentsSidebar')
        }
      }
    },

    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },

  created() {
    this.$serverBus.$on('company-settings-data', this.setInitialData)
  },

  beforeDestroy() {
    this.$serverBus.$off('company-settings-data')
  },

  watch: {
    'company.country'(value) {
      const countryCodes = this.countryCodes.filter((country) => country.countryName === (value ? value : ''))
      this.company.countryCode = countryCodes && countryCodes.length > 0 ? countryCodes[0].code : ''
    }
  },

  methods: {
    async saveSettings() {
      const result = await this.$validator.validate()

      if (!result) {
        return
      }

      await this.$vs.loading()

      try {
        const data = {
          ...this.customer,
          ...this.company,
          agencyUserId: this.activeUserInfo.uid,
          agencyId: this.activeUserInfo.company,
          language: this.$i18n.locale,
          origin: `${window.location.protocol}//${window.location.host}/company-setup`
        }

        const createAccountByUser = this.$functions.httpsCallable('createAccountByUser')
        const response = await createAccountByUser(data)
        const responseData = response.data

        if (!responseData.status) {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: responseData.messageCode
              ? this.$i18n.t(`error.${responseData.messageCode}`)
              : responseData.message || this.$i18n.t('info.somethingWentWrong'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })

          if (responseData.message) {
            /* eslint-disable no-console */
            console.log(responseData.message)
          }

          await this.$vs.loading.close()
        } else {
          if (this.isSendingSingLink) {
            const payload = {
              email: data.email,
              closeAnimation: this.$vs.loading.close
            }

            await this.$store.dispatch('auth/sendSignInLink', payload)
          }

          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.success'),
            text: this.$i18n.t('vue.accountSuccessfullyCreated'),
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })

          if (!this.isSendingSingLink) {
            await this.$vs.loading.close()
          }
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error)

        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.somethingWentWrong'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        await this.$vs.loading.close()
      }

      this.isSidebarActiveLocal = false
    },

    async setInitialData(data) {
      await this.$vs.loading()

      this.isNewAccount = !data

      if (data) {
        Object.keys(data).forEach((key) => {
          if (key === 'customer') {
            this.customer.firstname = data[key].firstname
            this.customer.lastname = data[key].lastname
            this.customer.email = data[key].email
          } else {
            this.company[key] = data[key]
          }
        })
      } else {
        Object.keys(this.company).forEach((key) => {
          this.company[key] = ''
        })

        Object.keys(this.customer).forEach((key) => {
          this.customer[key] = ''
        })

        this.$nextTick(() => {
          this.errors.clear()
          this.$validator.reset()
        })
      }

      await this.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-sidebar {
  height: calc(100% - 80px);
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgb(171, 171, 171);
    border-radius: 6px;
  }
}

.header-text {
  font-weight: bold;
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.pa-bk-secondary {
  background: rgba(var(--vs-secondary), 1) !important;
  border-radius: 10px;
  color: #fff !important;
}

.add-new-data-sidebar {
  .vs-sidebar {
    max-width: 600px !important;
  }
}

.vs-popup {
  width: 460px !important;
}
</style>
