<template>
  <div id="call-center-view">
    <CallCenterSidebar
      :isSidebarActive="isOpenedSidebar"
      :isCallCenter="isCallCenter"
      :appActiveUser="activeUserInfo"
      @closeAppointmentsSidebar="toggleSidebar"
    />

    <vs-popup class="link-cc-popup" title="" :active.sync="isLinkCallCenterPopupActive" button-close-hidden>
      <div class="link-cc-popup__close-icon-wrapper" @click="closeCallCenterForm">
        <close-icon color="#575757" :width="20" :height="20" />
      </div>
      <template v-if="!isLinkCCFormSent">
        <div class="link-cc-popup__content-wrapper">
          <div class="link-cc-popup__title">{{ $t('vue.ccForm.mainPopupTitle') }}</div>
          <div class="link-cc-popup__subtitle">
            {{ $t('vue.ccForm.mainPopupSubtitle') }}
          </div>
          <div class="link-cc-popup__fields">
            <div class="link-cc-popup__fields__field">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="saluation"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.salutation"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.salutation')"
                  :label-placeholder="$t('inputs.ccForm.salutation')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.salutation && linkCCFormFields.salutation.length > 0
                  }"
                  @click="$refs.saluation.focus()"
                >
                  {{ $t('inputs.ccForm.salutation') }}
                </div>
              </div>
              <vx-tooltip :text="$t('info.ccForm.salutation')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
            <div class="link-cc-popup__fields__field">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="companyName"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.companyName"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.companyName')"
                  :label-placeholder="$t('inputs.ccForm.companyName')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.companyName && linkCCFormFields.companyName.length > 0
                  }"
                  @click="$refs.companyName.focus()"
                >
                  {{ $t('inputs.ccForm.companyName') }}
                </div>
              </div>
            </div>
            <div class="link-cc-popup__fields__field link-cc-popup__fields__field--company-description">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="companyDescription"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.companyDescription"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.companyDescription')"
                  :label-placeholder="$t('inputs.ccForm.companyDescription')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.companyDescription && linkCCFormFields.companyDescription.length > 0
                  }"
                  @click="$refs.companyDescription.focus()"
                >
                  {{ $t('inputs.ccForm.companyDescription') }}
                </div>
              </div>
              <vx-tooltip :text="$t('info.ccForm.companyDescription')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
            <div class="link-cc-popup__fields__field">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="generalEmailAddress"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.generalEmailAddress"
                  v-validate="'required|email'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.generalEmailAddress')"
                  :label-placeholder="$t('inputs.ccForm.generalEmailAddress')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.generalEmailAddress && linkCCFormFields.generalEmailAddress.length > 0
                  }"
                  @click="$refs.generalEmailAddress.focus()"
                >
                  {{ $t('inputs.ccForm.generalEmailAddress') }}
                </div>
              </div>
              <vx-tooltip :text="$t('info.ccForm.generalEmailAddress')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
            <div class="link-cc-popup__fields__field">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="generalPhoneNumber"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.generalPhoneNumber"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.generalPhoneNumber')"
                  :label-placeholder="$t('inputs.ccForm.generalPhoneNumber')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.generalPhoneNumber && linkCCFormFields.generalPhoneNumber.length > 0
                  }"
                  @click="$refs.generalPhoneNumber.focus()"
                >
                  {{ $t('inputs.ccForm.generalPhoneNumber') }}
                </div>
              </div>
              <vx-tooltip :text="$t('info.ccForm.generalPhoneNumber')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
            <div class="link-cc-popup__fields__field link-cc-popup__fields__field--internal-contact">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="internalContactPerson"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.internalContactPerson"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.internalContactPerson')"
                  :label-placeholder="$t('inputs.ccForm.internalContactPerson')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.internalContactPerson && linkCCFormFields.internalContactPerson.length > 0
                  }"
                  @click="$refs.internalContactPerson.focus()"
                >
                  {{ $t('inputs.ccForm.internalContactPerson') }}
                </div>
              </div>
              <vx-tooltip :text="$t('info.ccForm.internalContactPerson')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
            <div class="link-cc-popup__fields__field link-cc-popup__fields__field--reasons">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="reasons"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.reasons"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.reasons')"
                  :label-placeholder="$t('inputs.ccForm.reasons')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.reasons && linkCCFormFields.reasons.length > 0
                  }"
                  @click="$refs.reasons.focus()"
                >
                  {{ $t('inputs.ccForm.reasons') }}
                </div>
              </div>
              <vx-tooltip :text="$t('info.ccForm.reasons')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
            <div class="link-cc-popup__fields__field">
              <div class="link-cc-popup__fields__field__inner">
                <multiselect
                  ref="neededCustomerInformation"
                  v-model="linkCCFormFields.neededCustomerInformation"
                  :options="neededCustomerInformationList"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :placeholder="''"
                  :selectLabel="''"
                  :selectedLabel="''"
                  :deselectLabel="''"
                  label="name"
                  track-by="name"
                  name="neededCustomerInformation"
                  class="link-cc-popup__fields__field__select link-cc-popup__fields__field__select-checkbox"
                  open-direction="bottom"
                  :preserve-search="true"
                  :preselect-first="false"
                  @close="isOpenedNeededCustomerInformationSelect = false"
                  @open="isOpenedNeededCustomerInformationSelect = true"
                >
                  <template slot="option" slot-scope="{ option }">
                    <div class="link-cc-popup__fields__field__select__checkbox" />
                    {{ option.name }}
                  </template>
                  <template slot="noResult">No results found</template>
                </multiselect>
                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved':
                      (!!linkCCFormFields.neededCustomerInformation && !!linkCCFormFields.neededCustomerInformation.length) ||
                      isOpenedNeededCustomerInformationSelect,
                    'link-cc-popup__fields__field__label--over': isOpenedNeededCustomerInformationSelect
                  }"
                  @click="$refs.neededCustomerInformation.$el.focus()"
                >
                  {{ $t('inputs.ccForm.neededCustomerInformation') }}
                </div>
              </div>
              <vx-tooltip :text="$t('info.ccForm.neededCustomerInformation')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
            <div class="link-cc-popup__fields__field link-cc-popup__fields__field--contact-requests">
              <div class="link-cc-popup__fields__field__inner">
                <input
                  ref="contactRequestsEmail"
                  class="link-cc-popup__fields__field__input"
                  v-model="linkCCFormFields.contactRequestsEmail"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  :name="$t('inputs.ccForm.contactRequestsEmail')"
                  :label-placeholder="$t('inputs.ccForm.contactRequestsEmail')"
                />

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': linkCCFormFields.contactRequestsEmail && linkCCFormFields.contactRequestsEmail.length > 0
                  }"
                  @click="$refs.contactRequestsEmail.focus()"
                >
                  {{ $t('inputs.ccForm.contactRequestsEmail') }}
                </div>
              </div>
            </div>
            <div class="link-cc-popup__fields__field">
              <div class="link-cc-popup__fields__field__inner">
                <multiselect
                  ref="handleRequests"
                  v-model="linkCCFormFields.handleRequests"
                  :options="handleRequestsList"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :placeholder="''"
                  :selectLabel="''"
                  :selectedLabel="''"
                  :deselectLabel="''"
                  label="name"
                  track-by="name"
                  name="handleRequests"
                  class="link-cc-popup__fields__field__select"
                  open-direction="bottom"
                  :preserve-search="true"
                  :preselect-first="false"
                  @close="isOpenedHandleRequestsSelect = false"
                  @open="isOpenedHandleRequestsSelect = true"
                >
                  <template slot="noResult">No results found</template>
                </multiselect>

                <div
                  class="link-cc-popup__fields__field__label"
                  :class="{
                    'link-cc-popup__fields__field__label--moved': !!linkCCFormFields.handleRequests || isOpenedHandleRequestsSelect,
                    'link-cc-popup__fields__field__label--over': isOpenedHandleRequestsSelect
                  }"
                  @click="$refs.handleRequests.$el.focus()"
                >
                  {{ $t('inputs.ccForm.handleRequests') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="link-cc-popup__button-wrapper">
          <vs-button color="primary" type="filled" @click.prevent="sendCallCenterForm">{{ $t('vue.ccForm.save') }}</vs-button>
        </div>
      </template>
      <template v-else>
        <div class="link-cc-popup__content-wrapper">
          <div class="">
            <div class="flex items-center w-full justify-center">
              <div class="link-cc-popup__check-icon-wrapper">
                <ThinCheckIcon class="link-cc-popup__check-icon" width="40" height="24" color="#fff" />
              </div>
            </div>
            <div class="link-cc-popup__content-wrapper--success-title">{{ $t('vue.ccForm.successPopupTitle') }}</div>
            <div class="link-cc-popup__content-wrapper--success-subtitle">
              {{ $t('vue.ccForm.successPopupSubtitle') }}
            </div>
          </div>
        </div>
      </template>
    </vs-popup>

    <vx-card v-if="isCallCenter" title-color="primary" subtitle-color="danger" :title="$t('register.callCenterService')" class="mb-4">
      <p class="mb-1">
        {{ $t('info.createSettingsForTheAbilityToBookAMeeting') }}
      </p>

      <div slot="footer">
        <vs-row vs-justify="flex-end">
          <vs-button v-if="isCallCenter" color="primary" type="filled" icon-size="24px" icon="add" @click.stop="activePrompt = true">
            {{ $t('inputs.inviteCompany') }}
          </vs-button>
        </vs-row>
      </div>
    </vx-card>

    <vx-card
      v-else-if="!isCallCenter && !company.linkCCFormFields && !company.isConnectedWithCallCenter"
      title-color="primary"
      subtitle-color="danger"
      :title="$t('register.callCenterService')"
      class="mb-4"
    >
      <p class="mb-1">
        <template v-if="$i18n.locale === 'de'">
          Um unseren Callcenter-Service zu nutzen, um Ihre eingehenden Anfragen zu qualifizieren, klicken Sie bitte auf die Schaltfläche
          <span @click="linkCallCenter" class="link-cc__link">„Callcenter verknüpfen“”</span>
          und fügen Sie Ihre Unternehmensdaten hinzu. Nach erfolgreicher Verifizierung wird Ihr Konto in den nächsten 48 Stunden automatisch mit unserem
          Callcenter verbunden.
        </template>
        <template v-else>
          To use our Call Center Service to qualify your incoming requests, please click on the
          <span @click="linkCallCenter" class="link-cc__link">“Link call center”</span> Button and add your Company details. After a successful verification,
          your account will be automatically connected with our call center in the next 48 hours.
        </template>
      </p>

      <div slot="footer">
        <vs-row vs-justify="flex-end">
          <vs-button color="primary" type="filled" @click="linkCallCenter">{{ $t('vue.ccForm.linkCallCenter') }}</vs-button>
        </vs-row>
      </div>
    </vx-card>

    <vx-card>
      <vs-button v-if="isCallCenter" color="primary" type="filled" icon-size="24px" icon="add" @click.stop="activePrompt = true">
        {{ $t('inputs.inviteCompany') }}
      </vs-button>

      <vs-table
        ref="table"
        v-model="selected"
        pagination
        :max-items="itemsPerPage"
        search
        :data="allowedCompanies"
        :noDataText="!isCallCenter && company && company.linkCCFormFields ? $t('vue.ccForm.pendingRequest') : 'No data Available'"
      >
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center"></div>
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              style="border-radius: 5px"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{ allowedCompanies.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : allowedCompanies.length }} of
                {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 5">
                <span>5</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="name">
            {{ $t('vue.name') }}
          </vs-th>
          <vs-th sort-key="cooperation">
            {{ $t('vue.cooperation') }}
          </vs-th>
          <vs-th sort-key="isEnabledUpdatingDialogs">
            {{ $t('vue.enabledUpdatingDialogs') }}
          </vs-th>
          <vs-th sort-key="phone">
            {{ $t('vue.phone') }}
          </vs-th>
          <vs-th sort-key="url">
            {{ $t('vue.url') }}
          </vs-th>
          <vs-th sort-key="city">
            {{ $t('vue.address') }}
          </vs-th>
          <vs-th>{{ $t('vue.action') }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>

              <vs-td :data="data[indextr].cooperation">
                <span
                  :style="{
                    color: setCooperationColorByType(data[indextr].cooperation)
                  }"
                >
                  {{ $t(`vue.${data[indextr].cooperation}`) }}
                </span>
              </vs-td>

              <vs-td :data="data[indextr].isEnabledUpdatingDialogs">
                <button
                  style="background: none; border: none; cursor: pointer"
                  @click="callCenterSettings[0].customerId === activeUserInfo.company ? toggleIsEnabledUpdatingDialogs() : null"
                >
                  <vs-chip :color="chipColor(data[indextr].isEnabledUpdatingDialogs)">
                    <span style="width: 100%; height: 100%">{{ data[indextr].isEnabledUpdatingDialogs ? $t('vue.enabled') : $t('vue.disabled') }}</span>
                  </vs-chip>
                </button>
              </vs-td>

              <vs-td :data="data[indextr].phone">
                {{ data[indextr].phone }}
              </vs-td>

              <vs-td :data="data[indextr].url">
                {{ data[indextr].url }}
              </vs-td>

              <vs-td>
                <p v-if="data[indextr].street">
                  <strong>{{ $t('vue.street') }} : </strong>{{ data[indextr].street }}
                </p>
                <p v-if="data[indextr].city">
                  <strong>{{ $t('vue.city') }} : </strong>{{ data[indextr].city }}
                </p>
                <p v-if="data[indextr].zipcode">
                  <strong>{{ $t('vue.zipcode') }} : </strong>{{ data[indextr].zipcode }}
                </p>
                <p v-if="data[indextr].country">
                  <strong v-if="data[indextr].country.countryName">{{ $t('vue.country') }} : </strong>{{ data[indextr].country.countryName }}
                </p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <div class="flex">
                  <vx-tooltip v-if="isCallCenter && data[indextr].approved !== 'confirmed'" position="top" :text="$t('vue.resendTheInvite')">
                    <vs-button
                      class="mr-2"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-send"
                      :color="data[indextr].approved === 'canceled' ? 'primary' : 'dark'"
                      :disabled="data[indextr].approved !== 'canceled'"
                      @click="inviteCompany(data[indextr].customerId)"
                    />
                  </vx-tooltip>

                  <vx-tooltip v-if="!isCallCenter" position="top" :text="'Edit linked CallCenter form'">
                    <vs-button
                      class="mr-2"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      :color="'primary'"
                      :icon="'icon-edit'"
                      @click="editLinkedCallCenterInfo()"
                    />
                  </vx-tooltip>

                  <vx-tooltip position="top" :text="$t('vue.openSettingsForTheCompany')">
                    <vs-button
                      class="mr-2"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      :color="data[indextr].approved === 'confirmed' ? 'primary' : 'dark'"
                      :icon="isCallCenter ? 'icon-edit' : 'icon-info'"
                      :disabled="data[indextr].approved !== 'confirmed'"
                      @click="toggleSidebar(true, data[indextr])"
                    />
                  </vx-tooltip>

                  <vx-tooltip v-if="isCallCenter" position="top" :text="$t('vue.goToTheDialogs')">
                    <vs-button
                      class="mr-2"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-code"
                      :color="data[indextr].approved === 'confirmed' && data[indextr].isEnabledUpdatingDialogs ? 'primary' : 'dark'"
                      :disabled="data[indextr].approved !== 'confirmed' || !data[indextr].isEnabledUpdatingDialogs"
                      @click.stop="$router.push(`/call-center-dialogs/${data[indextr].customerId}`)"
                    />
                  </vx-tooltip>

                  <vx-tooltip position="top" :text="$t('vue.cancelTheCooperation')">
                    <vs-button
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-trash"
                      color="danger"
                      @click.stop="openDeletingPrompt(data[indextr].uidCallCenterSettings)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vx-card>

    <vs-prompt
      v-if="activePrompt"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.confirm')"
      :title="$t('inputs.inviteCompany')"
      :active.sync="activePrompt"
      @accept="inviteCompany"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t('inputs.enterCompanyId') }}</span>

        <vs-input
          class="mt-3 w-full"
          :placeholder="$t('inputs.companyId')"
          :name="$t('inputs.companyId')"
          v-model="inviteData.companyId"
          v-validate="'required'"
          data-vv-validate-on="blur"
        />
      </div>
    </vs-prompt>

    <vs-prompt
      v-if="activeDeletingPrompt"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :title="$t('inputs.deleteCompany')"
      :active.sync="activeDeletingPrompt"
      @accept="deleteCompany"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t('info.terminateTheCooperationWithTheCompany') }}</span>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CallCenterSidebar from './CallCenterSidebar'
import CloseIcon from '@/components/icons/CloseIcon'
import ThinCheckIcon from '@/components/icons/ThinCheckIcon'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    CallCenterSidebar,
    CloseIcon,
    ThinCheckIcon,
    Multiselect
  },

  props: {
    isCallCenter: {
      type: Boolean,
      required: true
    },
    isCallCenterList: {
      required: true
    }
  },

  data: (state) => ({
    allowedCompanies: [],
    isMounted: false,
    itemsPerPage: 5,
    selected: [],
    isOpenedSidebar: false,
    activePrompt: false,
    activeDeletingPrompt: false,
    inviteData: {
      companyId: ''
    },
    temrminateCooperationId: '',
    uidCallCenterSettingsId: null,
    isLinkCallCenterPopupActive: false,

    infoImg: require('@/assets/images/elements/info.svg'),

    isOpenedNeededCustomerInformationSelect: false,
    isOpenedHandleRequestsSelect: false,
    isLinkCCFormSent: false,
    isEditLinkCCForm: false,

    linkCCFormFields: {
      salutation: '',
      companyName: '',
      companyDescription: '',
      generalEmailAddress: '',
      generalPhoneNumber: '',
      internalContactPerson: '',
      reasons: '',
      neededCustomerInformation: '',
      contactRequestsEmail: '',
      handleRequests: ''
    },
    neededCustomerInformationList: [
      {
        name: state.$i18n.t('inputs.neededCustomerInformationList.reason')
      },
      {
        name: state.$i18n.t('inputs.neededCustomerInformationList.reach')
      },
      {
        name: state.$i18n.t('inputs.neededCustomerInformationList.fullName')
      },
      {
        name: state.$i18n.t('inputs.neededCustomerInformationList.phoneNumber')
      },
      {
        name: state.$i18n.t('inputs.neededCustomerInformationList.email')
      },
      {
        name: state.$i18n.t('inputs.neededCustomerInformationList.other')
      }
    ],
    handleRequestsList: [
      {
        name: state.$i18n.t('inputs.handleRequests.doNotAcceptRequests')
      },
      {
        name: state.$i18n.t('inputs.handleRequests.forwardRequests')
      }
    ]
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      callCenterSettings: 'call_center_settings'
    }),

    currentPage() {
      if (this.isMounted && this.$refs.table) {
        return this.$refs.table.currentx
      }

      return 1
    },

    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.allowedCompanies.length
    }
  },

  mounted() {
    this.isMounted = true

    this.setCallCenterSettings(this.callCenterSettings)

    this.$serverBus.$on('toggle-active-call-center-sidebar', ({ isOpened, data }) => {
      this.toggleSidebar(isOpened, data)
    })
  },

  beforeDestroy() {
    this.$serverBus.$off('toggle-active-call-center-sidebar')
  },

  watch: {
    async callCenterSettings(newValue) {
      this.setCallCenterSettings(newValue)

      if (this.isOpenedSidebar && this.uidCallCenterSettingsId && !this.isCallCenter) {
        this.isOpenedSidebar = false
        const sidebarData = newValue.find((x) => x.uidCallCenterSettings === this.uidCallCenterSettingsId)
        if (sidebarData) {
          setTimeout(() => {
            this.toggleSidebar(true, sidebarData)
          }, 100)
        }
      }
    }
  },

  methods: {
    async editLinkedCallCenterInfo() {
      this.isLinkCCFormSent = false
      this.isEditLinkCCForm = true
      this.linkCCFormFields = this.company.linkCCFormFields
      this.isLinkCallCenterPopupActive = true
    },
    async sendCallCenterForm() {
      await this.$vs.loading()
      try {
        const sendEmailLinkCallCenter = this.$functions.httpsCallable('sendEmailLinkCallCenter')
        await this.$db.collection('company').doc(this.activeUserInfo.company).set(
          {
            linkCCFormFields: this.linkCCFormFields
          },
          { merge: true }
        )
        if (!this.isEditLinkCCForm) {
          await sendEmailLinkCallCenter({
            user: this.activeUserInfo,
            data: this.linkCCFormFields
          })
        }
        await this.$vs.loading.close()
        this.linkCCFormFields = {
          salutation: '',
          companyName: '',
          companyDescription: '',
          generalEmailAddress: '',
          generalPhoneNumber: '',
          internalContactPerson: '',
          reasons: '',
          neededCustomerInformation: '',
          contactRequestsEmail: '',
          handleRequests: ''
        }
        if (!this.isEditLinkCCForm) {
          this.isLinkCCFormSent = true
        }

        if (this.isEditLinkCCForm) {
          this.isLinkCallCenterPopupActive = false
          this.isEditLinkCCForm = false
        }
      } catch (err) {
        await this.$vs.loading.close()
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        // eslint-disable-next-line
        console.error(err.message)
      }
    },
    async linkCallCenter() {
      this.isEditLinkCCForm = false
      this.isLinkCCFormSent = false
      this.isLinkCallCenterPopupActive = true
    },
    closeCallCenterForm() {
      this.isEditLinkCCForm = false
      this.isLinkCallCenterPopupActive = false
      this.linkCCFormFields = {
        salutation: '',
        companyName: '',
        companyDescription: '',
        generalEmailAddress: '',
        generalPhoneNumber: '',
        internalContactPerson: '',
        reasons: '',
        neededCustomerInformation: '',
        contactRequestsEmail: '',
        handleRequests: ''
      }
    },
    async setCallCenterSettings(newValue) {
      await this.$vs.loading()

      this.allowedCompanies = []

      const keyForCallCenterSettings = this.isCallCenter ? 'customerId' : 'callCenterId'

      await Promise.all(
        newValue.map(async (data) => {
          const companyRef = await this.$db.collection('company').doc(data[keyForCallCenterSettings]).get()

          const { name, phone, url, street, city, zipcode, country } = companyRef.data()

          this.allowedCompanies.push({
            ...data,
            name,
            phone,
            url,
            street,
            city,
            zipcode,
            country,
            cooperation: data.approved
          })
        })
      )

      await this.$vs.loading.close()
    },

    openDeletingPrompt(docId) {
      this.activeDeletingPrompt = true
      this.temrminateCooperationId = docId
    },

    async inviteCompany(id) {
      if (!id) {
        this.activePrompt = true
      }

      if (!this.inviteData.companyId && !id) {
        return
      }

      await this.$vs.loading()

      const invitedCompanyRef = await this.$db
        .collection('company')
        .doc(this.inviteData.companyId || id)
        .get()

      if (!invitedCompanyRef.exists) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.companyNotFound'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return await this.$vs.loading.close()
      }

      const callCenterSettingsRef = await this.$db
        .collection('call-center-settings')
        .where('callCenterId', '==', this.activeUserInfo.company)
        .where('customerId', '==', this.inviteData.companyId)
        .get()

      if (!callCenterSettingsRef.empty && !id) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.companyIsAlreadyInvited'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        let data = {
          approved: 'waiting'
        }

        if (!id) {
          data = {
            ...data,
            callCenterId: this.activeUserInfo.company,
            customerId: this.inviteData.companyId,
            created: new Date(),
            createdBy: this.activeUserInfo.uid
          }

          await this.$db.collection('call-center-settings').add(data)
        } else {
          data = {
            ...data,
            modified: new Date(),
            modifiedBy: this.activeUserInfo.uid
          }

          const neededCallCenterSettings = this.callCenterSettings.find((settings) => settings.customerId === id)

          if (!neededCallCenterSettings) {
            this.$vs.notify({
              title: this.$i18n.t('vue.error'),
              text: this.$i18n.t('info.somethingWentWrong'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })

            await this.$vs.loading.close()

            return
          }

          await this.$db.collection('call-center-settings').doc(neededCallCenterSettings.uidCallCenterSettings).set(data, { merge: true })
        }

        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: !id ? this.$i18n.t('info.theInvitationHasBeenSent') : this.$i18n.t('info.theInvitationHasBeenResent'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      }

      this.inviteData.companyId = ''
      this.activePrompt = false

      await this.$vs.loading.close()
    },

    async deleteCompany() {
      this.activeDeletingPrompt = true

      if (!this.temrminateCooperationId) {
        return
      }

      await this.$vs.loading()

      await this.$db.collection('call-center-settings').doc(this.temrminateCooperationId).delete()

      this.$vs.notify({
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('info.theCooperationHasBeenTerminated'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })

      this.activeDeletingPrompt = false

      await this.$vs.loading.close()
    },

    toggleSidebar(isOpened = false, data) {
      this.$serverBus.$emit('company-settings-data', isOpened ? data : null)

      this.isOpenedSidebar = isOpened
      this.uidCallCenterSettingsId = isOpened ? data.uidCallCenterSettings : null

      if (!isOpened) {
        this.selected = []
      }
    },

    async toggleIsEnabledUpdatingDialogs() {
      this.isEnabledUpdatingDialogs = !this.isEnabledUpdatingDialogs

      await this.$db
        .collection('call-center-settings')
        .doc(this.callCenterSettings[0].uidCallCenterSettings)
        .set({ isEnabledUpdatingDialogs: this.isEnabledUpdatingDialogs }, { merge: true })
    },

    setCooperationColorByType(value) {
      switch (value) {
        case 'confirmed':
          return 'rgb(40, 199, 111)'
        case 'waiting':
          return 'rgb(245, 220, 0)'
        case 'canceled':
          return 'rgb(234, 84, 85)'
      }
    },

    chipColor(value) {
      if (value) {
        return 'success'
      }

      return 'danger'
    }
  }
}
</script>

<style lang="scss">
.link-cc {
  &__link {
    color: #3b86f7;
    cursor: pointer;
  }
  &-popup {
    &.con-vs-popup {
      .vs-popup {
        width: 100% !important;
        max-width: 800px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        overflow: hidden;
        min-height: 600px;
        &--header {
          background: white;
          display: none;
        }
        &--content {
          position: relative;
          margin: 0;
          width: 100%;
          padding: 50px 30px;
          overflow: hidden auto;
        }
      }
    }

    &__close-icon-wrapper {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    &__content-wrapper {
      width: 100%;
      max-width: 520px;
      margin: 0 auto;
      &--success-title {
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        color: #262629;
        margin-bottom: 20px;
      }
      &--success-subtitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #262629;
      }
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #575757;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      max-width: 480px;
    }
    &__subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #575757;
      margin-bottom: 30px;
      max-width: 480px;
    }
    &__button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    &__check-icon-wrapper {
      width: 75px;
      min-width: 75px;
      height: 75px;
      border-radius: 50%;
      background: #275D73;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 35px;
      margin-top: 100px;
    }
    &__fields {
      &__field {
        margin-top: 25px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        &:first-of-type {
          margin-top: 0;
        }

        &.link-cc-popup__fields__field--company-description {
          .link-cc-popup__fields__field__input {
            min-height: 120px;
          }
          .link-cc-popup__fields__field__label {
            top: 30px;
          }
        }
        &.link-cc-popup__fields__field--internal-contact,
        &.link-cc-popup__fields__field--reasons,
        &.link-cc-popup__fields__field--contact-requests {
          .link-cc-popup__fields__field__input {
            min-height: 86px;
          }
        }

        .con-vs-tooltip {
          margin-top: 18px;
        }

        &__inner {
          position: relative;
          width: 100%;
          max-width: 480px;
        }

        &__select {
          min-height: 60px;
          .multiselect__tag {
            color: #262629;
            background: #def1fe;
            &-icon {
              &:hover {
                background: #82ceff;
              }
            }
          }
          .multiselect__option {
            text-transform: capitalize;

            &.multiselect__option--highlight {
              color: #575757;
              font-weight: 700;
              background: #f1f9ff;
            }
          }

          .multiselect {
            &__tags {
              padding-top: 9px;
              min-height: 60px;
            }

            &__select {
              height: 55px;
              right: 15px;
            }

            &__single,
            &__input {
              padding-top: 10px;
            }

            &__content-wrapper {
              min-height: 200px !important;
            }
          }

          &-checkbox {
            .multiselect__option {
              display: flex;
              align-items: center;
              padding-left: 30px;
              min-height: 60px;

              &--selected {
                .link-cc-popup__fields__field__select__checkbox {
                  background-image: url('../../../../assets/images/pages/login/Vector-7.svg');
                  background-position: 50% 50%;
                  background-size: auto;
                  background-repeat: no-repeat;
                  background-color: #3b86f7;
                  border-color: #3b86f7;
                }
              }
            }
          }

          &__checkbox {
            margin-right: 20px;
            width: 20px;
            min-width: 20px;
            height: 20px;
            min-height: 20px;
            border: 1px solid #575757;
            border-radius: 4px;
          }
        }

        &__label {
          max-width: calc(100% - 60px);
          position: absolute;
          top: 50%;
          left: 30px;
          margin-right: 60px;
          padding: 0 2px;
          touch-action: none;
          background: #ffffff;
          transform: translateY(-50%);
          font-style: normal;
          font-family: 'Lato';
          font-weight: 400;
          font-size: 18px;
          line-height: 130%;
          color: #b2b2b2;

          &--moved {
            top: 0;
            left: 15px;
            font-family: 'Lato';
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            transition: all 0.2s linear;
          }
        }

        &__input {
          padding: 5px 30px;
          width: 100%;
          height: 60px;
          color: #575757;
          font-family: 'Lato';
          font-size: 16px;
          line-height: 18px;
          letter-spacing: -0.111111px;
          background: rgb(255, 255, 255);
          border: 1px solid rgb(217, 219, 221);
          border-radius: 8px;
          /* transition is using for preventing the autofill styles of the input */
          transition: background-color 99999999ms;

          &:focus + .link-cc-popup__fields__field__label,
          &:-webkit-autofill + .link-cc-popup__fields__field__label {
            top: 0;
            left: 30px;
            font-family: 'Lato';
            font-size: 12px;
            line-height: 20px;
            transition: all 0.2s linear;
          }
        }
      }
    }
  }
}
#call-center-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
          box-shadow: none !important;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
