var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vs-input", {
    directives: [
      {
        name: "validate",
        rawName: "v-validate",
        value: "required|numeric",
        expression: "'required|numeric'",
      },
    ],
    staticClass: "w-full mt-1",
    attrs: { name: "ringingDuration", type: "number" },
    on: { input: _vm.inputRingingDuration },
    model: {
      value: _vm.ringingDuration,
      callback: function ($$v) {
        _vm.ringingDuration = $$v
      },
      expression: "ringingDuration",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }