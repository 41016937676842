var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "call-center-view" } },
    [
      _c("CallCenterSidebar", {
        attrs: {
          isSidebarActive: _vm.isOpenedSidebar,
          isCallCenter: _vm.isCallCenter,
          appActiveUser: _vm.activeUserInfo,
        },
        on: { closeAppointmentsSidebar: _vm.toggleSidebar },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "link-cc-popup",
          attrs: {
            title: "",
            active: _vm.isLinkCallCenterPopupActive,
            "button-close-hidden": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.isLinkCallCenterPopupActive = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "link-cc-popup__close-icon-wrapper",
              on: { click: _vm.closeCallCenterForm },
            },
            [
              _c("close-icon", {
                attrs: { color: "#575757", width: 20, height: 20 },
              }),
            ],
            1
          ),
          !_vm.isLinkCCFormSent
            ? [
                _c("div", { staticClass: "link-cc-popup__content-wrapper" }, [
                  _c("div", { staticClass: "link-cc-popup__title" }, [
                    _vm._v(_vm._s(_vm.$t("vue.ccForm.mainPopupTitle"))),
                  ]),
                  _c("div", { staticClass: "link-cc-popup__subtitle" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.ccForm.mainPopupSubtitle")) +
                        "\n        "
                    ),
                  ]),
                  _c("div", { staticClass: "link-cc-popup__fields" }, [
                    _c(
                      "div",
                      { staticClass: "link-cc-popup__fields__field" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.linkCCFormFields.salutation,
                                  expression: "linkCCFormFields.salutation",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "saluation",
                              staticClass:
                                "link-cc-popup__fields__field__input",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: _vm.$t("inputs.ccForm.salutation"),
                                "label-placeholder": _vm.$t(
                                  "inputs.ccForm.salutation"
                                ),
                              },
                              domProps: {
                                value: _vm.linkCCFormFields.salutation,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "salutation",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    _vm.linkCCFormFields.salutation &&
                                    _vm.linkCCFormFields.salutation.length > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.saluation.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("inputs.ccForm.salutation")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.ccForm.salutation"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "link-cc-popup__fields__field" }, [
                      _c(
                        "div",
                        { staticClass: "link-cc-popup__fields__field__inner" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.linkCCFormFields.companyName,
                                expression: "linkCCFormFields.companyName",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "companyName",
                            staticClass: "link-cc-popup__fields__field__input",
                            attrs: {
                              "data-vv-validate-on": "blur",
                              name: _vm.$t("inputs.ccForm.companyName"),
                              "label-placeholder": _vm.$t(
                                "inputs.ccForm.companyName"
                              ),
                            },
                            domProps: {
                              value: _vm.linkCCFormFields.companyName,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.linkCCFormFields,
                                  "companyName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "link-cc-popup__fields__field__label",
                              class: {
                                "link-cc-popup__fields__field__label--moved":
                                  _vm.linkCCFormFields.companyName &&
                                  _vm.linkCCFormFields.companyName.length > 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.companyName.focus()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("inputs.ccForm.companyName")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "link-cc-popup__fields__field link-cc-popup__fields__field--company-description",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.linkCCFormFields.companyDescription,
                                  expression:
                                    "linkCCFormFields.companyDescription",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "companyDescription",
                              staticClass:
                                "link-cc-popup__fields__field__input",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: _vm.$t(
                                  "inputs.ccForm.companyDescription"
                                ),
                                "label-placeholder": _vm.$t(
                                  "inputs.ccForm.companyDescription"
                                ),
                              },
                              domProps: {
                                value: _vm.linkCCFormFields.companyDescription,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "companyDescription",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    _vm.linkCCFormFields.companyDescription &&
                                    _vm.linkCCFormFields.companyDescription
                                      .length > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.companyDescription.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("inputs.ccForm.companyDescription")
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.ccForm.companyDescription"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "link-cc-popup__fields__field" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.linkCCFormFields.generalEmailAddress,
                                  expression:
                                    "linkCCFormFields.generalEmailAddress",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email",
                                  expression: "'required|email'",
                                },
                              ],
                              ref: "generalEmailAddress",
                              staticClass:
                                "link-cc-popup__fields__field__input",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: _vm.$t(
                                  "inputs.ccForm.generalEmailAddress"
                                ),
                                "label-placeholder": _vm.$t(
                                  "inputs.ccForm.generalEmailAddress"
                                ),
                              },
                              domProps: {
                                value: _vm.linkCCFormFields.generalEmailAddress,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "generalEmailAddress",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    _vm.linkCCFormFields.generalEmailAddress &&
                                    _vm.linkCCFormFields.generalEmailAddress
                                      .length > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.generalEmailAddress.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "inputs.ccForm.generalEmailAddress"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.ccForm.generalEmailAddress"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "link-cc-popup__fields__field" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.linkCCFormFields.generalPhoneNumber,
                                  expression:
                                    "linkCCFormFields.generalPhoneNumber",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "generalPhoneNumber",
                              staticClass:
                                "link-cc-popup__fields__field__input",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: _vm.$t(
                                  "inputs.ccForm.generalPhoneNumber"
                                ),
                                "label-placeholder": _vm.$t(
                                  "inputs.ccForm.generalPhoneNumber"
                                ),
                              },
                              domProps: {
                                value: _vm.linkCCFormFields.generalPhoneNumber,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "generalPhoneNumber",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    _vm.linkCCFormFields.generalPhoneNumber &&
                                    _vm.linkCCFormFields.generalPhoneNumber
                                      .length > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.generalPhoneNumber.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("inputs.ccForm.generalPhoneNumber")
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.ccForm.generalPhoneNumber"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "link-cc-popup__fields__field link-cc-popup__fields__field--internal-contact",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.linkCCFormFields.internalContactPerson,
                                  expression:
                                    "linkCCFormFields.internalContactPerson",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "internalContactPerson",
                              staticClass:
                                "link-cc-popup__fields__field__input",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: _vm.$t(
                                  "inputs.ccForm.internalContactPerson"
                                ),
                                "label-placeholder": _vm.$t(
                                  "inputs.ccForm.internalContactPerson"
                                ),
                              },
                              domProps: {
                                value:
                                  _vm.linkCCFormFields.internalContactPerson,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "internalContactPerson",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    _vm.linkCCFormFields
                                      .internalContactPerson &&
                                    _vm.linkCCFormFields.internalContactPerson
                                      .length > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.internalContactPerson.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "inputs.ccForm.internalContactPerson"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.ccForm.internalContactPerson"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "link-cc-popup__fields__field link-cc-popup__fields__field--reasons",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.linkCCFormFields.reasons,
                                  expression: "linkCCFormFields.reasons",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "reasons",
                              staticClass:
                                "link-cc-popup__fields__field__input",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: _vm.$t("inputs.ccForm.reasons"),
                                "label-placeholder": _vm.$t(
                                  "inputs.ccForm.reasons"
                                ),
                              },
                              domProps: { value: _vm.linkCCFormFields.reasons },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "reasons",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    _vm.linkCCFormFields.reasons &&
                                    _vm.linkCCFormFields.reasons.length > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.reasons.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("inputs.ccForm.reasons")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("info.ccForm.reasons"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "link-cc-popup__fields__field" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c(
                              "multiselect",
                              {
                                ref: "neededCustomerInformation",
                                staticClass:
                                  "link-cc-popup__fields__field__select link-cc-popup__fields__field__select-checkbox",
                                attrs: {
                                  options: _vm.neededCustomerInformationList,
                                  multiple: true,
                                  "close-on-select": false,
                                  "clear-on-select": false,
                                  placeholder: "",
                                  selectLabel: "",
                                  selectedLabel: "",
                                  deselectLabel: "",
                                  label: "name",
                                  "track-by": "name",
                                  name: "neededCustomerInformation",
                                  "open-direction": "bottom",
                                  "preserve-search": true,
                                  "preselect-first": false,
                                },
                                on: {
                                  close: function ($event) {
                                    _vm.isOpenedNeededCustomerInformationSelect = false
                                  },
                                  open: function ($event) {
                                    _vm.isOpenedNeededCustomerInformationSelect = true
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function (ref) {
                                        var option = ref.option
                                        return [
                                          _c("div", {
                                            staticClass:
                                              "link-cc-popup__fields__field__select__checkbox",
                                          }),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(option.name) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1334571870
                                ),
                                model: {
                                  value:
                                    _vm.linkCCFormFields
                                      .neededCustomerInformation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.linkCCFormFields,
                                      "neededCustomerInformation",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "linkCCFormFields.neededCustomerInformation",
                                },
                              },
                              [
                                _c("template", { slot: "noResult" }, [
                                  _vm._v("No results found"),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    (!!_vm.linkCCFormFields
                                      .neededCustomerInformation &&
                                      !!_vm.linkCCFormFields
                                        .neededCustomerInformation.length) ||
                                    _vm.isOpenedNeededCustomerInformationSelect,
                                  "link-cc-popup__fields__field__label--over":
                                    _vm.isOpenedNeededCustomerInformationSelect,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.neededCustomerInformation.$el.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "inputs.ccForm.neededCustomerInformation"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t(
                                "info.ccForm.neededCustomerInformation"
                              ),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "link-cc-popup__fields__field link-cc-popup__fields__field--contact-requests",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-cc-popup__fields__field__inner",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.linkCCFormFields.contactRequestsEmail,
                                  expression:
                                    "linkCCFormFields.contactRequestsEmail",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "contactRequestsEmail",
                              staticClass:
                                "link-cc-popup__fields__field__input",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: _vm.$t(
                                  "inputs.ccForm.contactRequestsEmail"
                                ),
                                "label-placeholder": _vm.$t(
                                  "inputs.ccForm.contactRequestsEmail"
                                ),
                              },
                              domProps: {
                                value:
                                  _vm.linkCCFormFields.contactRequestsEmail,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "contactRequestsEmail",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "link-cc-popup__fields__field__label",
                                class: {
                                  "link-cc-popup__fields__field__label--moved":
                                    _vm.linkCCFormFields.contactRequestsEmail &&
                                    _vm.linkCCFormFields.contactRequestsEmail
                                      .length > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.contactRequestsEmail.focus()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "inputs.ccForm.contactRequestsEmail"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "link-cc-popup__fields__field" }, [
                      _c(
                        "div",
                        { staticClass: "link-cc-popup__fields__field__inner" },
                        [
                          _c(
                            "multiselect",
                            {
                              ref: "handleRequests",
                              staticClass:
                                "link-cc-popup__fields__field__select",
                              attrs: {
                                options: _vm.handleRequestsList,
                                multiple: false,
                                "close-on-select": true,
                                "clear-on-select": false,
                                placeholder: "",
                                selectLabel: "",
                                selectedLabel: "",
                                deselectLabel: "",
                                label: "name",
                                "track-by": "name",
                                name: "handleRequests",
                                "open-direction": "bottom",
                                "preserve-search": true,
                                "preselect-first": false,
                              },
                              on: {
                                close: function ($event) {
                                  _vm.isOpenedHandleRequestsSelect = false
                                },
                                open: function ($event) {
                                  _vm.isOpenedHandleRequestsSelect = true
                                },
                              },
                              model: {
                                value: _vm.linkCCFormFields.handleRequests,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.linkCCFormFields,
                                    "handleRequests",
                                    $$v
                                  )
                                },
                                expression: "linkCCFormFields.handleRequests",
                              },
                            },
                            [
                              _c("template", { slot: "noResult" }, [
                                _vm._v("No results found"),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "link-cc-popup__fields__field__label",
                              class: {
                                "link-cc-popup__fields__field__label--moved":
                                  !!_vm.linkCCFormFields.handleRequests ||
                                  _vm.isOpenedHandleRequestsSelect,
                                "link-cc-popup__fields__field__label--over":
                                  _vm.isOpenedHandleRequestsSelect,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.handleRequests.$el.focus()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("inputs.ccForm.handleRequests")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "link-cc-popup__button-wrapper" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.sendCallCenterForm.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.ccForm.save")))]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c("div", { staticClass: "link-cc-popup__content-wrapper" }, [
                  _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center w-full justify-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "link-cc-popup__check-icon-wrapper" },
                          [
                            _c("ThinCheckIcon", {
                              staticClass: "link-cc-popup__check-icon",
                              attrs: {
                                width: "40",
                                height: "24",
                                color: "#fff",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "link-cc-popup__content-wrapper--success-title",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.ccForm.successPopupTitle")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "link-cc-popup__content-wrapper--success-subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("vue.ccForm.successPopupSubtitle")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
        ],
        2
      ),
      _vm.isCallCenter
        ? _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                "title-color": "primary",
                "subtitle-color": "danger",
                title: _vm.$t("register.callCenterService"),
              },
            },
            [
              _c("p", { staticClass: "mb-1" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("info.createSettingsForTheAbilityToBookAMeeting")
                    ) +
                    "\n    "
                ),
              ]),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "vs-row",
                    { attrs: { "vs-justify": "flex-end" } },
                    [
                      _vm.isCallCenter
                        ? _c(
                            "vs-button",
                            {
                              attrs: {
                                color: "primary",
                                type: "filled",
                                "icon-size": "24px",
                                icon: "add",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.activePrompt = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("inputs.inviteCompany")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : !_vm.isCallCenter &&
          !_vm.company.linkCCFormFields &&
          !_vm.company.isConnectedWithCallCenter
        ? _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                "title-color": "primary",
                "subtitle-color": "danger",
                title: _vm.$t("register.callCenterService"),
              },
            },
            [
              _c(
                "p",
                { staticClass: "mb-1" },
                [
                  _vm.$i18n.locale === "de"
                    ? [
                        _vm._v(
                          "\n        Um unseren Callcenter-Service zu nutzen, um Ihre eingehenden Anfragen zu qualifizieren, klicken Sie bitte auf die Schaltfläche\n        "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "link-cc__link",
                            on: { click: _vm.linkCallCenter },
                          },
                          [_vm._v("„Callcenter verknüpfen“”")]
                        ),
                        _vm._v(
                          "\n        und fügen Sie Ihre Unternehmensdaten hinzu. Nach erfolgreicher Verifizierung wird Ihr Konto in den nächsten 48 Stunden automatisch mit unserem\n        Callcenter verbunden.\n      "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n        To use our Call Center Service to qualify your incoming requests, please click on the\n        "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "link-cc__link",
                            on: { click: _vm.linkCallCenter },
                          },
                          [_vm._v("“Link call center”")]
                        ),
                        _vm._v(
                          " Button and add your Company details. After a successful verification,\n        your account will be automatically connected with our call center in the next 48 hours.\n      "
                        ),
                      ],
                ],
                2
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "vs-row",
                    { attrs: { "vs-justify": "flex-end" } },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { color: "primary", type: "filled" },
                          on: { click: _vm.linkCallCenter },
                        },
                        [_vm._v(_vm._s(_vm.$t("vue.ccForm.linkCallCenter")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "vx-card",
        [
          _vm.isCallCenter
            ? _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled",
                    "icon-size": "24px",
                    icon: "add",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.activePrompt = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("inputs.inviteCompany")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "vs-table",
            {
              ref: "table",
              attrs: {
                pagination: "",
                "max-items": _vm.itemsPerPage,
                search: "",
                data: _vm.allowedCompanies,
                noDataText:
                  !_vm.isCallCenter &&
                  _vm.company &&
                  _vm.company.linkCCFormFields
                    ? _vm.$t("vue.ccForm.pendingRequest")
                    : "No data Available",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var data = ref.data
                    return [
                      _c(
                        "tbody",
                        _vm._l(data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].name } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].cooperation } },
                                [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        color: _vm.setCooperationColorByType(
                                          data[indextr].cooperation
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "vue." + data[indextr].cooperation
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                {
                                  attrs: {
                                    data: data[indextr]
                                      .isEnabledUpdatingDialogs,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticStyle: {
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.callCenterSettings[0]
                                            .customerId ===
                                          _vm.activeUserInfo.company
                                            ? _vm.toggleIsEnabledUpdatingDialogs()
                                            : null
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-chip",
                                        {
                                          attrs: {
                                            color: _vm.chipColor(
                                              data[indextr]
                                                .isEnabledUpdatingDialogs
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  data[indextr]
                                                    .isEnabledUpdatingDialogs
                                                    ? _vm.$t("vue.enabled")
                                                    : _vm.$t("vue.disabled")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].phone } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].phone) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].url } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].url) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                data[indextr].street
                                  ? _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.street")) + " : "
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(data[indextr].street) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                data[indextr].city
                                  ? _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.city")) + " : "
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(data[indextr].city) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                data[indextr].zipcode
                                  ? _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.zipcode")) + " : "
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(data[indextr].zipcode) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                data[indextr].country
                                  ? _c("p", [
                                      data[indextr].country.countryName
                                        ? _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.$t("vue.country")) +
                                                " : "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(
                                          data[indextr].country.countryName
                                        ) + "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "vs-td",
                                { staticClass: "whitespace-no-wrap" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _vm.isCallCenter &&
                                      data[indextr].approved !== "confirmed"
                                        ? _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                position: "top",
                                                text: _vm.$t(
                                                  "vue.resendTheInvite"
                                                ),
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-send",
                                                  color:
                                                    data[indextr].approved ===
                                                    "canceled"
                                                      ? "primary"
                                                      : "dark",
                                                  disabled:
                                                    data[indextr].approved !==
                                                    "canceled",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.inviteCompany(
                                                      data[indextr].customerId
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isCallCenter
                                        ? _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                position: "top",
                                                text: "Edit linked CallCenter form",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  color: "primary",
                                                  icon: "icon-edit",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editLinkedCallCenterInfo()
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            position: "top",
                                            text: _vm.$t(
                                              "vue.openSettingsForTheCompany"
                                            ),
                                          },
                                        },
                                        [
                                          _c("vs-button", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              type: "border",
                                              size: "medium",
                                              "icon-pack": "feather",
                                              color:
                                                data[indextr].approved ===
                                                "confirmed"
                                                  ? "primary"
                                                  : "dark",
                                              icon: _vm.isCallCenter
                                                ? "icon-edit"
                                                : "icon-info",
                                              disabled:
                                                data[indextr].approved !==
                                                "confirmed",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleSidebar(
                                                  true,
                                                  data[indextr]
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.isCallCenter
                                        ? _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                position: "top",
                                                text: _vm.$t(
                                                  "vue.goToTheDialogs"
                                                ),
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-code",
                                                  color:
                                                    data[indextr].approved ===
                                                      "confirmed" &&
                                                    data[indextr]
                                                      .isEnabledUpdatingDialogs
                                                      ? "primary"
                                                      : "dark",
                                                  disabled:
                                                    data[indextr].approved !==
                                                      "confirmed" ||
                                                    !data[indextr]
                                                      .isEnabledUpdatingDialogs,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.$router.push(
                                                      "/call-center-dialogs/" +
                                                        data[indextr].customerId
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            position: "top",
                                            text: _vm.$t(
                                              "vue.cancelTheCooperation"
                                            ),
                                          },
                                        },
                                        [
                                          _c("vs-button", {
                                            attrs: {
                                              type: "border",
                                              size: "medium",
                                              "icon-pack": "feather",
                                              icon: "icon-trash",
                                              color: "danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openDeletingPrompt(
                                                  data[indextr]
                                                    .uidCallCenterSettings
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap-reverse items-center flex-grow justify-between",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", {
                    staticClass: "flex flex-wrap-reverse items-center",
                  }),
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer mb-4 mr-4",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                          staticStyle: { "border-radius": "5px" },
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentPage * _vm.itemsPerPage -
                                  (_vm.itemsPerPage - 1)
                              ) +
                                " -\n              " +
                                _vm._s(
                                  _vm.allowedCompanies.length -
                                    _vm.currentPage * _vm.itemsPerPage >
                                    0
                                    ? _vm.currentPage * _vm.itemsPerPage
                                    : _vm.allowedCompanies.length
                                ) +
                                " of\n              " +
                                _vm._s(_vm.queriedItems)
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 5
                                },
                              },
                            },
                            [_c("span", [_vm._v("5")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 10
                                },
                              },
                            },
                            [_c("span", [_vm._v("10")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 15
                                },
                              },
                            },
                            [_c("span", [_vm._v("15")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.itemsPerPage = 20
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { "sort-key": "name" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("vue.name")) + "\n        "
                    ),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "cooperation" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.cooperation")) +
                        "\n        "
                    ),
                  ]),
                  _c(
                    "vs-th",
                    { attrs: { "sort-key": "isEnabledUpdatingDialogs" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.enabledUpdatingDialogs")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("vs-th", { attrs: { "sort-key": "phone" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.phone")) +
                        "\n        "
                    ),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "url" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("vue.url")) + "\n        "
                    ),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "city" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.address")) +
                        "\n        "
                    ),
                  ]),
                  _c("vs-th", [_vm._v(_vm._s(_vm.$t("vue.action")))]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.activePrompt
        ? _c(
            "vs-prompt",
            {
              attrs: {
                "cancel-text": _vm.$t("vue.cancel"),
                "accept-text": _vm.$t("vue.confirm"),
                title: _vm.$t("inputs.inviteCompany"),
                active: _vm.activePrompt,
              },
              on: {
                "update:active": function ($event) {
                  _vm.activePrompt = $event
                },
                accept: _vm.inviteCompany,
              },
            },
            [
              _c(
                "div",
                { staticClass: "con-exemple-prompt" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("inputs.enterCompanyId")))]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "mt-3 w-full",
                    attrs: {
                      placeholder: _vm.$t("inputs.companyId"),
                      name: _vm.$t("inputs.companyId"),
                      "data-vv-validate-on": "blur",
                    },
                    model: {
                      value: _vm.inviteData.companyId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inviteData, "companyId", $$v)
                      },
                      expression: "inviteData.companyId",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.activeDeletingPrompt
        ? _c(
            "vs-prompt",
            {
              attrs: {
                color: "danger",
                "cancel-text": _vm.$t("vue.cancel"),
                "accept-text": _vm.$t("vue.delete"),
                title: _vm.$t("inputs.deleteCompany"),
                active: _vm.activeDeletingPrompt,
              },
              on: {
                "update:active": function ($event) {
                  _vm.activeDeletingPrompt = $event
                },
                accept: _vm.deleteCompany,
              },
            },
            [
              _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("info.terminateTheCooperationWithTheCompany"))
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }