var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "flex",
        style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" },
      },
      [
        _c("vs-button", {
          attrs: {
            radius: "",
            size: "large",
            color: "dark",
            type: "flat",
            disabled: _vm.isUpArrowDisabled,
            icon: "arrow_circle_up",
          },
          on: {
            click: function ($event) {
              return _vm.moveUp()
            },
          },
        }),
        _c("vs-button", {
          attrs: {
            radius: "",
            size: "large",
            color: "dark",
            type: "flat",
            disabled: _vm.isDownArrowDisabled,
            icon: "arrow_circle_down",
          },
          on: {
            click: function ($event) {
              return _vm.moveDown()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }